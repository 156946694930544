import React from 'react';
import {
  TextField,
  BooleanField,
} from 'react-admin';
import { ImportList } from '../../modules/ra-csv-importer';

function CommuneReviewComponent(props) {
    return (
      <ImportList {...props}>
        <TextField source="code"  />
        <TextField source="name"  />
        <TextField source="region"  />
      </ImportList>
    );
  }

  export default CommuneReviewComponent;