import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SelectField,
    ReferenceField,
    ArrayField,
    SingleFieldList,
    ChipField,
    EditButton,
    DeleteButton,
    ShowButton,
    Pagination,
    BooleanField,
  } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function WarehousePagination(props){
  return(
    <Pagination 
      rowsPerPageOptions={[25, 50, 100]} 
      {...props} 
    />
  )
} 

function WarehouseList(props) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const list = (
      <SimpleList
        primaryText={(record) => record.name}
        secondaryText={(record) => record.address}
      />
    );
    const datagrid = (
      <Datagrid rowClick="show">
        <TextField source="name"  />
        <BooleanField   
          source="default"  
        />
        <TextField source="address"  />
        <ShowButton label=""  />
        <EditButton label=""  />
        <CustomButtonDelete />
      </Datagrid>
    );
    return (
      <List {...props} 
        pagination={<WarehousePagination />}
        perPage={25} 
        sort={{ field: 'name', order: 'ASC' }} 
      >
        {isSmall ? list : datagrid}
        
      </List>
    );
  }

export default WarehouseList;



