import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  Pagination,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import OrderReportFilter from './OrderReportFilter';
import OrderReportActions from './OrderReportActions'

function RoutePagination(props) {
  return <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;
}

function OrderReport(props) {

  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => record.code}
      secondaryText={(record) => record.createdAt}
    />
  );
  const datagrid = (
    <Datagrid rowClick="show">
      <TextField source="code" label="resources.orders.fields.code" />
      <DateField
        source="events.GEOCODE_SUCCESSED"
        label="resources.orders.status.received"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.ROUTE_PLANNING"
        label="resources.orders.status.planned_in_route"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.TRANSPORT_ASSIGNMENT"
        label="resources.orders.status.assigned_in_transport"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.START_ROUTE"
        label="resources.orders.status.in_transit"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.DELIVER"
        label="resources.orders.status.accepted_by_client"
        options={dateOptions}
        locales="es-VE"
      />
      <TextField 
        source="vehiclePlate"
        label="resources.routes.fields.vehicle_id"
      />
    </Datagrid>
  );
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<OrderReportFilter />}
      pagination={<RoutePagination />}
      perPage={25}
      sort={{ field: 'code', order: 'ASC' }}
      actions={<OrderReportActions/>}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default OrderReport;
