import React from 'react';
import {
  List,
  Show,
  Create,
  Edit,
  SimpleShowLayout,
  SimpleList,
  SimpleForm,
  Datagrid,
  TextField,
  EmailField,
  DateField,
  ReferenceField,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  PasswordInput,
  required,
  EditButton,
  useTranslate,
  ShowButton,
  maxLength,
  minLength,
  email,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';


function UserTitle({ record }) {
    const translate = useTranslate();
    return <span>{translate('resources.users.message.user')} {`${record.email} `}</span>;
  }


const validate = {
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email:  [maxLength(128), required(), email()],
  phone: [maxLength(16)],
  dni: [maxLength(32)],
  password: [maxLength(16), minLength(8), required()]

};




function UserShow(props) {
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };
  return (
    <Show title={<UserTitle />} {...props}>
      <SimpleShowLayout>
        <TextField source='firstName'  />
        <TextField source='lastName' />
        <EmailField source='email' />
        <TextField source='phone'  />
        <TextField source='address'  />
        <TextField source='dni'  /><ReferenceField
          source="role_id"
          link="show"
          reference="roles"
        >
          <TextField source="label" />
        </ReferenceField>

        <DateField
          source='createdAt'
          showTime
          options={dateOptions}
        />
        <DateField
          source='updatedAt'
          showTime
          options={dateOptions}
        />
      </SimpleShowLayout>
    </Show>
  );
}

export default UserShow;