import React, { useEffect, useState } from "react";
import { useTranslate } from "react-admin";
import { GoogleMap, HeatmapLayer } from "@react-google-maps/api";
import { useGoogleMapsContext } from "../contexts/GoogleMapsContextProvider";
import CustomMarker from './CustomMarker';
import { Typography } from "@material-ui/core";

const containerStyle = {
  width: "100%",
  height: "100%",
};

const center = {
  lat: -33.4724727,
  lng: -70.9100253,
};

const southwest = {
  lat: -33.5772828,
  lng: -70.7011775,
};

const northeast = {
  lat: -33.369448,
  lng: -70.688604,
};

function Map({ data = [], route = false, heatMap = false, onClickMarker }) {
  /* global window */
  const { isLoaded, error, google } = useGoogleMapsContext();
  const [mapRef, setMapRef] = useState(null);
  const translate = useTranslate();
  useEffect(() => {
    if (mapRef instanceof Object) {
      fitBounds();
    }
  }, [data, mapRef]);

  const fitBounds = () => {
    let bounds = new google.maps.LatLngBounds(southwest, northeast);
    if (data.length > 0) {
      bounds = new google.maps.LatLngBounds();
    }
    data.map(({ lat, lng }) => {
      const latlng = new google.maps.LatLng(lat, lng);
      bounds.extend(latlng);
    });

    // Don't zoom in too far on only one marker
    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const offset = 0.005;
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat() + offset,
        bounds.getNorthEast().lng() + offset
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat() - offset,
        bounds.getNorthEast().lng() - offset
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    mapRef.fitBounds(bounds);
    //mapRef.panToBounds(bounds);
  };

  const drawRoute = (map) => {
    if (route && data.length > 0) {
      const directionsService = new google.maps.DirectionsService();

      const service_callback = (response, status) => {
        if (status === "OK") {
          const directionsDisplay = new google.maps.DirectionsRenderer({
            suppressMarkers: true,
          });
          directionsDisplay.setMap(map);
          directionsDisplay.setDirections(response);
        } else {
          window.alert("Directions request failed due to " + status);
        }
      };

      // Divide route to several parts because max stations limit is 25 (23 waypoints + 1 origin + 1 destination)
      let parts = [];
      for (let i = 0, max = 25 - 1; i < data.length; i = i + max) {
        parts.push(data.slice(i, i + max + 1));
      }
      for (let i = 0; i < parts.length; i++) {
        let waypoints = [];
        for (var j = 0; j < parts[i].length - 1; j++) {
          waypoints.push({
            location: { lat: parts[i][j].lat, lng: parts[i][j].lng },
            stopover: true,
          });
        }

        directionsService.route(
          {
            origin: parts[i][0],
            destination: parts[i][parts[i].length - 1],
            waypoints: waypoints,
            travelMode: "DRIVING",
          },
          service_callback
        );
      }
    }
  };

  const handleLoadedMap = (map) => {
    setMapRef(map);
    drawRoute(map);
  };

  const renderHeatPosition = ({ lat, lng }) => {
    return new google.maps.LatLng(lat, lng);
  };

  const renderMap = () => {
    return (
      <GoogleMap
        onLoad={handleLoadedMap}
        mapContainerStyle={containerStyle}
        center={center}
        zoom={5}
        options={{ styles: heatMap ? mapStyles : [] }}
      >
        {heatMap && data instanceof Array && (
          <HeatmapLayer data={data.map(renderHeatPosition)} />
        )}
        {!heatMap && data instanceof Array && data.map(({id, lat, lng, color, label, title, onClick}, index) => {
          return (
          <CustomMarker
            key={index}
            id={id}
            lat={lat}
            lng={lng}
            color={color}
            label={label}
            title={title}
            onClick={onClick}
            index = {index}
          />
          );
        })}
      </GoogleMap>
    );
  };

  if (error) {
    return <Typography>{translate("texts.noMap")}</Typography>;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <Typography>{translate("texts.loadingMap")}</Typography>
  );
}

const mapStyles = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5",
      },
    ],
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e",
      },
    ],
  },
];

export default React.memo(Map);
