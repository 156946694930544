import React from 'react';
import { 
  Edit, 
  SimpleForm, 
  TextInput, 
  required, 
  maxLength,
  useTranslate,
  TextField,
  email,
 } from 'react-admin';

function PlannerTitle({ record }) {
  const translate = useTranslate();
  return <span> {translate('resources.planners.message.planner')} {`${record.email}`}</span>;
}

const validate = {
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email: [maxLength(64), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
};

export function PlannerEdit(props) {
  return (
    <Edit title={<PlannerTitle />} {...props}>
      <SimpleForm
        variant="standard"
      >
        <TextField source='email' validate={validate.email} />
        <TextInput
          source='firstName'
          validate={validate.firstName}
        />
        <TextInput
          source='lastName'
          validate={validate.lastName}
        />
        <TextInput source='phone'  validate={validate.phone} />
        <TextInput source='dni' validate={validate.dni} />
        <TextInput
          source='address'
          validate={validate.address}
        />
      </SimpleForm>
    </Edit>
  );
}

export default PlannerEdit;
