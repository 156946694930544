import { useTranslate } from "react-admin";

function useTranslateOrderStatus() {
  const translate = useTranslate();
  return [
    {
      id: "INITIAL",
      name: translate("resources.orders.status.initial"),
    },
    {
      id: "RECEIVED",
      name: translate("resources.orders.status.received"),
    },
    {
      id: "WITHOUT_GEOCODING",
      name: translate("resources.orders.status.without_geocoding"),
    },
    {
      id: "INVALID",
      name: translate("resources.orders.status.invalid"),
    },
    {
      id: "PLANNED_IN_ROUTE",
      name: translate("resources.orders.status.planned_in_route"),
    },
    {
      id: "ASSIGNED_IN_TRANSPORT",
      name: translate("resources.orders.status.assigned_in_transport"),
    },
    {
      id: "LOADED_IN_TRANSPORT",
      name: translate("resources.orders.status.loaded_in_transport"),
    },
    {
      id: "IN_TRANSIT",
      name: translate("resources.orders.status.in_transit"),
    },
    {
      id: "ACCEPTED_BY_CLIENT",
      name: translate("resources.orders.status.accepted_by_client"),
    },
    {
      id: "REJECTED_BY_CLIENT",
      name: translate("resources.orders.status.rejected_by_client"),
    },
    {
      id: "REPLANNED_IN_ROUTE",
      name: translate("resources.orders.status.replanned_in_route"),
    },
    {
      id: "ASSIGNED",
      name: translate("resources.orders.status.assigned"),
    },
    {
      id: "PENDING",
      name: translate("resources.orders.status.pending"),
    },
  ];
}

export default useTranslateOrderStatus;
