export default {
    name: 'Planificador |||| Planificadores',
    label: 'Planificadores',
    fields: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Correo electronico',
        phone: 'Teléfono',
        dni: 'RUT',
        address: 'Dirección',
        subscription_last_day: "Último día del plan",
        subscription_plan_id: "Plan de Subscripción"
    },
    message: {
        register: 'Registrar planificador',
        planner: 'Planificador:',
    },
}