import React from 'react';
import { ReactComponent as Icon } from './truck.svg';
import { SvgIcon } from '@material-ui/core';

function TruckIcon(props) {
  let color = props.color ? props.color : 'gray';
  return <SvgIcon component={Icon} viewBox="0 0 612 612" htmlColor={color} />;
}

export default TruckIcon;
