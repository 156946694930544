import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const validate = {
  description: [maxLength(256)],
  vehicle_id: [],
  warehouse: [required(),]
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function RouteCreate(props) {
  const classes = useStyles();
  return (
    <Create  {...props}>
      <SimpleForm>
        <TextInput
          source='description'
          variant="standard"
          validate={validate.description}
          formClassName={classes.inlineBlock}
        />
        <ReferenceInput
          source='vehicle_id'
          reference='vehicles'
          variant="standard"
          allowEmpty
          filterToQuery={(searchText) => ({ plate: searchText, status: 'AVAILABLE' })}
          filter={{ status: 'AVAILABLE' }}
          validate={validate.vehicle_id}
        >
          <AutocompleteInput optionText="plate" />
        </ReferenceInput>
        <ReferenceInput
          source="warehouse_id" 
          reference="warehouses" 
          variant="standard"
          formClassName={classes.shortInput}
          validate={validate.warehouse}
          alwaysOn 
        > 
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

export default RouteCreate;
