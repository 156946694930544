import React from 'react';
import { TopToolbar, ListButton, EditButton, useTranslate} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

function WaybillShowActions({ basePath, data }){
  const translate = useTranslate();
 return(

  <TopToolbar>
    <ListButton 
      basePath={basePath} 
      label={translate('resources.waybills.message.back')} 
      icon={<ChevronLeft />}
    />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);
}

export default WaybillShowActions;