import { default as orders } from '../es/orders';

export default {
    ...orders,
    fields: {
        ...orders.fields,
        commune: 'Ciudad',
        region: 'Estado',
        administrative_area_level_1_id: 'Estado',
        administrative_area_level_2_id: 'Ciudad',
    }
}