export default {
  name: "Order |||| Orders",
  label: "Orders",
  fields: {
    createdAt: "Creation date",
    updatedAt: "Last update date",
    code: "ID",
    firstname: "Firstname",
    lastname: "Lastname",
    email: "Email",
    retail: "Client retail",
    street: "Street",
    streetNumber: "Street number",
    commune: "Commune",
    region: "Region",
    address: "Address",
    address_references: "Address references",
    phone: "Phone",
    amount: "Amount",
    client_retail_id: "Client retail",
    priority: "Priority",
    createdAt_lte: "End date",
    createdAt_gte: "Init date",
    administrative_area_level_1_id: "Region",
    administrative_area_level_2_id: "Commune",
    geocoded: "Geocoded",
    waybill_id: "Bill of landing",
    weight: "Weight (Kg)",
    volume: "Volume (mts³)",
    qr_code: "QR Code",
    isImportable: "Received",
    status: "Status",
    valid: "Valid",
  },
  status: {
    initial: "Initial",
    received: "Received",
    without_geocoding: "Without location",
    invalid: "Invalid",
    planned_in_route: "Planned in route",
    assigned_in_transport: "Assigned to a transport",
    loaded_in_transport: "Loaded in transport",
    in_transit: "In transit",
    accepted_by_client: "Accepted by client",
    rejected_by_client: "Rejected by client",
    replanned_in_route: "Replanned in route",
    error: "Error",
    assigned: "Assigned",
    pending: "Pending",
  },
  import_step: {
    one: "File upload",
    two: "Geocoding",
    three: "Preview data",
  },
  modal: {
    title: "Import orders",
  },
  message: {
    geocoding: "Geocoding addresses, please",
    longWait: "Go for a cup of coffee ☕",
    shortWait: "Wait...",
    noReceived: "Order not received",
    receive: "Order already received",
    geocoded: "Geocoded address",
    noGeocoded: "Address not geocoded",
    label: "Deliveries",
    order: "Order:",
    back: "Back",
    selected: "Selected",
    relocate: "Would you like to change delivery address?",
    yes: 'Yes',
    no: 'No',
  },
  errors: {
    weightExceedVehicleCapacity:
      "Order with invalid weight, cannot exceed weight capacity of assigned vehicle",
    volumeExceedVehicleCapacity:
      "Order with invalid volume, cannot exceed volume capacity of assigned vehicle",
    assignmentToRouteExceedVehicleCapacity:
      "Orders to be assigned exceed the capacity of vehicle assigned to the route",
  },
};
