import spanishMessages from '@blackbox-vision/ra-language-spanish';
import { default as waybills } from './waybills';
import { default as orders } from './orders';
import { default as users } from './users';
import { default as planners } from './planners';
import { default as transports } from './transports';
import { default as vehicles } from './vehicles';
import { default as countries } from './countries';
import { default as administrative_areas_level_1 } from './administrative_areas_level_1';
import { default as administrative_areas_level_2 } from './administrative_areas_level_2';
import { default as clients_retail } from './clients_retail';
import { default as drivers } from './drivers';
import { default as roles } from './roles';
import { default as routes } from './routes';
import { default as deliveries } from './deliveries';
import { default as dashboard } from './dashboard';
import { default as warehouses } from './warehouses';
import { default as subscription_plans } from './subscription_plans';

export default {
    ...spanishMessages,
    resources: {
        waybills,
        orders,
        users,
        planners,
        transports,
        vehicles,
        countries,
        "administrative_areas/level_1": administrative_areas_level_1,
        "administrative_areas/level_2": administrative_areas_level_2,
        "clients/retail": clients_retail,
        drivers,
        roles,
        routes,
        deliveries,
        warehouses,
        subscription_plans,
        settings: {
            name: 'Configuración |||| Configuraciones',
            label: 'Configuraciones',
        },
        reports: {
            label: 'Reportes',
            exportPDF: 'Exportar PDF',
            exportCSV: 'Exportar CSV',
            waybill: {
                label: 'Reporte de guías',
            },
            route: {
                label: 'Reporte de rutas',
            },
            order: {
                label: 'Reporte de órdenes',
            },
        },
    },
    dashboard,
    auth: {
        unauthorized: "Correo electrónico o contraseña inválida"
    },
    ra_csv_importer: {
        action: {
            import: "Importar"
        }
    },
    texts: {
        welcome: "Bienvenidos a Chitrack",
        noMap: "El mapa no se pudo cargar correctamente, lo siento.",
        loadingMap: "Cargando Mapa...",
        geocode: 'Geocodificar',
    },
    confirmDelete: {
        delete: 'Eliminar',
        cancel: 'Cancelar',
        message: '¿Estás seguro de que quieres eliminar?',
        warning: 'Advertencia',
    },
    'Failed to fetch': 'Lo sentimos! Ocurrió un error de conexión con el servidor, intente de nuevo o contacte con soporte',
    'BAD REQUEST': 'Solicitud inválida, intente de nuevo o contacte con soporte',
}