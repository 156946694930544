import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    EditButton,
    DeleteButton,
    EmailField,
    Pagination
  } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import {default as RetailActions} from './RetailActions'; 
import {default as RetailFilter} from './RetailFilter';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function OrdersPagination(props){
  return(
    <Pagination 
      rowsPerPageOptions={[25, 50, 100]} 
      {...props} 
    />
  )
}

export function RetailList(props) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const list = (
      <SimpleList
        primaryText={(record) => record.cardId}
        secondaryText={(record) => record.Name}
      />
    );
    const datagrid = (
      <Datagrid rowClick='show'>
        <TextField source='dni' />
        <TextField source='name' />
        <EmailField source='email'  />
        <TextField source='phone'  />
        <EditButton label="" />
        <CustomButtonDelete />
      </Datagrid>
    );
    return (
      <List  
        {...props} 
        actions={<RetailActions/>}
        filters={<RetailFilter/>}
        pagination={<OrdersPagination />}
        perPage={25} 
      >
        {isSmall ? list : datagrid}
      </List>
    );
  }

export default RetailList;