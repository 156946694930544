export default {
    name: 'Country |||| Countries',
    label: 'Countries',
    fields: { 
        name: 'Country name',
        dniLegalPersonName: 'DNI legal person name', 
        dniLegalPersonFormat: 'DNI legal person format',
        dniNaturalPersonName: 'DNI natural person name', 
        dniNaturalPersonFormat: 'DNI natural person format', 
    },
    message: {
        country: 'Country:',
    }
}