import React from "react";
import Map from "../../components/Map";
import { Box, Card, CardContent, Typography } from "@material-ui/core";
import { Droppable, Draggable } from "react-beautiful-dnd";
import VehicleTypeField from "../../components/VehicleTypeField";
import { useTranslate, useRedirect } from "react-admin";

import { makeStyles } from "@material-ui/core/styles";

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: "450px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  ul: {
    listStyle: "none",
    paddingLeft: "0",
    cursor: "pointer",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  vehicleCard: {
    width: "50%",
  },
  cardContent: {
    padding: "0.75rem !important",
    display: "flex",
    flexWrap: "wrap",
  },
  card: {
    height: "100%",
  },
  margin: {
    marginRight: ".75rem",
  },
  typography: {
    flexBasis: "100%",
  },
}));

const linkStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // flexWrap: 'nowrap',
    alignItems: "center",
    fontWeight: "500",
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

function WaybillAside({ record = {}, basePath, vehicles }) {
  const classes = useAsideStyles();
  const translate = useTranslate();
  const redirect = useRedirect();
  const linkClasses = linkStyles();
  const { orders } = record;

  const grid = 3;

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    //padding: grid,
    //width: 250,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    //padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? "lightgreen" : "white",

    ...draggableStyle,
  });

  let markers = [];

  if (orders instanceof Array) {
    markers = orders
      .filter(
        ({ latitude, longitude }) =>
          typeof latitude === "number" && typeof longitude === "number"
      )
      .sort((curr, next) => curr.priority - next.priority || 0)
      .map(({ id, code, latitude, longitude, status }) => ({
        id: id,
        lat: latitude,
        lng: longitude,
        status: status,
        title: `ID: ${code}`,
        onClick: () => redirect(`/orders/${id}/show`),
      }));
  }

  return (
    <div className={classes.root}>
      <Box m="0 0 1em 1em">
        <Card>
          <Box component="div" height="40vh" width="100%">
            <Map data={markers} />
          </Box>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {translate("resources.waybills.message.fleetAvailable")}
            </Typography>
            <Droppable droppableId="vehicles">
              {(provided, snapshot) => (
                <ul
                  className={classes.ul}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                >
                  {vehicles &&
                    Object.values(vehicles)
                      .filter(
                        ({
                          volumeCapacity,
                          volumeAssigned,
                          weightCapacity,
                          weightAssigned,
                        }) =>
                          volumeCapacity > volumeAssigned ||
                          weightCapacity > weightAssigned
                      )
                      .map((record, index) => {
                        const {
                          id,
                          plate,
                          volumeCapacity,
                          weightCapacity,
                          volumeAssigned,
                          weightAssigned,
                        } = record;
                        return (
                          <Draggable key={id} draggableId={id} index={index}>
                            {(provided, snapshot) => (
                              <li
                                className={classes.vehicleCard}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Card
                                  variant="outlined"
                                  className={classes.card}
                                >
                                  <CardContent className={classes.cardContent}>
                                    <Typography
                                      variant="subtitle1"
                                      component="h6"
                                      className={classes.typography}
                                    >
                                      <div className={linkClasses.root}>
                                        <VehicleTypeField
                                          record={record}
                                          source="type"
                                        />
                                        &nbsp; {plate}
                                      </div>
                                    </Typography>
                                    <Typography
                                      color="textSecondary"
                                      className={classes.margin}
                                    >
                                      {(
                                        weightCapacity - weightAssigned
                                      ).toFixed(2)}{" "}
                                      (kg)
                                    </Typography>
                                    <Typography color="textSecondary">
                                      {(
                                        volumeCapacity - volumeAssigned
                                      ).toFixed(2)}{" "}
                                      (mts³)
                                    </Typography>
                                  </CardContent>
                                </Card>
                              </li>
                            )}
                          </Draggable>
                        );
                      })}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
}
export default WaybillAside;
