export default {
    name: 'País |||| Países',
    label: 'Países',
    fields: { 
        name: 'Nombre del país',
        dniLegalPersonName: 'Nombre DNI Persona Jurídica', 
        dniLegalPersonFormat: 'Formato DNI Persona Jurídica', 
        dniNaturalPersonName: 'Nombre DNI Persona Natural', 
        dniNaturalPersonFormat: 'Formato DNI Persona Natural', 
    },
    message: {
        country: 'País:',
    }
}