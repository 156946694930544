import { useTranslate } from 'react-admin';

function useTranslateRangeFilter() {
  const translate = useTranslate();
  return [
    { 
      id: 'TODAY', 
      name: translate('dashboard.filters.rangeOptions.today') 
    },
    { 
      id: '15DAYS', 
      name: translate('dashboard.filters.rangeOptions.last15Days')
    },
    { 
      id: '30DAYS', 
      name: translate('dashboard.filters.rangeOptions.last30Days') 
    },
  ]
}

export default useTranslateRangeFilter;
