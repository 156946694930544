export default  {
  name: 'Plan de subscripción |||| Planes de subscripción',
  label: 'Planes de subscripción',
  fields: {
    subscription_plan_id: "Plan de subscripción",
    initial_date: "Fecha de inicio",
  },
  message:{ 
    update: 'Actualizar plan',
    updateSubscription: 'Actualizar plan de subscripción',
    subscriptionUpdated: 'Subscripción actualizada',
    subscriptionFinished: 'Su plan ha finalizado',
    subscriptionContact: 'Contacte con soporte para gestionar el pago y la reactivación de su servicio'
  }
}