import React from 'react';
import ListActions from './OrderActions';
import Aside from './Aside';
import OrderFilter from './OrderFilters';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  NumberField,
  SelectField,
  ReferenceField,
  Pagination,
  DeleteButton,
  EditButton,
  ShowButton,
} from 'react-admin';
import useTranslateOrderStatus from './useTranslateOrderStatus';
import { makeStyles } from "@material-ui/core/styles";
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

const useStyles = makeStyles({
  root: {
    width: 'calc(100% - 28.5vw)',
    '& > .MuiToolbar-root': {
      flexWrap: 'wrap-reverse',
      justifyContent: 'flex-end',
    }
  },
  content: {
    overflowX: 'scroll',
  }
})

function OrdersPagination(props){
  return(
    <Pagination 
      rowsPerPageOptions={[100, 200, 500]} 
      {...props} 
    />
  )
} 

function OrderList(props) {
  const classes = useStyles();
  const orderStatus = useTranslateOrderStatus();
  const today = new Date() //.toISOString().split('T')[0];
  function toDateString(date) {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  return (
    <List 
      classes={classes}
      actions={<ListActions />}
      aside={<Aside />} 
      filters={<OrderFilter />}
      filterDefaultValues={{ 
        createdAt_gte: toDateString(today), 
        createdAt_lte: toDateString(today), 
        waybill_id: null
      }}
      pagination={<OrdersPagination />}
      perPage={100}
      {...props} 
    >
      <Datagrid>
        <DateField 
          source="createdAt" 
        />
        <TextField 
          source="code"
        />
        <SelectField 
          source="status" 
          choices={orderStatus} 
        />
        <ReferenceField 
          source="waybill_id" 
          reference="waybills" 
        >
          <TextField source="code" />
        </ReferenceField>
        <TextField source="address" />
        {/*
        <TextField source="phone" label="Teléfono" />
        <TextField source="email" label="Correo electrónico" /> 
        */}
        <ReferenceField 
          source="client_retail_id" 
          reference="clients/retail" 
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="priority"  />
        <ShowButton label="" />
        <EditButton label="" />
        <CustomButtonDelete />
      </Datagrid>
    </List>
  );
}
export default OrderList;