import React, { useState, useCallback, useEffect, useContext } from 'react';
import { useTranslate, useDataProvider, useNotify, useRefresh } from 'react-admin';
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OptimizeWarehouse from './OptimizeWarehouse';
import OptimizeFleet from './OptimizeFleet';
import OptimizeRoutes from './OptimizeRoutes';
import WaybillContext from './context';

const useStyles = makeStyles((theme) => ({
  navContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
    color: '#ffffff',
  },
}));

const STEPS = [1, 2, 3];

const OptimizeWizard = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const { orders, closeDialog } = props;
  const [warehouses, setWarehouses] = useState(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState('');
  const [vehicles, setVehicles] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState([]);
  const [routesState, setRoutesState] = useState(false);
  const { routes, setRoutes } = useContext(WaybillContext);

  const classes = useStyles();

  useEffect(() => {
    fetchVehicles();
    fetchWarehouses();
  }, []);

  const fetchVehicles = useCallback(async () => {
    const { data } = await dataProvider.getList('vehicles', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'plate', order: 'ASC' },
      filter: { status: 'AVAILABLE' },
    });
    setVehicles(data);
  });

  const fetchWarehouses = useCallback(async () => {
    const { data } = await dataProvider.getList('warehouses', {
      pagination: { page: 1, perPage: 1000 },
      sort: { field: 'name', order: 'ASC' },
      filter: {},
    });
    const warehouses = data.map(({ id, name, address }) => ({
      id,
      name: `${name} - ${address}`,
    }));
    const defaultWarehouse = data.filter((warehouse) => warehouse.default);
    setWarehouses(warehouses);
    if (defaultWarehouse.length > 0) {
      setSelectedWarehouse(defaultWarehouse[0].id);
    }else if(warehouses.length > 0){
      setSelectedWarehouse(warehouses[0].id);
    }
  });

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const handleSubmit = () => {
    setLoading(true);
    dataProvider
      .saveOptimized('optimized', { data: routesState })
      .then(({ data }) => {
        setLoading(false);
        let newRoutes = routes ? routes : {};
        data.json.records.forEach((route) => {
          newRoutes[route.id] = route;
        });
        setRoutes(newRoutes);
        refresh();
        closeDialog();
      })
      .catch((error) => {
        notify(error.message, 'error');
        setLoading(false);
      });
  };

  const handleDisableNextStep = () => {
    return (
      loading ||
      (activeStep === 0 && !selectedWarehouse) ||
      (activeStep === 1 && selectedFleet.length === 0) ||
      (activeStep === 2 && (!routesState || routesState.length === 0))
    );
  };

  const disableNextStep = handleDisableNextStep();

  const renderStepComponent = (stepIndex = 0) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className={classes.navContainer}>
            <OptimizeWarehouse
              warehouses={warehouses}
              selectedWarehouse={selectedWarehouse}
              setSelectedWarehouse={setSelectedWarehouse}
            />
          </div>
        );
      case 1:
        return (
          <div className={classes.navContainer}>
            <OptimizeFleet
              vehicles={vehicles}
              selectedFleet={selectedFleet}
              handleSelected={setSelectedFleet}
            />
          </div>
        );
      case 2:
        return (
          <div className={classes.navContainer}>
            <OptimizeRoutes
              handleRoutes={setRoutesState}
              routesState={routesState}
              orders={orders}
              warehouse_id={selectedWarehouse}
              selectedFleet={selectedFleet}
            />
          </div>
        );
      default:
        return 'Unknown stepIndex';
    }
  };

  const renderNavigation = () => (
    <div className={classes.navContainer}>
      <Button
        className={classes.button}
        variant="contained"
        disabled={activeStep === 0}
        onClick={handlePrevStep}
      >
        {translate('ra-csv-importer.button.back')}
      </Button>
      {activeStep < STEPS.length - 1 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleNextStep}
          disabled={disableNextStep}
        >
          {translate('ra-csv-importer.button.next')}
        </Button>
      )}
      {activeStep === STEPS.length - 1 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={disableNextStep}
        >
          {translate('resources.waybills.optimize.button')}
          {loading && (
            <CircularProgress className={classes.spinner} size={20} />
          )}
        </Button>
      )}
    </div>
  );

  return (
    <>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {STEPS.map((step) => (
            <Step key={step}>
              <StepLabel>
                {translate('resources.waybills.optimize.wizard.steps.' + step)}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <div>{renderStepComponent(activeStep)}</div>
      <div>{renderNavigation()}</div>
    </>
  );
};

export default OptimizeWizard;
