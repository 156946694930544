import React, { useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router';
import { useTranslate } from 'react-admin';

import { useVersion, useDataProvider } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import MonthlyRevenue from './MonthlyRevenue';
import DailyRevenue from './DailyRevenue';
import PendingOrders from './PendingOrders';
import RegionChart from './RegionChart';
import CommuneChart from './CommuneChart';
import HeatMap from './HeatMap';
import OrderStatusChart from './OrderStatusChart';
import RetailsChart from './RetailsChart';
import WaybillsStatusChart from './WaybillsStatusChart';
import RoutesStatusChart from './RoutesStatusChart';
import useTranslateWaybillStatus from '../../resources/waybills/useTranslateWaybillStatus';
import useTranslateOrderStatus from '../../resources/orders/useTranslateOrderStatus';
import useTranslateRouteStatus from '../../resources/route/useTranslateRouteStatus';
import Filters from './Filters';

const styles = {
  flex: { display: 'flex' },
  flexColumn: { display: 'flex', flexDirection: 'column' },
  leftCol: { flex: 3, marginRight: '0.5em' },
  rightCol: { flex: 1, marginLeft: '0.5em' },
  singleCol: { marginTop: '1em', marginBottom: '1em' },
  twoCol: { flex: 2, marginTop: '1em', marginBottom: '1em' },
};

const Spacer = () => <span style={{ width: '1em' }} />;

function Dashboard() {
  const translate = useTranslate();
  const waybillStatus = useTranslateWaybillStatus();
  let waybillStatusMap = {};
  waybillStatus.forEach(status => waybillStatusMap[status.id] = status.name);
  const orderStatus = useTranslateOrderStatus();
  let orderStatusMap = {};
  orderStatus.forEach(status => orderStatusMap[status.id] = status.name);
  const routeStatus = useTranslateRouteStatus();
  let routeStatusMap = {};
  routeStatus.forEach(status => routeStatusMap[status.id] = status.name);

  const [state, setState] = useState({});
  const date = new Date();
  const today = date.toLocaleDateString('en-US');
  const month = new Date(
    date.getFullYear(),
    date.getMonth(),
    1
  ).toLocaleDateString('en-US');
  const [usedFilters, setUsedFilters] = useState({
    date_gte: today,
    range: 'TODAY',
    date_lte: today,
  });
  const version = useVersion();
  const dataProvider = useDataProvider();
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('md'));

  useEffect(() => {
    fetchData();
  }, [usedFilters]);

  const fetchData = useCallback(async () => {
    const { data } = await dataProvider.getDashboard('dashboard', {
      filter: { ...usedFilters, today: today, month: month },
    });
    data.processedWaybills.map(waybill => {
      waybill.name = waybillStatusMap[waybill.id];
      return waybill;
    });
    data.processedOrders.map(order => {
      order.name = orderStatusMap[order.id];
    });
    data.processedRoutes.map(route => {
      route.name = routeStatusMap[route.id];
    });
    setState(data);
  });

  const userInfo = JSON.parse(sessionStorage.getItem('USER_INFO')); 
  const dashboard = !(userInfo?.subscription_active)
    ? (<Redirect to="/subscription-finished" />)
    : (
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.flex}>
            <MonthlyRevenue value={state.monthRevenue} />
            <Spacer />
            <DailyRevenue value={state.dayRevenue} />
          </div>
          <div style={styles.flex}>
            <Filters
              resource="dashboard"
              variant="standard"
              filterValues={usedFilters}
              setFilters={setUsedFilters}
              hideFilter={() => false}
            />
          </div>
          <div style={styles.flex}>
            <div style={styles.twoCol}>
              <WaybillsStatusChart data={state.processedWaybills} />
            </div>
            <Spacer />
            <div style={styles.twoCol}>
              <OrderStatusChart data={state.processedOrders} />
            </div>
            <Spacer />
            <div style={styles.twoCol}>
              <RoutesStatusChart data={state.processedRoutes} />
            </div>
          </div>
          <div style={styles.singleCol}>
            <HeatMap data={state.heatData} />
          </div>
          <div style={styles.flex}>
            <div style={styles.twoCol}>
              <CommuneChart data={state.communeData} />
            </div>
            <Spacer />
            <div style={styles.twoCol}>
              <RegionChart data={state.regionData} />
            </div>
          </div>
          <div style={styles.singleCol}>
            <RetailsChart data={state.retailData} />
          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.flex}>
            <PendingOrders waybills={state.pendingWaybills} />
          </div>
        </div>
      </div>
    )

  return dashboard;
}

export default Dashboard;
