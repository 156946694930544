export default{
    name: 'Region |||| Regions',
    label: 'Regions',
    fields: {
        code: 'Code',
        name: 'Name',
        sub_areas: 'Communes',
    },
    message: {
        import: 'Import regions',
        region: 'Region',
    },
}