import React, { useState } from 'react';
import WaybillContext from '.';

const WaybillContextProvider = ({ children }) => {
  
  const [routes, setRoutes] = useState(null);
  
  const context = {
    routes,
    setRoutes,
  };
  return (
    <WaybillContext.Provider value={ context }> 
      {children}
    </WaybillContext.Provider>
  );
}
export default WaybillContextProvider;