import React, { useState } from 'react';
import {
  useTranslate,
  ListContextProvider,
  ShowButton,
  Datagrid,
  TextField,
  NumberField,
  SelectField,
  ReferenceField,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import { Typography, Toolbar, Paper } from '@material-ui/core';
import VehicleFilter from '../vehicle/VehicleFilter';
import useTranslateVehicleType from '../vehicle/useTranslateVehicleType';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 'auto',
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {translate('resources.orders.message.selected')}
        </Typography>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  datagridCell: {
    padding: '6px',
    maxWidth: '70px',
    textAlign: 'center',
    '&.column-address': {
      maxWidth: '100px',
    },
  },
  actionButton: {
    minWidth: '20px',
    padding: 0,
  },
}));

const OptimizeFleet = (props) => {
  const vehiclesType = useTranslateVehicleType();
  const [usedFilters, setUsedFilters] = useState({});
  const classes = useStyles();

  const { vehicles, selectedFleet, handleSelected } = props;

  const handleClick = (id, basePath, record) => {
    const selectedIndex = selectedFleet.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedFleet, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedFleet.slice(1));
    } else if (selectedIndex === selectedFleet.length - 1) {
      newSelected = newSelected.concat(selectedFleet.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedFleet.slice(0, selectedIndex),
        selectedFleet.slice(selectedIndex + 1)
      );
    }
    handleSelected(newSelected);
  };

  const filteredVehicles = vehicles.filter((vehicle) => {
    if (
      usedFilters.hasOwnProperty('plate') &&
      usedFilters.plate !== '' &&
      vehicle.plate !== usedFilters.plate
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('type') &&
      usedFilters.type !== '' &&
      usedFilters.type !== vehicle.type
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('transport_id') &&
      usedFilters.transport_id !== null &&
      usedFilters.transport_id !== '' &&
      usedFilters.transport_id !== vehicle.transport_id
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('driver_id') &&
      usedFilters.driver_id !== null &&
      usedFilters.driver_id !== '' &&
      usedFilters.driver_id !== vehicle.driver_id
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('volumeCapacity') &&
      usedFilters.volumeCapacity !== vehicle.volumeCapacity
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('weightCapacity') &&
      usedFilters.weightCapacity !== vehicle.weightCapacity
    ) {
      return false;
    }
    return true;
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selectedFleet.length}
          selected={selectedFleet}
        />
        <VehicleFilter
          resource="vehicles"
          setFilters={setUsedFilters}
          hideFilter={() => true}
        />
        <ListContextProvider
          value={{
            resource: 'vehicles',
            basePath: '/vehicles',
            data: keyBy(filteredVehicles, 'id'),
            ids: filteredVehicles.map(({ id }) => id),
            currentSort: { field: 'plate', order: 'ASC' },
            selectedIds: selectedFleet,
            onSelect: (selectableIDs) => handleSelected(selectableIDs),
          }}
        >
          <Datagrid
            onToggleItem={() => {}}
            hasBulkActions={true}
            rowClick={handleClick}
            onClick={(event) => console.log(selectedFleet)}
            classes={{
              headerCell: classes.datagridCell,
              rowCell: classes.datagridCell,
            }}
          >
            <TextField 
              source="plate" 
              label="resources.vehicles.fields.plate" 
            />
            <SelectField
              source="type"
              label="resources.vehicles.fields.type"
              choices={vehiclesType}
            />
            <ReferenceField
              source="transport_id"
              label="resources.vehicles.fields.transport_id"
              reference="transports"
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="driver_id"
              label="resources.vehicles.fields.driver_id"
              reference="drivers"
            >
              <TextField source="firstName" />
            </ReferenceField>
            <NumberField
              source="volumeCapacity"
              label="resources.vehicles.fields.volumeCapacity"
            />
            <NumberField
              source="weightCapacity"
              label="resources.vehicles.fields.weightCapacity"
            />
            <NumberField
              source="volumeAssigned"
              label="resources.vehicles.fields.volumeAssigned"
            />
            <NumberField
              source="weightAssigned"
              label="resources.vehicles.fields.weightAssigned"
            />
            <ShowButton label="" className={classes.actionButton} />
          </Datagrid>
        </ListContextProvider>
      </Paper>
    </div>
  );
};

export default OptimizeFleet;
