export default {
    "ra-csv-importer": {
        button: {
            import: "Importar",
            association: "Guardar Asociación",
            next: "Siguiente",
            back: "Atrás",
        },
        modal: {
            title: "Importar", 
        },
        file: {
            title: "Cargar archivo CSV", 
        },
        message:{
            message1: "Suelta un archivo aquí o haz click para seleccionar",
        },
        step: {
            one: "Cargar archivo",
            two: "Previsualizar datos",
        },
    },
}