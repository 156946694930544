import React from 'react';
import {

  Create,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  PasswordInput,
  required,
  EditButton,
  useTranslate,
  ShowButton,
  maxLength,
  minLength,
  email,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';


const validate = {
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email:  [maxLength(128), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
  password: [maxLength(16), minLength(8), required()],
  role_id: [required()],
};

function UserCreate(props) {
    const translate = useTranslate();
    return (
      <Create title={translate('resources.users.message.register')} {...props}>
        <SimpleForm>
          <TextInput source='firstName'  validate={validate.firstName} />
          <TextInput source='lastName'  validate={validate.lastName} />
          <TextInput source='email' validate={validate.email} />
          <PasswordInput source='password' validate={validate.password} />
          <TextInput source='phone' validate={validate.phone} />
          <TextInput source='dni' validate={validate.dni} />
          <TextInput source='address' validate={validate.address} />
          <ReferenceInput
            source="role_id"
            reference="roles"
            filterToQuery={(searchText) => ({ label: searchText })}
            validate={validate.role_id}
          >
            <AutocompleteInput optionText="label" />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    );
  }

export default UserCreate;