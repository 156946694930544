export default {
    "ra-csv-importer": {
        button: {
            import: "Import",
            association: "Save association",
            next: "Next",
            back: "back",
        },
        modal: {
            title: "Import", 
        },
        file: {
            title: "Upload CSV file ",
        },
        message: {
            message1: "Drop a file here or click to select"
        },
        step: {
            one: "File upload",
            two: "Preview data",
        },

    }    
}