import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    ReferenceField,
    EditButton,
    DeleteButton,
    Pagination,
    useListContext
  } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import {default as CommuneActions} from './CommuneActions'; 
import {default as CommuneFilter} from './CommuneFilter';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function OrdersPagination(props){
  return(
    <Pagination
      rowsPerPageOptions={[25, 50, 100]} 
      {...props} 
    />
  )
} 

export function CommuneList(props) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const list = (
      <SimpleList
        primaryText={(record) => record.code}
        secondaryText={(record) => record.name}
      />
    );
    const datagrid = (
      <Datagrid rowClick="show">
        <TextField source="code" />
        <TextField source="name" />
        <ReferenceField 
            source="parent_area_id" 
            reference="administrative_areas/level_1" 
        >
            <TextField source="name" />
        </ReferenceField>
        <EditButton label="" />
        <CustomButtonDelete />
      </Datagrid>
    );
    return (
      <List 
       variant="standard"
        {...props} 
        sort={{ field: 'code', order: 'ASC' }} 
        actions={<CommuneActions/>}
        filters={<CommuneFilter />}
        pagination={<OrdersPagination />}
        perPage={25} 
      >
         {isSmall ? list : datagrid}
      </List>
    );
  }

export default CommuneList;



