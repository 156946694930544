import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, DateField, TextField, NumberField, useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  rightAlignedCell: { textAlign: 'right' },
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

const Orders = ({ record }) => {
  const classes = useStyles();
  const translate = useTranslate();
  if (!record) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{translate('resources.routes.message.date')}</TableCell>
          <TableCell>{translate('resources.routes.message.id')}</TableCell>
          <TableCell>{translate('resources.routes.message.waybill')}</TableCell>
          <TableCell>{translate('resources.routes.message.address')}</TableCell>
          <TableCell>{translate('resources.routes.message.amount')}</TableCell>
          <TableCell>{translate('resources.routes.message.weight')}</TableCell>
          <TableCell>{translate('resources.routes.message.volume')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.orders?.map((item) => (
          <TableRow key={item.id}>
            <TableCell>
              <DateField record={item} source="createdAt" />
            </TableCell>
            <TableCell>
              <TextField record={item} source="code" />
            </TableCell>
            <TableCell>
              <Link to={`/waybills/${item.waybill_id}/show`}>{item.waybill.code}</Link>
            </TableCell>
            <TableCell>
              <TextField record={item} source="address" />
            </TableCell>
            <TableCell>
              <NumberField record={item} source="amount" />
            </TableCell>
            <TableCell>
              <NumberField record={item} source="weight" />
            </TableCell>
            <TableCell>
              <NumberField record={item} source="volume" />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Orders;
