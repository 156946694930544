import React from 'react';
import { ReactComponent as Icon } from './van.svg';
import { SvgIcon } from '@material-ui/core';

function VanIcon(props) {
  let color = props.color ? props.color : 'gray';
  return <SvgIcon component={Icon} viewBox="0 0 407 407" htmlColor={color} />;
}

export default VanIcon;
