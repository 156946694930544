export default {
    filters: {
        date_gte: 'Init date',
        date_lte: 'End date',
        range: 'Filter',
        rangeOptions: {
            today: "Today",
            last15Days: "Last 15 days",
            last30Days: "Last 30 days",
        },
    },
    withoutData: 'Without data',
    monthlyRevenue: "Monthly invoiced",
    weeklyRevenue: "Weekly invoiced",
    dailyRevenue: "Daily invoiced",
    pendingWaybills: {
        title: "Waiting for asign route",
        fields: {
            code: "Waybill",
            createdAt: "Date",
            orders: "Orders",
            assigned: "Assigned",
            pending: "Pending",
        }
    },
    regionChart:{
        title: "Revenue by region",
        subtitle: "Last 30 days",
    },
    communeChart:{
        title: "Top 10 Revenue by commune",
        subtitle: "Last 30 days",
    },
    heatMap:{
        title: "Delivery zone",
        subtitle: "Last 30 days",
    },
    orderStatusChart:{
        title: "Processed orders",
        subtitle: "Last 30 days",
    },
    retailsChart:{
        title: "Orders by retail client",
        subtitle: "Last 30 days",
        amount: "Amount",
        orders: "#Orders",
    },
    waybillsStatusChart:{
        title: "Waybills",
        subtitle: "Last 30 days",
    },
    routesStatusChart:{
        title: "Routes",
        subtitle: "Last 30 days",
    },
}