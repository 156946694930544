import React from 'react';
import {
    SimpleForm,
    Show,
    ImageField,
    TextField,
    DateField,
    NumberField,
    EmailField,
    SelectField,
    useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import useTranslateDeliveryStatus from './useTranslateDeliveryStatus';

function DeliveryTitle({ record }) 
{
    const translate = useTranslate();
    const translateStatus = {
        'ACCEPTED': translate('resources.deliveries.status.accepted'),
        'REJECTED': translate('resources.deliveries.status.rejected'),
    };
    return <span> {translate('resources.deliveries.message.delivery')} {`${translateStatus[record.status]}`}</span>;
}

const useStyles = makeStyles({
    form: { 
        '& .MuiCardContent-root': {
            display: 'flex', 
            flexFlow: 'row wrap',
            justifyContent: 'flex-start',
        }
    },
    field: { flexBasis: '15%' },
    shortInput: { 
        flexBasis: '15%',
        '& .MuiFormControl-root': { width: '100%' }
    },
    largeInput: { 
        flexBasis: '48.5%',
        '& .MuiFormControl-root': { width: '100%' }
    },
    fullWidth: { 
        flexBasis: '100%',
        textAlign: 'left',
        '& .MuiFormControl-root': { width: '100%' }
    },
    image: {
        width: '100%',
        maxHeight: 'unset',
    }
});

function DeliveryShow(props){
    const classes = useStyles();
    const deliveryTranslate = useTranslateDeliveryStatus();
    const dateOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
    return(
        <Show 
            {...props} 
            title={<DeliveryTitle />}
            actions={<> </>}
        >
            <SimpleForm 
                toolbar={<> </>}
                className={classes.form} 
                variant="standard"
            >
                <DateField 
                   source="createdAt"
                   options={dateOptions}
                   formClassName={classes.shortInput}
                   showTime
                />
                <SelectField 
                    source='status'
                    choices={deliveryTranslate}
                    formClassName={classes.shortInput}
                />
                <TextField 
                    source='observations' 
                    formClassName={classes.fullWidth}
                />
                 <ImageField 
                    source='photo' 
                    formClassName={classes.fullWidth}
                    classes = {{
                        image: classes.image
                    }}
                /> 
            </SimpleForm>
        </Show>
    );
}

export default DeliveryShow;
