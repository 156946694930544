export default  {
    name: 'Conductor |||| Conductores',
    label: 'Conductores',
    fields: {
        transport_id: 'Empresa de transporte',
        firstName: 'Nombre',
        lastName: 'Apellido',
        dni: 'RUT',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        photo: 'Foto',
        address: 'Dirección',
        licenseCode: "Código licencia de conducir", 
        licenseType: "Tipo de licencia de conducir", 
        licenseExpire: "Vencimiento licencia de conducir", 
        subscription_last_day: "Último día del plan",
        subscription_plan_id: "Plan de Subscripción"
    },
    message:{ 
        driver: 'Conductor:'
    }
}