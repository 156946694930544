import React from 'react';
import { useTranslate } from 'react-admin';
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
      fontSize: '1rem',
  }
});


const styles = {
  backgroundColor: { backgroundColor: 'rgba(247, 252, 255, 0.8)' },
  chartSize: { width: '99%', height: 300 },
};

function RegionChart({ data }) {
  const translate = useTranslate();
  const classes = useStyles();

  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <Card>
          <CardContent>
            <p className="label">{`${payload[0].payload.name} : $${payload[0].value}`}</p>
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.title
        }}
        title={translate('dashboard.regionChart.title')}
      />
      <CardContent>
        <div style={styles.chartSize}>
          {data && data.length>0 ?(
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <YAxis
                  label={{
                    value: 'Monto $',
                    angle: -90,
                    position: 'insideLeft',
                    textAnchor: 'middle',
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar dataKey="value" barSize={20} fill="#2196f3" />
              </BarChart>
            </ResponsiveContainer>
          ): <Typography>{translate('dashboard.withoutData')} </Typography>}
        </div>
      </CardContent>
    </Card>
  );
}

export default RegionChart;
