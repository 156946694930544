import React from 'react';
import { Button } from '@material-ui/core';

function ColorField({ record = {}, source }) {
  return (
    <Button disabled style={{ backgroundColor: record[source] }}>
      &nbsp;
    </Button>
  );
}

export default ColorField;
