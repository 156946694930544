import React, { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  downloadCSV,
  useTranslate,
} from 'react-admin';
import OrderReportExportPDF from './OrderReportExportPDF';
import useTranslateOrderStatus from '../../orders/useTranslateOrderStatus';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';

function OrderReportActions(props) {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  const orderStatus = useTranslateOrderStatus();
  const translate = useTranslate();

  const mapStatus = {};
  orderStatus.forEach((status) => {
    mapStatus[status.id] = status.name;
  });

  const fieldsMap = {
    code: translate('resources.orders.fields.code'),
    received: translate('resources.orders.status.received'),
    planned_in_route: translate('resources.orders.status.planned_in_route'),
    assigned_in_transport: translate('resources.orders.status.assigned_in_transport'),
    in_transit: translate('resources.orders.status.in_transit'),
    accepted_by_client: translate('resources.orders.status.accepted_by_client'),
    vehicle: translate('resources.routes.fields.vehicle_id'),
  };

  const exporter = (records) => {
    const recordsForExport = records.map((record) => {
      const csvData = {
        [fieldsMap.code]: record.code,
        [fieldsMap.received]: record.events.GEOCODE_SUCCESSED
          ? format(new Date(record.events.GEOCODE_SUCCESSED), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.planned_in_route]: record.events.ROUTE_PLANNING
          ? format(new Date(record.events.ROUTE_PLANNING), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.assigned_in_transport]: record.events.TRANSPORT_ASSIGNMENT
          ? format(new Date(record.events.TRANSPORT_ASSIGNMENT), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.in_transit]: record.events.START_ROUTE
          ? format(new Date(record.events.START_ROUTE), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.accepted_by_client]: record.events.DELIVER
          ? format(new Date(record.events.DELIVER), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.vehicle]: record.vehiclePlate,
      };
      return csvData;
    });
    jsonExport(
      recordsForExport,
      {
        headers: [],
      },
      (err, csv) => {
        downloadCSV(csv, 'order_report'); // download as 'order_report.csv`
      }
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        exporter={exporter}
      />
      <OrderReportExportPDF
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
}

export default OrderReportActions;
