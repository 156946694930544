import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  useTranslate,
  required,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import useTranslateRouteStatus from './useTranslateRouteStatus';


function RouteTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.routes.message.route')} {`${record.code}`}</span>;
}

const validate = {
  vehicle_id: [],
  description: [maxLength(256)],
  warehouse: [required()],
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function RouteEdit(props) {
  const routeStatus = useTranslateRouteStatus();
  const classes = useStyles();
  return (
    <Edit 
      title={<RouteTitle />} 
      {...props}
    >
      <SimpleForm
        variant="standard"
      >
        <TextInput
          source='description'
          validate={validate.description}
          formClassName={classes.inlineBlock}
        />
        <ReferenceInput
          source='vehicle_id'
          reference='vehicles'
          allowEmpty
          filterToQuery={(searchText) => ({ plate: searchText, status: 'AVAILABLE' })}
          filter={{ status: 'AVAILABLE' }}
          validate={validate.vehicle_id}
        >
          <AutocompleteInput optionText="plate" />
        </ReferenceInput>
        <ReferenceInput
          source="warehouse_id" 
          reference="warehouses" 
          variant="standard"
          formClassName={classes.shortInput}
          //validate={validate.clientRetail}
          alwaysOn 
        > 
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export default RouteEdit;
