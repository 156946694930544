import React from 'react';
import {
  Show,
  SimpleShowLayout,
  BooleanField,
  TopToolbar,
  ListButton,
  TextField,
  ArrayField,
  Datagrid,
  EditButton,
  useTranslate,
  ShowButton,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { default as RoleAside } from './RoleAside';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  hideHeaderCell: {
    display: 'none',
  },
});

function RoleTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.roles.message.role')} {`${record.label}`}</span>;
}

const RoleShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} icon={<ChevronLeft />} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

export function RoleShow(props) {
  const classes = useStyles();

  return (
    <Show
      title={<RoleTitle />}
      actions={<RoleShowActions />}
      aside={<RoleAside />}
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="label"  />
        <ArrayField source="permissions" >
          <Datagrid>
            <TextField source="name"  />
            <ArrayField source="actions">
              <Datagrid classes={{ headerCell: classes.hideHeaderCell }}>
                <BooleanField source="allowed" label="" />
                <TextField source="actionLabel" label="" />
              </Datagrid>
            </ArrayField>
          </Datagrid>
        </ArrayField>
      <ShowButton label="" />
      </SimpleShowLayout>
    </Show>
  );
}

export default RoleShow;
