import React, { useContext, useState } from 'react';
import WaybillContext from './context';
import {
  required,
  SaveButton,
  Button,
  useUpdateMany,
  useNotify,
  useRefresh,
  FormWithRedirect,
  useTranslate,
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';
import RouteReferenceInput from './RouteReferenceInput';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

const RouteAssign = (props) => {
  const translate = useTranslate();
  const { routes, setRoutes } = useContext(WaybillContext);
  const [showDialog, setShowDialog] = useState(false);
  const{selected} = props;
  const [updateMany, { loading }] = useUpdateMany('orders');
  const notify = useNotify();
  const refresh = useRefresh();

  const handleSubmit = async (values) => {
    updateMany({ payload: { data: values, ids: selected } },
    {
      onSuccess: ({ data }) => {
        let newRoutes = routes? routes: {};
        data.forEach(order => {
          if ( newRoutes && order.route_id in newRoutes){
            let found = false;
            newRoutes[order.route_id].orders.map((oldOrder) => {
              if (oldOrder.id === order.id){
                oldOrder = order;
                found=true;
              }
            });
            if (!found){
              newRoutes[order.route_id].orders.push(order);
            }
          }
          else{
            newRoutes[order.route_id] = order.route;
            newRoutes[order.route_id].orders = [order];
          }
        });
        setRoutes(newRoutes);
        refresh();
      },
      onFailure: (error) => {
        notify(error.message, 'error');
      },
    });
  };

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button onClick={handleClick} label={translate('resources.waybills.assign.button')}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('resources.waybills.assign.modalTitle')}
      >
        <DialogTitle>{translate('resources.waybills.assign.modalTitle')}</DialogTitle>

      <FormWithRedirect
        resource="orders"
        save={handleSubmit}
        render={({ handleSubmitWithRedirect, pristine, saving }) => (
          <>
          <DialogContent>
            <RouteReferenceInput
              source="route_id"
              reference="routes"
              validate={required()}
              perPage={10000}
            />
            </DialogContent>
            <DialogActions>
              <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
              
                <SaveButton
                  label={translate('resources.waybills.button.assign')}
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                  icon={<></>}
                />
              
            </DialogActions>
          </>
        )}
      />
      </Dialog>
    </>
  );
};

export default RouteAssign;
