import React, { useState, useEffect } from 'react';
import {
    TextInput,
    NumberInput,
    SimpleForm,
    Edit,
    ReferenceField,
    TextField,
    maxLength,
    email,
    required,
    useTranslate,
    BooleanInput,
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import GeocodeButton from '../../components/GeocodeButton';

const useStyles = makeStyles({
    hide: {
      display: 'none',
    },
    form: { 
        '& .MuiCardContent-root': {
            display: 'flex', 
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    },
    fullWidth: {
      width: '100%'
    },
    shortInput: { 
        flexBasis: '30%',
        '& .MuiFormControl-root': { width: '100%' },
    },
    shortBoolean: { 
        flexBasis: '15%',
        '& .MuiFormControl-root': { width: '100%' },
        '& .MuiFormGroup-root': { width: '100%' },
    },
    largeInput: { 
        flexBasis: '50%',
        '& .MuiFormControl-root': { width: '100%' }
    },
  })


function WarehouseTitle({ record }) {
    return <span> {`${record.name}`}</span>;
}

const validate={
    name: [maxLength(128), required()],
    address: [maxLength(128), required()],
}

function WarehouseEdit(props){
    const classes = useStyles();
    const translate = useTranslate();
    const validateGeocode = (values) => {
        const errors = {};
        if (
          (!values.latitude && typeof values.latitude !== 'number')  
          || (!values.longitude && typeof values.latitude !== 'number')
        ) {
          errors.latitude = translate('resources.warehouses.message.geocode') ;
        }
        return errors
      };

    return(
        <Edit 
            {...props}
            title={<WarehouseTitle />} 
        >
            <SimpleForm 
                className={classes.form} 
                variant='standard'  
                validate={validateGeocode}
            >
                <TextInput 
                    source='name'
                    validate={validate.name}
                    formClassName={classes.shortInput}
                />
                <BooleanInput  
                    source="default" 
                    formClassName={classes.shortBoolean} 
                />
                <TextInput 
                    source='address' 
                    validate={validate.address}
                    formClassName={classes.largeInput}
                />
                <GeocodeButton
                    latSource="latitude"
                    lngSource="longitude"
                    addressSource="address"
                    formClassName={classes.fullWidth} />
            </SimpleForm>
        </Edit>
    );
}

export default WarehouseEdit;
