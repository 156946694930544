export default {
  name: "Route |||| Routes",
  label: "Routes",
  fields: {
    code: "ID",
    description: "Description",
    vehicle_id: "Vehicle",
    transport_id: "Transport",
    status: "Status",
    createdAt: "Created",
    createdAt_gte: "Init date",
    createdAt_lte: "End date",
    updatedAt: "Updated",
    warehouse_id: "Warehouse",
    warehouse: "Warehouse",
    weight: "Weight (kg)",
    volume: "Volume  (mts³)",
    distance: "Distance (km)",
    duration: "Duration (min)",
  },
  status: {
    initial: "Initial",
    created: "Created",
    assigned: "Assigned to a transport",
    accepted: "Accepted by driver",
    inTransit: "In transit",
    finished: "Finished",
  },
  orderLabels: {
    actionButton: "Labels",
    filename: "labels-to-orders-of-route-",
    fields: {
      order: "Order",
      route: "Route",
      deliveryTo: "Deliver to",
      deliveryNumber: "Delivery No.",
      phone: "Phone",
      address: "Address",
    },
  },
  message: {
    date: "Date",
    id: "ID",
    name: "Name",
    address: "Address",
    amount: "Amount",
    waybill: "Waybill",
    capacity: "Vehicle capacity",
    mts: "(mts³)",
    kg: "(kg)",
    assigned: "Assigned",
    available: "Available",
    driver: "Driver",
    order: "Orders",
    assignedVehicle: "Assigned vehicle",
    unAssignedVehicle: "Un assigned vehicle",
    route: "Route:",
    weight: "Weight (kg)",
    volume: "Volume (mts³)",
    back: "Back",
  },
};
