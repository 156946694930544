export default {
  name: "Órden |||| Órdenes",
  label: "Órdenes",
  fields: {
    createdAt: "Fecha de creación",
    updatedAt: "Fecha de ultima actualización",
    code: "ID",
    firstname: "Nombre",
    lastname: "Apellido",
    email: "Email",
    retail: "Cliente retail",
    street: "Calle",
    streetNumber: "Número",
    commune: "Comuna",
    region: "Región",
    address: "Dirección",
    address_references: "Referencias de la dirección",
    phone: "Teléfono",
    amount: "Monto",
    priority: "Prioridad",
    createdAt_lte: "Fecha fin",
    createdAt_gte: "Fecha inicio",
    administrative_area_level_1_id: "Región",
    administrative_area_level_2_id: "Comuna",
    client_retail_id: "Cliente retail",
    geocoded: "Geocodificación",
    waybill_id: "Guía de carga",
    weight: "Peso (Kg) ",
    volume: "Volumen (mts³)",
    qr_code: "Código QR",
    isImportable: "Recepcionada",
    status: "Estatus",
    valid: "Válida",
  },
  status: {
    initial: "Inicial",
    received: "Recepcionada",
    without_geocoding: "Sin ubicación",
    invalid: "Inválida",
    planned_in_route: "Planificada en ruta",
    assigned_in_transport: "Asignada a transporte",
    loaded_in_transport: "Cargada en transporte",
    in_transit: "En tránsito",
    accepted_by_client: "Aceptada por el cliente",
    rejected_by_client: "Rechazada por el cliente",
    replanned_in_route: "Replanificada en ruta",
    error: "Error",
    assigned: "Asignada",
    pending: "Pendiente",
  },
  import_step: {
    one: "Cargar archivo",
    two: "Geocodificación",
    three: "Previsualizar datos",
  },
  modal: {
    title: "Importar órdenes",
  },
  message: {
    geocoding: "Geocodificando direcciones, por favor",
    longWait: "vaya por una taza de café ☕",
    shortWait: "espere...",
    noReceived: "Órden no recepcionada",
    receive: "Órden ya recepcionada",
    geocoded: "Dirección geocodificada",
    noGeocoded: "Dirección no geocodificada",
    label: "Entregas",
    order: "Orden:",
    back: "Atrás",
    selected: "Seleccionados",
    relocate: "¿Desea cambiar la ubicación de entrega?",
    yes: 'Sí',
    no: 'No',
  },
  errors: {
    weightExceedVehicleCapacity:
      "Orden con peso inválido, no puede exceder la capacidad de peso del vehículo asignado",
    volumeExceedVehicleCapacity:
      "Orden con volumen inválido, no puede exceder la capacidad de volumen del vehículo asignado",
    assignmentToRouteExceedVehicleCapacity:
      "Las órdenes para asignar exceden la capacidad del vehículo asignado a la ruta",
  },
};
