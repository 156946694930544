import React from 'react';
import DollarIcon from '@material-ui/icons/AttachMoney';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';

const MonthlyRevenue = ({ value }) => {
    const translate = useTranslate();
    return (
        <CardWithIcon
            to="/commands"
            icon={DollarIcon}
            title={translate('dashboard.monthlyRevenue')}
            subtitle={value ? value.toFixed(2) : '0.00'}
        />
    );
};

export default MonthlyRevenue;