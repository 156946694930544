import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  maxLength,
  email,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const validate = {
  transport_id: [required()],
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email: [maxLength(64), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
  licenseCode: [maxLength(32), required()],
  licenseType: [maxLength(32), required()],
  licenseExpire: [required()],
};

function formatImage(value) {
  if (!value || typeof value === 'string') {
    return { url: value };
  } else {
    return value;
  }
}

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function DriverCreate(props) {
  const classes = useStyles();
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput
          validate={validate.transport_id}
          source='transport_id'
          reference='transports'
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText='name' />
        </ReferenceInput>
        <ImageInput
          format={formatImage}
          source='photo'
          accept='image/*'
        >
          <ImageField source='photo' title='' />
        </ImageInput>
        <TextInput
          source='firstName'
          validate={validate.firstName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='lastName'
          validate={validate.lastName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='email'
          validate={validate.email}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='phone'
          validate={validate.phone}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='dni'
          validate={validate.dni}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address'
          validate={validate.address}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='licenseCode'
          validate={validate.licenseCode}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='licenseType'
          validate={validate.licenseType}
          formClassName={classes.inlineBlock}
        />
        <DateInput
          source='licenseExpire'
          validate={validate.licenseExpire}
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Create>
  );
}

export default DriverCreate;
