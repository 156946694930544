import React, { useState, useEffect } from "react";

const convertImageToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file);
  });

function PreviewImageField({ record, source, ...props }) {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (typeof record[source] === "string" && record[source]) {
      return setImage(record[source]);
    }
    if (record.rawFile instanceof File) {
      convertImageToBase64(record.rawFile).then((image) => {
        setImage(image);
      });
    }
    setImage("");
  }, [record[source], record.rawFile]);

  const style = {
    maxWidth: 200,
  };

  return <img src={image} style={style} alt="" />;
}

export default PreviewImageField;
