import { default as waybills } from "../es/waybills";

export default {
  ...waybills,
  fields: {
    ...waybills.fields,
    commune: "Ciudad",
    region: "Estado",
    administrative_area_level_1_id: "Estado",
    administrative_area_level_2_id: "Ciudad",
    addressHasAreas: "Dirección incluye ciudad y estado",
  },
};
