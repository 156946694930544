import React from 'react';
import {
  TextField,
  BooleanField,
} from 'react-admin';
import { ImportList } from '../../modules/ra-csv-importer';

function RetailImportList(props) {
    return (
      <ImportList {...props}>
        <TextField source="dni" />
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="phone" />
      </ImportList>
    );
  }

  export default RetailImportList;