import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  maxLength,
  number,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const validate = {
  plate: [maxLength(32), required()],
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function RoleCreate(props) {
  const classes = useStyles();
  return (
    <Create  {...props}>
      <SimpleForm>
        <TextInput
          source="label"
          validate={validate.plate}
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Create>
  );
}

export default RoleCreate;
