import React from 'react';
import { Layout } from 'react-admin';
import {default as CustomAppBar} from './CustomAppBar';
import TreeMenu from '@bb-tech/ra-treemenu';


function CustomLayout(props){
    return(
        <Layout
            {...props}
            appBar={CustomAppBar}
            menu={TreeMenu}
        >
        </Layout>
    )
}

export default CustomLayout;