import React from "react";
import {
  Edit,
  TopToolbar,
  ListButton,
  TextField,
  NumberField,
  SelectField,
  Labeled,
  FormWithRedirect,
  EditButton,
  DateField,
  Link,
  useTranslate,
  ReferenceField,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import { default as RouteAside } from "./RouteAside";
import useTranslateRouteStatus from "./useTranslateRouteStatus";
import ExportRouteOrdersLabels from "./ExportRouteOrdersLabels";

import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Avatar,
} from "@material-ui/core";
import { default as VehicleTypeField } from "../../components/VehicleTypeField";

import { makeStyles } from "@material-ui/core/styles";

import Orders from "./Orders";

function RouteTitle({ record }) {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.routes.message.route")} {`${record.code}`}
    </span>
  );
}

const Spacer = () => <Box m={1}>&nbsp;</Box>;

function RouteShowActions({ basePath, data }) {
  const translate = useTranslate();

  return (
    <TopToolbar>
      <ListButton
        basePath={basePath}
        label={translate("resources.routes.message.back")}
        icon={<ChevronLeft />}
      />
      <ExportRouteOrdersLabels record={data} />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
}

const useStyles = makeStyles({
  root: { alignItems: "flex-start" },
});

const linkStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "nowrap", alignItems: "center" },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

const dateOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

const RouteForm = (props) => {
  const classes = useStyles();
  const routeStatus = useTranslateRouteStatus();
  const linkClasses = linkStyles();
  const translate = useTranslate();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box className={classes.mainBox}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    {formProps.record.code}
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        label={translate("resources.routes.fields.description")}
                      >
                        <TextField
                          source="description"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        label={translate("resources.routes.fields.status")}
                      >
                        <SelectField
                          source="status"
                          choices={routeStatus}
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>

                      <Labeled label={translate('resources.routes.fields.weight')}>
                        <NumberField
                          source="weight"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.routes.fields.volume')}>
                        <NumberField
                          source="volume"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.routes.fields.distance')}>
                        <NumberField
                          source="distance"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                    {/* <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate("resources.routes.fields.duration")}>
                        <NumberField
                          source="duration"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={12}>
                      <Labeled
                        label={translate("resources.routes.fields.warehouse")}
                      >
                        <TextField
                          record={formProps.record}
                          source="warehouse.address"
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        label={translate("resources.routes.fields.createdAt")}
                      >
                        <DateField
                          source="createdAt"
                          record={formProps.record}
                          options={dateOptions}
                          showTime
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled
                        label={translate("resources.routes.fields.updatedAt")}
                      >
                        <DateField
                          source="updatedAt"
                          record={formProps.record}
                          options={dateOptions}
                          showTime
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    {formProps.record.vehicle
                      ? translate("resources.routes.message.assignedVehicle")
                      : translate("resources.routes.message.unAssignedVehicle")}
                  </Typography>
                  {formProps.record.vehicle && (
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Link to={`/vehicles/${formProps.record.vehicle.id}`}>
                          <div className={linkClasses.root}>
                            <VehicleTypeField
                              source="type"
                              record={formProps.record.vehicle}
                            />
                            &nbsp;
                            {formProps.record.vehicle.plate}
                          </div>
                        </Link>
                      </Grid>
                      <Typography variant="h6" gutterBottom>
                        {translate("resources.routes.message.driver")}
                      </Typography>
                      {formProps.record.vehicle.driver && (
                        <Grid item xs={12} sm={12} md={12}>
                          <Link
                            to={`/drivers/${formProps.record.vehicle.driver.id}`}
                          >
                            <div className={linkClasses.root}>
                              <Avatar
                                src={`${formProps.record.vehicle.driver.photo}?size=25x25`}
                                className={linkClasses.avatar}
                              />
                              <TextField
                                source="name"
                                record={formProps.record.vehicle.driver}
                              />
                            </div>
                          </Link>
                        </Grid>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Spacer />
              <Typography variant="h6" gutterBottom>
                {translate("resources.routes.message.order")}{" "}
                {`(${formProps.record.orders.length})`}
              </Typography>
              <Orders record={formProps.record} />
            </CardContent>
          </Card>
        </Box>
      )}
    />
  );
};

export function RouteShow(props) {
  const classes = useStyles();

  return (
    <Edit
      title={<RouteTitle />}
      actions={<RouteShowActions />}
      aside={<RouteAside />}
      classes={classes}
      component="div"
      {...props}
    >
      <RouteForm />
    </Edit>
  );
}

export default RouteShow;
