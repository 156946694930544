export default {
    name: 'Transport |||| Transports',
    label: 'Transport',
    fields: {
        name: 'Name',
        dni: 'DNI',
        address: 'Address',
        phone: 'Phone',
        website: 'Website URL',
        callCenterPhone: 'Callcenter phone',
        contactFirstName: 'Contact firstname',
        contactLastName: 'Contact lastname ',
        contactEmail: 'Contact email',
        contactPhone: 'Contact phone',
        contac: 'Contact person',
    },
    message: {
        vehicles: 'Vehicles',
        drivers: 'Drivers',
        register: "Register transport company",
        plate: 'Plate',
        brand: 'Brand',
        model: 'Model',
        maxMt: 'Max. (mts³)',
        maxKg: 'Max. (kg)',
        driver: 'Driver',
        name: 'Name',
        dni: 'DNI',
        email: 'Email',
        capacity: 'Fleet capacity',
        volume: 'Volume',
        weight: 'Weight',
        assigned: 'Assigned',
        available: 'Available',
        details: 'Details',
        created: 'Created',
        update: 'Update',
        transportCompany: 'Transport company:',
    }
}