export default {
    name: 'User |||| Users',
    label: 'Users',
    fields: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        email: 'Email',
        phone: 'Phone',
        dni: 'DNI',
        password: 'Password',
        address: 'Address',
        createdAt: 'Date',
        updatedAt: 'Last update',
        role_id: 'Role',
    },
    message: {
        register: 'Register user',
        user: 'User:',
        success: 'User updated successfully!',
    },
}