import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  ShowButton,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { default as RoleActions } from './RoleActions';

function RoleList(props) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = <SimpleList primaryText={(record) => `${record.label}`} />;
  const datagrid = (
    <Datagrid rowClick="show">
      <TextField source="label" />
      <ShowButton label="" />
      <EditButton label="" />
    </Datagrid>
  );
  return (
    <List
      {...props}
      actions={<RoleActions />}
      filter={{ name_in: ['driver', 'planner'] }}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default RoleList;
