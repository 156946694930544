import React, { useEffect, useCallback, useState } from 'react';
import { useTranslate, useDataProvider, Button } from 'react-admin';
import useTranslateWaybillStatus from '../../waybills/useTranslateWaybillStatus';
import GetAppIcon from '@material-ui/icons/GetApp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';


const generatePDF = (records, columns, mapStatus, title) => {
  const doc = new jsPDF();
  
  const tableRows = [];
  records.forEach((record) => {
    const recordData = [
      record.code,
      format(new Date(record.createdAt), 'DD/MM/YYYY'),
      record.orders,
      record.volume,
      record.weight,
      mapStatus[record.status],
    ];
    tableRows.push(recordData);
  });

  doc.autoTable(columns, tableRows, { startY: 60 });
  const imageData = '/chitrack-background.png';
  doc.text(title, 15, 50);
  doc.addImage(imageData, 'PNG', 15, 20, 90, 20);
  doc.save(`waybills_report.pdf`);
};

function WaybillReportExportPDF(props) {
  const [records, setRecords] = useState([]);
  const { disabled, resource, sort, filterValues } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const waybillStatus = useTranslateWaybillStatus();

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  const fetchData = useCallback(async () => {
    const { data } = await dataProvider.getList(resource, {
      filter: { ...filterValues },
      sort: { ...sort },
      pagination: {page: 1, perPage: 1000},
    });

    setRecords(data);
  });

  const columns = [
    translate('resources.waybills.fields.code'),
    translate('resources.waybills.fields.createdAt'),
    translate('resources.waybills.fields.orders'),
    translate('resources.waybills.fields.volume'),
    translate('resources.waybills.fields.weight'),
    translate('resources.waybills.fields.status'),
  ];

  const mapStatus = {};
  waybillStatus.forEach((status) => {
    mapStatus[status.id] = status.name;
  });

  return (
    <Button
      onClick={() => {
        generatePDF(records, columns, mapStatus, translate('resources.reports.waybill.label'));
      }}
      label="resources.reports.exportPDF"
      disabled={disabled}
    >
      <GetAppIcon />
    </Button>
  );
}

export default WaybillReportExportPDF;
