import React from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';

import CardWithIcon from './CardWithIcon';
import { WaybillIcon } from '../../resources/waybills';

const PendingOrders = ({ waybills }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <CardWithIcon
      to="/waybills"
      icon={WaybillIcon}
      title={translate('dashboard.pendingWaybills.title')}
      subtitle={waybills && waybills.length}
    >
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                {translate('dashboard.pendingWaybills.fields.code')}
              </TableCell>
              <TableCell>
                {translate('dashboard.pendingWaybills.fields.createdAt')}
              </TableCell>
              <TableCell align="right">
                {translate('dashboard.pendingWaybills.fields.orders')}
              </TableCell>
              <TableCell align="right">
                {translate('dashboard.pendingWaybills.fields.assigned')}
              </TableCell>
              <TableCell align="right">
                {translate('dashboard.pendingWaybills.fields.pending')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {waybills &&
              waybills.map((record) => (
                <TableRow key={record.code}>
                  <TableCell component="th" scope="row">
                    <Typography
                      component={Link}
                      to={`/waybills/${record.id}/show`}
                      color="primary"
                    >
                      {record.code}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {new Date(record.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell align="right">{record.orders}</TableCell>
                  <TableCell align="right">{record.assigned}</TableCell>
                  <TableCell align="right">{record.pending}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardWithIcon>
  );
};

const useStyles = makeStyles((theme) => ({
  link: {
    borderRadius: 0,
  },
  linkContent: {
    color: theme.palette.primary.main,
  },
}));

export default PendingOrders;
