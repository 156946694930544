import React from 'react';
import { useTranslate, List, Datagrid, TextField , SimpleShowLayout, RichTextField } from 'react-admin';
import DeleteWithCustomConfirmButton from 'ra-delete-with-custom-confirm-button';
import Delete from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutline from '@material-ui/icons/ErrorOutline';

// Define your custom title of confirm dialog
const DeleteConfirmTitle = 'confirmDelete.message';

// Define your custom contents of confirm dialog
const DeleteConfirmContent = props => {
  return (
    <SimpleShowLayout {...props} >
    </SimpleShowLayout>
  );
};

function CustomButtonDelete(props) {
  const translate = useTranslate();
  return (
        <DeleteWithCustomConfirmButton
          {...props}
          title={DeleteConfirmTitle}     
          content={DeleteConfirmContent} 
          label=''                 
          confirmColor='warning'          
          ConfirmIcon={Delete}    
          cancel=''                   
          CancelIcon={CancelIcon}       
          undoable={true}
          classes={{}}                
        />
  );
};

export default CustomButtonDelete;