import React from 'react';
import { CircularProgress } from '@material-ui/core';
import { SelectInput, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

const OptimizeWarehouse = (props) => {
  const { warehouses, selectedWarehouse, setSelectedWarehouse } = props;
  const translate = useTranslate();

  const handleWarehouse = (warehouse_id) => {
    setSelectedWarehouse(warehouse_id);
  };

  return (
    <>
      {warehouses && warehouses.length > 0 &&
        (
          <SelectInput
            source="warehouse_id"
            label="resources.waybills.fields.warehouse_id"
            choices={warehouses}
            value={selectedWarehouse}
            variant="standard"
            onChange={(event) => handleWarehouse(event.target.value)}
          />
        )
      }
      {warehouses && warehouses.length === 0 && (
        <Typography>
          {translate('resources.waybills.optimize.noWarehouse')}
        </Typography>
      )}
      {!warehouses && (<CircularProgress size={20} />)}
    </>
  );
};

export default OptimizeWarehouse;
