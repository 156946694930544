import React, { useState } from "react";
import {
  ListContextProvider,
  Datagrid,
  TextField,
  ShowButton,
  useTranslate,
} from "react-admin";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import keyBy from "lodash/keyBy";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import VehicleTypeField from "../../components/VehicleTypeField";
import Map from "../../components/Map";

const accordionStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: { padding: 0 },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "25%",
    flexShrink: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  vehicleCard: {
    padding: "10px !important",
  },
  datagridCell: {
    padding: "6px",
    maxWidth: "80px",
    textAlign: "center",
    "&.column-address": {
      maxWidth: "100px",
    },
  },
  actionButton: {
    minWidth: "20px",
    padding: 0,
  },
}));

const linkStyles = makeStyles((theme) => ({
  root: { display: "flex", flexWrap: "nowrap", alignItems: "center" },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

function OptimizedRoutesList({ routes, error, ...props }) {
  const [expanded, setExpanded] = useState(false);
  const translate = useTranslate();

  const accordionClass = accordionStyles();
  const classes = useStyles();
  const linkClasses = linkStyles();

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const convertOrderStatusInColor = (status = "", relocated=false) => {
    switch (status) {
      case 'REJECTED_BY_CLIENT':
        return '#F50C35';
      case 'ACCEPTED_BY_CLIENT':
        return '#51B86A';
      default:
        return relocated ? '#9e34eb' : '#F6CC1A';
    }
  };

  return (
    <>
      {routes &&
        routes.length > 0 &&
        routes.map((route) => {
          let markers = [];
          const { warehouse, orders } = route;
          if (
            typeof warehouse.latitude === "number" &&
            typeof warehouse.longitude === "number"
          ) {
            markers = [{ lat: warehouse.latitude, lng: warehouse.longitude }];
          }
          orders
            .filter(
              ({ latitude, longitude }) =>
                typeof latitude === "number" && typeof longitude === "number"
            )
            .forEach((order) => {
              markers.push({
                lat: order.latitude,
                lng: order.longitude,
                title: `ID: ${order.code}`,
                label: order.delivery_number,
                color: convertOrderStatusInColor(order.status, order.relocated),
              });
            });
          return (
            <Accordion
              onChange={handleChange(`op-${route.code}`)}
              expanded={expanded === `op-${route.code}`}
              key={`op-${route.code}`}
              className={classes.root}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`op-${route.code}bh-content`}
                id={`op-${route.code}`}
              >
                <div className={classes.heading}>
                  <Typography className={classes.heading}>
                    {route.code}&nbsp;({route.orders.length})
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {route.description}
                  </Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.heading}>
                    {translate("resources.waybills.fields.distance")}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {`${route.distance.toFixed(2)} ${translate(
                      "resources.waybills.message.distanceUnit"
                    )}`}
                  </Typography>
                </div>
                <div className={classes.heading}>
                  <Typography className={classes.heading}>
                    {translate("resources.waybills.message.weightAndVolume")}
                  </Typography>
                  <Typography className={classes.secondaryHeading}>
                    {route.weight} {translate("resources.waybills.message.kg")}{" "}
                    {route.volume} {translate("resources.waybills.message.mts")}
                  </Typography>
                </div>
                {route.vehicle && (
                  <Card style={{ backgroundColor: "white" }}>
                    <CardContent className={classes.vehicleCard}>
                      <Typography className={linkClasses.root}>
                        <VehicleTypeField
                          record={route.vehicle}
                          source="type"
                        />
                        &nbsp; {route.vehicle.plate}
                      </Typography>
                      <Typography color="textSecondary">
                        {(
                          route.vehicle.weightCapacity -
                          route.vehicle.weightAssigned
                        ).toFixed(2)}{" "}
                        {translate("resources.waybills.message.kg")}{" "}
                        {(
                          route.vehicle.volumeCapacity -
                          route.vehicle.volumeAssigned
                        ).toFixed(2)}{" "}
                        {translate("resources.waybills.message.mts")}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </AccordionSummary>
              <AccordionDetails className={accordionClass.details}>
                <ListContextProvider
                  value={{
                    resource: "orders",
                    basePath: "/orders",
                    data: keyBy(route.orders, "id"),
                    ids: route.orders.map(({ id }) => id),
                    currentSort: { field: "id", order: "ASC" },
                    selectedIds: [],
                  }}
                >
                  <Datagrid
                    rowClick="show"
                    classes={{
                      headerCell: classes.datagridCell,
                      rowCell: classes.datagridCell,
                    }}
                  >
                    <TextField source="code" label="ID" />
                    <TextField
                      source="waybill.code"
                      label={translate("resources.orders.fields.waybill_id")}
                    />
                    <TextField source="address" />
                    <TextField source="priority" />
                    <TextField source="weight" />
                    <TextField source="volume" />
                    <ShowButton label="" className={classes.actionButton} />
                  </Datagrid>
                </ListContextProvider>
                <Box component="div" height="40vh" width="40%">
                  <Map data={markers} route={true} />
                </Box>
              </AccordionDetails>
            </Accordion>
          );
        })}
      {routes && routes.length === 0 && (
        <Typography className={classes.heading}>
          {translate("resources.waybills.optimize.noSolution")}
        </Typography>
      )}
      {!routes && error === '' && (
        <Typography className={classes.heading}>
          {translate("resources.waybills.optimize.loading")}
        </Typography>
      )}
      
      {error !== '' && (
        <Typography className={classes.heading}>
          {translate(error)}
        </Typography>
      )}
    </>
  );
}

export default OptimizedRoutesList;
