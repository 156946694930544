import React, {useState} from 'react';
import {
    Filter,
    TextInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import DependentReferenceInput from '../../components/DependentReferenceInput';
import useTranslateVehicleType from './useTranslateVehicleType';

const useStyles = makeStyles({
    filter: {
        marginTop: 0,
        marginLeft: '16px',

        '& .filter-field': {
            flexGrow: 1,
        },
        '& .MuiFormControl-root': {
            width: '100%',
        }
    }
})

function VehicleFilter(props) {
    const classes = useStyles();
    const vehiclesType = useTranslateVehicleType();
    const [fields, setFields] = useState({
        plate: '',
        type: '',
        transport_id: '',
        driver_id: ''
    });
    
    const handleChange = (event) => {
        const newFields = fields;
        newFields[event.target.name] = event.target.value;
        setFields(newFields);
        if (event.target.value === ''){
            props.setFilters(fields);
        }
    };


    return (
        <Filter 
            className={classes.filter} 
            resource={props.resource}
            variant="standard"
            {...props}
        >
            <TextInput 
                source="plate"
                label='resources.vehicles.fields.plate'
                alwaysOn
                value={fields.plate}
                onChange={handleChange}
            />
            <SelectInput
                source="type"
                label='resources.vehicles.fields.type'
                choices={vehiclesType} 
                alwaysOn
                value={fields.type}
                onChange={handleChange}
            />
            <ReferenceInput  
                source="transport_id"
                label='resources.vehicles.fields.transport_id'
                reference="transports"
                sort={{ field: 'name', order: 'ASC' }} 
                filterToQuery={searchText => ({ name: searchText })} 
                alwaysOn 
                value={fields.transport_id}
                onChange={handleChange}
            >
                <SelectInput optionText="name" optionValue="id" />
                {/* <AutocompleteInput optionText="name" optionValue="id" /> */}
            </ReferenceInput> 
            <DependentReferenceInput 
                source="driver_id"
                label='resources.vehicles.fields.driver_id'
                reference="drivers"
                filterProp="transport_id"
                dependentSource="transport_id"
                sort={{ field: 'firstName', order: 'ASC' }} 
                filterToQuery={searchText => ({ name: searchText })}
                perPage={60} 
                alwaysOn 
                value={fields.driver_id}
                onChange={handleChange}
            >
                <SelectInput optionText="name" optionValue="id" />
                {/* <AutocompleteInput optionText="name" optionValue="id" /> */}
            </DependentReferenceInput>
            
        </Filter>
    )
}

export default VehicleFilter;