import React from "react";
import { SelectInput } from "react-admin";
import { useImportContext } from "./ImportContext";

function SelectImport({
  source,
  label,
  allowEmpty = true,
  validate = [],
  classes = {},
  variant = "filled",
  ...props
}) {
  const [state, dispatch] = useImportContext();
  let { resource, fields, records, selectedFields } = state;

  return (
    <SelectInput
      {...props}
      className={classes}
      source={source}
      label={label}
      resource={resource}
      validate={validate}
      variant={variant}
      allowEmpty={allowEmpty}
      choices={fields.map((field) => ({
        id: field,
        name: field,
        disabled: Object.values(selectedFields).includes(field) ? true : false,
      }))}
    />
  );
}

export default SelectImport;
