import React from 'react';
import { ReactComponent as Icon } from './bike.svg';
import { SvgIcon } from '@material-ui/core';

const DIcon = <SvgIcon component={Icon} viewBox="0 0 480 480" />;

function BikeIcon(props) {
  let color = props.color ? props.color : 'gray';
  return <SvgIcon component={Icon} viewBox="0 0 512 512" htmlColor={color} />;
}

export default BikeIcon;
