import { default as dashboard } from '../es/dashboard';

export default {
    ...dashboard,
    regionChart: {
        ...dashboard.regionChart,
        title: "Facturado por estado",
    },
    communeChart:{
        ...dashboard.communeChart,
        title: "Top 10 Facturado por ciudad",
    },
}