import React, { useState } from 'react'
import { Datagrid, Pagination, ListContextProvider } from 'react-admin'
import { useImportContext } from './ImportContext';
import keyBy from 'lodash/keyBy';

function ImportList({ children, className, classes = {} }) {
  const [state] = useImportContext();
  const [page, setPage] = useState(1);
  const { resource, recordsToReview } = state;
  const total = recordsToReview.length;
  const perPage = 10;
  const offset = (page - 1) * perPage;
  const data = recordsToReview.slice(offset, offset + perPage);

  return (
    <ListContextProvider
      value={{
        resource,
        basePath: 'import',
        data: keyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        currentSort: { field: 'id', order: 'ASC' },
        selectedIds: [],
      }}
    >
      <Datagrid classes={classes.datagrid}>
        { children }
      </Datagrid>
      <Pagination
        page={page}
        perPage={perPage}
        setPage={setPage}
        total={total}
      />
    </ListContextProvider>
  );
}

export default ImportList;