import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { default as CountryActions } from './CountryActions';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function CountryList(props) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = <SimpleList primaryText={(record) => record.name} />;
  const datagrid = (
    <Datagrid rowClick='show'>
      <TextField source='name'  />
      <EditButton label=""  />
      <CustomButtonDelete  />
    </Datagrid>
  );
  return (
    <List  {...props} actions={<CountryActions />}>
      {isSmall ? list : datagrid}
    </List>
  );
}

export default CountryList;
