import React from 'react';
import { 
  Edit, 
  SimpleForm, 
  TextInput, 
  required, 
  maxLength,
  useTranslate,
 } from 'react-admin';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';


function CountryTitle({ record }) {
  const translate = useTranslate();
return <span>{translate('resources.countries.message.country')} {`${record.name}`}</span>;
}

const validate = {
  name: [maxLength(128), required()],
  dniLegalPersonName: [maxLength(64)],
  dniLegalPersonFormat: [maxLength(64)],
  dniNaturalPersonName: [maxLength(64)],
  dniNaturalPersonFormat: [maxLength(64)],
};

export function CountryEdit(props) {
  return (
    <Edit title={<CountryTitle />} {...props}>
      <SimpleForm variant="standard">
        <TextInput source='name'  validate={validate.name} />
        <TextInput
          source='dniLegalPersonName'
          validate={validate.dniLegalPersonName}
        />
        <TextInput
          source='dniLegalPersonFormat'
          validate={validate.dniLegalPersonFormat}
        />
        <TextInput
          source='dniNaturalPersonName'
          validate={validate.dniNaturalPersonName}
        />
        <TextInput
          source='dniNaturalPersonFormat'
          validate={validate.dniNaturalPersonFormat}
        />
      </SimpleForm>
    </Edit>
  );
}

export default CountryEdit;
