import React from "react";
import {
  SimpleForm,
  Datagrid,
  TextInput,
  TextField,
  NumberField,
  BooleanField,
  ArrayField,
  useTranslate,
} from "react-admin";
import { ImportList, useImportContext } from "../../modules/ra-csv-importer";
import { Typography } from "@material-ui/core";
import { Check, ErrorOutline } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  label: {
    color: "gray",
    flexBasis: "100%",
  },
  smallInput: {
    display: "inline-flex",
    width: "30%",
    marginRight: "2.5%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  largeInput: {
    display: "inline-flex",
    width: "65%",
    marginRight: "2.5%",
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
  datagridHead: {
    display: "none",
  },
  datagridRowCell: {
    padding: 0,
  },
});

function WaybillImportPreviewList(props) {
  const translate = useTranslate();
  const [state] = useImportContext();
  const classes = useStyles();
  const datagridClasses = {
    thead: classes.datagridHead,
    rowCell: classes.datagridRowCell,
  };
  return (
    <>
      <SimpleForm resource="waybills" toolbar={<></>}>
        <TextInput
          formClassName={classes.smallInput}
          source="code"
          initialValue={state.recordsToReview[0].code}
          variant="standard"
          disabled
        />
        <TextInput
          formClassName={classes.largeInput}
          source="from_filename"
          initialValue={state.recordsToReview[0].from_filename}
          variant="standard"
          disabled
        />
        <TextInput
          formClassName={classes.smallInput}
          source="weight"
          initialValue={state.recordsToReview[0].weight}
          variant="standard"
          disabled
        />
        <TextInput
          formClassName={classes.smallInput}
          source="volume"
          initialValue={state.recordsToReview[0].volume}
          variant="standard"
          disabled
        />
      </SimpleForm>
      <Typography variant="subtitle1" className={classes.label}>
        {translate("resources.waybills.message.orders")}
      </Typography>
      <ImportList {...props} classes={{ datagrid: datagridClasses }}>
        <ArrayField source="orders" label="">
          <Datagrid>
            <BooleanField
              source="valid"
              TrueIcon={Check}
              FalseIcon={ErrorOutline}
              valueLabelTrue={translate(
                "resources.waybills.message.noReceived"
              )}
              valueLabelFalse={translate("resources.waybills.message.received")}
            />
            <TextField source="code" />
            <TextField source="firstname" />
            <TextField source="lastname" />
            <TextField source="address" />
            <TextField source="phone" />
            <TextField source="email" />
            <TextField source="address_references" />
            <NumberField source="amount" />
            <TextField source="retail" />
            <TextField source="priority" />
            <TextField source="weight" />
            <TextField source="volume" />
            <BooleanField
              source="geocoded"
              valueLabelTrue={translate("resources.waybills.message.geocoded")}
              valueLabelFalse={translate(
                "resources.waybills.message.noGeocoded"
              )}
            />
          </Datagrid>
        </ArrayField>
      </ImportList>
    </>
  );
}

export default WaybillImportPreviewList;
