import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  useTranslate,
} from 'react-admin';

function RegionTitle({ record }) {
  const translate = useTranslate();
    return <span>{translate('resources.administrative_areas/level_1.message.region')} {`${record.name}`}</span>;
  }

const validate={
  cod: [maxLength(64), required()],
  name:  [maxLength(128), required()],
}
  
  
export function RegionEdit(props) {
  return (
    <Edit title={<RegionTitle />} {...props}>
      <SimpleForm
      variant="standard">
        <TextInput source="code"  validate={validate.cod} />
        <TextInput source="name"  validate={validate.name} />
      </SimpleForm>
    </Edit>
  );
}

export default RegionEdit;
