import React, { useState } from 'react';
import { useTranslate, useUpdate, useNotify, useRefresh } from 'react-admin';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { useGoogleMapsContext } from '../contexts/GoogleMapsContextProvider';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

function CustomMarker(props) {
  const { id, lat, lng, status, color, label, title, onClick, index } = props;
  const [isOpen, setOpen] = useState(false);
  const [position, setPosition] = useState({ lat, lng });
  const [markerColor, setMarkerColor] = useState(color);
  const { google } = useGoogleMapsContext();
  const [update, { loading }] = useUpdate('orders');
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const markerIcon = (color) => ({
    path:
      'M11,0A11.01245,11.01245,0,0,0,0,11C0,21.36133,9.95166,29.44238,10.37549,29.78125a1.00083,1.00083,0,0,0,1.249,0C12.04834,29.44238,22,21.36133,22,11A11.01245,11.01245,0,0,0,11,0Z',
    fillColor: color || '#F6CC1A',
    fillOpacity: 1,
    strokeColor: '#ffffff',
    anchor: new google.maps.Point(10, 30),
    labelOrigin: new google.maps.Point(11, 14),
  });

  const markerLabel = (label) => ({
    text: label,
    color: '#ffffff',
  });

  const handleDragEnd = (evt) => {
    setPosition({
      lat: evt.latLng.lat(),
      lng: evt.latLng.lng(),
    });
    if (!['ACCEPTED_BY_CLIENT', 'REJECTED_BY_CLIENT'].includes(status))
      setOpen(true);
  };

  const convertOrderStatusInColor = (status = '', relocated = false) => {
    switch (status) {
      case 'REJECTED_BY_CLIENT':
        return '#F50C35';
      case 'ACCEPTED_BY_CLIENT':
        return '#51B86A';
      default:
        return relocated ? '#9e34eb' : '#F6CC1A';
    }
  };

  const handleSubmit = (values) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: position }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          const data = {
            latitude: position.lat,
            longitude: position.lng,
            address: results[0].formatted_address,
          };
          update(
            {
              payload: {
                data: data,
                id: id,
              },
            },
            {
              onSuccess: ({ data }) => {
                setOpen(false);
                setMarkerColor(
                  convertOrderStatusInColor(data.status, data.relocated)
                );
                //refresh();
              },
              onFailure: ({ error }) => {
                notify(error.message, 'error');
              },
            }
          );
        } else {
          notify(`No results found`, 'error');
        }
      } else {
        notify(`Geocoder failed due to: ${status}`, 'error');
      }
    });
  };

  return (
    <Marker
      key={`${lat}${lng}${title}`}
      position={position}
      icon={markerIcon(markerColor)}
      label={markerLabel(`${label || index}`)}
      title={title}
      onClick={onClick}
      draggable={true}
      onDragEnd={handleDragEnd}
    >
      {isOpen && (
        <InfoWindow onCloseClick={(evt) => setOpen(false)}>
          <Card>
            <CardActionArea>
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {translate('resources.orders.message.relocate')}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button
                size="small"
                color="primary"
                onClick={(evt) => setOpen(false)}
              >
                {translate('resources.orders.message.no')}
              </Button>
              <Button
                size="small"
                color="secondary"
                onClick={handleSubmit}
                disabled={loading}
              >
                {translate('resources.orders.message.yes')}
              </Button>
            </CardActions>
          </Card>
        </InfoWindow>
      )}
    </Marker>
  );
}

export default CustomMarker;
