export default {
  name: "Bill of landing |||| Bills of landing",
  label: "Bills of landing",
  fields: {
    code: "ID",
    firstname: "Firstname",
    lastname: "Lastname",
    address: "Address",
    address_references: "Address references",
    phone: "Phone",
    email: "Email",
    amount: "Amount",
    client_retail_id: "Client retail",
    retail: "Client retail",
    priority: "Priority",
    createdAt: "Date",
    from_filename: "Import file",
    geocoded: "Geocoded",
    isImportable: "Received",
    waybill_code: "ID",
    street: "Address line 1",
    streetNumber: "Address line 2",
    commune: "Commune",
    region: "Region",
    addressHasAreas: "Address has commune and region",
    administrative_area_level_1_id: "Region",
    administrative_area_level_2_id: "Commune",
    observations: "Observations",
    createdAt_gte: "Init date",
    createdAt_lte: "End date",
    weight: "Weight",
    volume: "Volume",
    status: "Status",
    distance: "Total distance",
    duration: "Total duration",
    description: "Description",
    vehicleID: "Vehicle",
    warehouse: "Warehouse",
    warehouse_id: "Warehouse",
    orders: "Orders",
    valid: "Valid",
  },
  status: {
    initial: "Initial",
    received: "Received",
    invalid: "Invalid",
    in_process: "In process",
    finished: "Finished",
    error: "Error",
    pending: "Pending",
  },
  message: {
    importTemplate: "Import template",
    or: "or",
    selectTemplate: "Select a saved template",
    inputTemplateName: "Type a new template name",
    updateTemplateName: "Update the template name",
    createdImportTemplate: "Import template created successfully",
    updatedImportTemplate: "Import template updated successfully",
    number: "Bill of landing:",
    landing: "Bill of landing",
    order: "Unassigned orders",
    orders: "Orders",
    routes: "Routes",
    noReceived: "order not received",
    received: "order received",
    geocoded: "Geocoded address",
    noGeocoded: "address not Geocoded",
    shortWait: "Wait...",
    longWait: "go for a cup of coffee ☕",
    gecodedAddress: "Geocoding addresses, please",
    gecodedAll: "All orders have already been received",
    gecodedNothing: "Nothing to geocode",
    importLoadGuide: "Import load guide",
    weightAndVolume: "Total weight and volume",
    mts: "(mts³)",
    kg: "(kg)",
    distanceUnit: "(km)",
    durationUnit: "(min)",
    duplicateOrders: "Invalid loading guide due to duplicate orders:",
    fleetAvailable: "Fleet available",
    status: "Status",
    selected: "Selected",
    createRoute: "Create route",
    new: "New",
    route: "Routes",
    errorFinalizedRoute: "Route has finalized",
    errorExceededCapacity: "Capacity is exceeded",
    back: "Back",
  },
  import_step: {
    one: "File upload",
    two: "Geocoding",
    three: "Preview data",
  },
  optimize: {
    button: "Optimize",
    buttonDescription: "Distribute orders automatically",
    modalTitle: "Optimize routes",
    modalSubtitle: "Suggested optimized routes",
    loading: "Optimizing...",
    noSolution: "There isn't fleet available to cover the selected cargo",
    noWarehouse: "There are not warehouses registered",
    errorAddress: "Some address has not a possible path. Please, check addresses",
    wizard: {
      buttons: {
        back: "Back",
        next: "Next",
        optimize: "Optimize",
      },
      steps: {
        1: "Warehouse selection",
        2: "Fleet selection",
        3: "Confirm",
      },
    },
  },
  assign: {
    button: "Assign",
    buttonDescription: "Assign orders to route manually",
    modalTitle: "Assign route",
  },
  button: {
    assign: "Assign",
  },
};
