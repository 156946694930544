export default {
    name: 'Warehouse |||| Warehouses',
    label: 'Warehouses',
    fields: {
        name: 'Name',
        address: 'Address',
        default: 'Default',
    },
    message: {
        geocode: 'You must Geocode the address',
    }
}
  