export default {
    name: 'Entrega |||| Entregas ',
    label:'Entregas', 
    fields: {
        photo: 'Foto',
        observations: 'Observaciones',
        order_id: 'ID de la orden',
        id: "ID",
        status: 'Estatus',
        createdAt: 'Fecha',
    },
    status: {
        accepted: 'Aceptada',
        rejected: 'Rechazada',
    },
    message: {
        delivery: 'Entrega',
    }
}