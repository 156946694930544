import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  SelectField,
  NumberField,
  DateField,
  Pagination,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import RouteReportFilter from './RouteReportFilter';
import RouteReportActions from './RouteReportActions'

function RoutePagination(props) {
  return <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;
}

function RouteReport(props) {

  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => record.code}
      secondaryText={(record) => record.createdAt}
    />
  );
  const datagrid = (
    <Datagrid rowClick="show">
      <TextField source="code" label="resources.routes.fields.code" />
      <DateField
        source="events.CREATE"
        label="resources.routes.status.created"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.TRANSPORT_ASSIGNMENT"
        label="resources.routes.status.assigned"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.DRIVER_CONFIRMATION"
        label="resources.routes.status.accepted"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.LOAD_TRANSPORT"
        label="resources.routes.status.inTransit"
        options={dateOptions}
        locales="es-VE"
      />
      <DateField
        source="events.DELIVER_LAST_ORDER"
        label="resources.routes.status.finished"
        options={dateOptions}
        locales="es-VE"
      />
      <TextField 
        source="vehicle.plate"
        label="resources.routes.fields.vehicle_id"
      />
    </Datagrid>
  );
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<RouteReportFilter />}
      pagination={<RoutePagination />}
      perPage={25}
      sort={{ field: 'code', order: 'ASC' }}
      actions={<RouteReportActions/>}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default RouteReport;
