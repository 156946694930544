export default {
    name: 'Commune |||| Communes',
    label: 'Communes',
    fields: {
        code: 'Code',
        name: 'Name',
        region: 'Region',
        parent_area_id: 'Regions',
    },
    message:{
        import: "Import communes",
        commune: 'Commune:',
    },
}