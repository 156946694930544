import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EmailField,
  ReferenceField,
  EditButton,
  ShowButton,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function UserList(props) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const list = (
      <SimpleList
        primaryText={(record) => record.firstName + ' ' + record.lastName}
        secondaryText={(record) => record.email}
        tertiaryText={(record) => record.phone}
      />
    );
    const datagrid = (
      <Datagrid rowClick='show'>
        <TextField source='firstName'  />
        <TextField source='lastName'  />
        <EmailField source='email'  />
        <TextField source='phone'  />
        <TextField source='dni' />
        <ReferenceField
          source="role_id"
          link="show"
          reference="roles"
        >
          <TextField source="label" />
        </ReferenceField>
        <ShowButton label="" />
        <EditButton label="" />
        <CustomButtonDelete />
      </Datagrid>
    );
    return (
      <List {...props}>
        {isSmall ? list : datagrid}
      </List>
    );
  }

  export default UserList;