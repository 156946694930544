import React from 'react';
import {required} from 'react-admin';
import { ImportForm, SelectImport, ReferenceSelectImport } from '../../modules/ra-csv-importer';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    selectImport: {
      flexBasis: '30%'
    },
    selectImportQuarter: {
      flexBasis: '22.5%'
    },
  })
function CommuneUploadComponent(props) {
    const classes = useStyles();
    return (
        <ImportForm>
            <SelectImport 
                source="code" 
                variant="standard" 
                classes={classes.selectImport}
                validate={[required()]}  
            />
            <SelectImport 
                source="name" 
                variant="standard" 
                classes={classes.selectImport}
                validate={[required()]}  
            />
             <ReferenceSelectImport 
                source="region" 
                reference="administrative_areas/level_1" 
                referenceSource="parent_area_id" 
                referenceTarget="name" 
                referenceValue="id"  
                variant="standard" 
                classes={classes.selectImportQuarter}
                validate={[required()]} 
        />
        </ImportForm>
    )
  }

  export default CommuneUploadComponent;