export default {
    name: 'Centro de distribución |||| Centros de distribución',
    label: 'Centros de distribución',
    fields: {
        name: 'Nombre',
        address: 'Dirección',
        default: 'Por defecto',
    },
    message: {
        geocode: 'Debe Geocodificar la dirección',
    }
}
  