import React from 'react';
import {
  BarChart,
  Bar,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const styles = {
  backgroundColor: { backgroundColor: 'rgba(247, 252, 255, 0.8)' },
  chartSize: { width: '99%', height: 300 },
};

const useStyles = makeStyles({
  title: { fontSize: '10' },
  titleNew: {
    fontSize: '1rem',
  },
  cardHeader: {
    root: { fontSize: '10' },
  },
  legend: { fontFamily: 'Roboto, Helvetica' },
});

function RetailsChart({ data }) {
  const translate = useTranslate();
  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <Card>
          <CardHeader 
          
            title={payload[0].payload.name} />
          <CardContent>
            <p>{`${translate('dashboard.retailsChart.amount')} : $${
              payload[0].payload.amount
            }`}</p>
            <p>{`${translate('dashboard.retailsChart.orders')} : ${
              payload[0].payload.orders
            }`}</p>
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.titleNew
       }}
        title={translate('dashboard.retailsChart.title')}
      />
      <CardContent>
        <div style={styles.chartSize}>
          {data && data.length>0 ?(
            <ResponsiveContainer>
              <BarChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                {/* <XAxis dataKey="name" /> */}
                <YAxis yAxisId="left" orientation="left" stroke="#2196f3" />
                <YAxis yAxisId="right" orientation="right" stroke="#cf3b30" />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  name={translate('dashboard.retailsChart.amount')}
                  yAxisId="left"
                  dataKey="amount"
                  fill="#2196f3"
                />
                <Bar
                  name={translate('dashboard.retailsChart.orders')}
                  yAxisId="right"
                  dataKey="orders"
                  fill="#cf3b30"
                />
                <Legend wrapperStyle={styles.legend} />
              </BarChart>
            </ResponsiveContainer>
          ): <Typography>{translate('dashboard.withoutData')} </Typography>}
        </div>
      </CardContent>
    </Card>
  );
}

export default RetailsChart;
