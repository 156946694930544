import React from 'react';

import {
    Filter,
    TextInput,
    }from 'react-admin';


function RetailFilter(props){
   return(
    <Filter {...props} variant="standard">
        <TextInput 
            source="name" 
            alwaysOn 
        />
    </Filter>
    
    )
}

export default RetailFilter;