export default { 
    name: 'Comuna |||| Comunas',
    label: 'Comunas',
    fields: {
        code: 'Código',
        name: 'Nombre',
        region: 'Región',
        parent_area_id: 'Regiones',
    },
    message:{
        import: "Importar comunas",
        commune: 'Comuna:',
    },
}