import { useTranslate } from 'react-admin';

function useTranslateVehicleStatus() {
  const translate = useTranslate();
  return [
    { 
        id: 'ASSIGNED', 
        name: translate('resources.vehicles.status.assigned') 
    },
    { 
        id: 'AVAILABLE', 
        name: translate('resources.vehicles.status.available') 
    },
  ]
}

export default useTranslateVehicleStatus;