import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useTranslate } from 'react-admin';
import { default as VehicleTypeField } from '../../components/VehicleTypeField';

const useStyles = makeStyles((theme) => ({
  rightAlignedCell: { textAlign: 'right' },
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

const Vehicles = ({ record }) => {
  const classes = useStyles();
  const translate =useTranslate();
  if (!record) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>{translate('resources.transports.message.plate')}</TableCell>
          <TableCell>{translate('resources.transports.message.brand')}</TableCell>
          <TableCell>{translate('resources.transports.message.model')}</TableCell>
          <TableCell className={classes.rightAlignedCell}>
          {translate('resources.transports.message.maxMt')}
          </TableCell>
          <TableCell className={classes.rightAlignedCell}>{translate('resources.transports.message.maxKg')}</TableCell>
          <TableCell>{translate('resources.transports.message.driver')} </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.vehicles.map(
          (item) =>
            item && (
              <TableRow key={item.id}>
                <TableCell>
                  <VehicleTypeField record={item} source="type" />
                </TableCell>
                <TableCell>
                  <Link to={`/vehicles/${item.id}`}>{item.plate}</Link>
                </TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.model}</TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {item.volumeCapacity}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {item.weightCapacity}
                </TableCell>
                <TableCell className={classes.rightAlignedCell}>
                  {item.driver && (
                    <div className={classes.root}>
                      <Avatar
                        src={`${item.driver.photo}?size=25x25`}
                        className={classes.avatar}
                      />
                      <Link to={`/drivers/${item.driver.id}`}>
                        {item.driver.name}
                      </Link>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  );
};

export default Vehicles;
