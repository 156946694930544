export default {
    name: 'Estado |||| Estados',
    label: 'Estados',
    fields: {
        code: 'Código',
        name: 'Nombre',
        sub_areas: 'Ciudades',
    },
    message: {
        import: 'Importar estados',
        region: 'Estado:'
    },
}