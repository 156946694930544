import React from 'react';
import { Route } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
import LoginPage from './resources/auth/LoginPage';
import { GoogleMapsContextProvider } from './contexts/GoogleMapsContextProvider';

// Resources
import {
  UserList,
  UserIcon,
  UserCreate,
  UserEdit,
  UserShow,
} from './resources/users';

import { OrderList, OrderShow, OrderEdit, OrderIcon } from './resources/orders';
import { WaybillList, WaybillShow, WaybillIcon } from './resources/waybills';
import {
  RegionList,
  RegionCreate,
  RegionEdit,
  RegionIcon,
} from './resources/region';
import {
  RetailList,
  RetailCreate,
  RetailEdit,
  RetailIcon,
} from './resources/retail-client';
import {
  CommuneList,
  CommuneCreate,
  CommuneEdit,
  CommuneIcon,
} from './resources/commune';

import {
  CountryList,
  CountryCreate,
  CountryEdit,
  CountryIcon,
} from './resources/country';

import {
  PlannerList,
  PlannerCreate,
  PlannerEdit,
  PlannerIcon,
} from './resources/planner';

import {
  TransportList,
  TransportCreate,
  TransportEdit,
  TransportShow,
  TransportIcon,
} from './resources/transport';

import {
  VehicleList,
  VehicleCreate,
  VehicleEdit,
  VehicleIcon,
} from './resources/vehicle';

import {
  DriverList,
  DriverCreate,
  DriverEdit,
  DriverIcon,
} from './resources/driver';
import { default as Logo } from './resources/driver/DriverIcon';

import { 
  default as RoleResource,
  RoleList,
  RoleEdit,
  RoleCreate,
  RoleShow,
  RoleIcon,
} from './resources/role';

import {
  RouteList,
  RouteCreate,
  RouteEdit,
  RouteIcon,
  RouteShow,
} from './resources/route';

import { DeliveryList, DeliveryShow } from './resources/deliveries';

import {
  WarehouseList,
  WarehouseIcon,
  WarehouseCreate,
  WarehouseEdit,
  WarehouseShow,
} from './resources/warehouses';
import settingsIcon from '@material-ui/icons/Settings';

import { 
  WaybillReportIcon,
  WaybillReport,
  RouteReportIcon,
  RouteReport,
  OrderReport,
  OrderReportIcon,
} from './resources/reports';
import profile from './resources/profile';

// Components
import Dashboard from './components/dashboard/Dashboard';
import SubscriptionPlanFinished from './components/subscription-plan/SubscriptionPlanFinished';
import { default as CustomLayout } from './components/CustomLayout';



// Provider
import authProvider from './providers/authProvider';
import dataProvider from './providers/dataProvider';
// Translations Provider
import polyglotI18nProvider from 'ra-i18n-polyglot';
import {default as translation} from './translation';
import {default as translation_import} from './modules/ra-csv-importer/translation' ;
import { default as Theme} from './Theme';

const lang = process.env.REACT_APP_DEFAULT_LANGUAGE || 'es';
const messages = {
  es: { ...translation.es, ...translation_import.es },
  'es-ve': { ...translation.esVE, ...translation_import.es },
  en: { ...translation.en, ...translation_import.en },
};

const i18nProvider = polyglotI18nProvider((locale) => messages[locale], lang);

const customRoutes = [
  <Route
    key="subscription-finished"
    path="/subscription-finished"
    component={SubscriptionPlanFinished}
  />,
  <Route
    key="my-profile"
    path="/my-profile"
    component={profile.edit}
  />
]

function App() {
  return (
    <GoogleMapsContextProvider>
      <Admin
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        customRoutes={customRoutes}
        i18nProvider={i18nProvider}
        layout={CustomLayout }
        theme={Theme}
      >
        {(permissions) => {
          return [
          <Resource
            name="waybills"
            list={permissions.includes('orders__view') ? WaybillList : null}
            show={permissions.includes('orders__view') ? WaybillShow : null}
            icon={WaybillIcon}
            options={{ label: 'resources.waybills.label' }}
          />,
          <Resource
            name="orders"
            show={OrderShow}
            edit={OrderEdit}
            icon={OrderIcon}
            option={{ label: 'resources.orders.label'}}
          />,
          <Resource
            name="routes"
            list={permissions.includes('orders__view') ? RouteList : null}
            create={permissions.includes('orders__view') ? RouteCreate : null}
            edit={permissions.includes('orders__view') ? RouteEdit : null}
            show={permissions.includes('orders__view') ? RouteShow : null}
            icon={RouteIcon}
            options={{ label: 'resources.routes.label' }}
          />,
          <Resource
            name="users"
            create={permissions.includes('users__create') ? UserCreate : null}
            edit={permissions.includes('users__edit') ? UserEdit : null}
            show={permissions.includes('users__view') ? UserShow : null}
            icon={UserIcon}
            options={{ label: 'resources.users.label'}}
          />,
          <Resource
            name="planners"
            list={permissions.includes('planners__view') ? PlannerList : null}
            create={
              permissions.includes('planners__create') ? PlannerCreate : null
            }
            edit={permissions.includes('planners__edit') ? PlannerEdit : null}
            icon={PlannerIcon}
            options={{ label: 'resources.planners.label' }}
          />,
          <Resource
            name="transports"
            list={
              permissions.includes('transports__view') ? TransportList : null
            }
            create={
              permissions.includes('transports__create')
                ? TransportCreate
                : null
            }
            edit={
              permissions.includes('transports__edit') ? TransportEdit : null
            }
            show={
              permissions.includes('transports__view') ? TransportShow : null
            }
            icon={TransportIcon}
            options={{ label: 'resources.transports.label'}}
          />,
          <Resource
            name="vehicles"
            list={permissions.includes('vehicles__view') ? VehicleList : null}
            create={
              permissions.includes('vehicles__create') ? VehicleCreate : null
            }
            edit={permissions.includes('vehicles__edit') ? VehicleEdit : null}
            icon={VehicleIcon}
            options={{ label: 'resources.vehicles.label' }}
          />,
          <Resource
            name="countries"
            list={permissions.includes('countries__view') ? CountryList : null}
            create={
              permissions.includes('countries__create') ? CountryCreate : null
            }
            edit={permissions.includes('countries__edit') ? CountryEdit : null}
            icon={CountryIcon}
            options={{ label: 'resources.countries.label' }}
          />,
          <Resource
            name="administrative_areas/level_1"
            list={
              permissions.includes('administrative_areas/level_1__view')
                ? RegionList
                : null
            }
            create={
              permissions.includes('administrative_areas/level_1__create')
                ? RegionCreate
                : null
            }
            edit={
              permissions.includes('administrative_areas/level_1__edit')
                ? RegionEdit
                : null
            }
            icon={RegionIcon}
            options={{ label: 'resources.administrative_areas/level_1.label' }}
          />,
          <Resource
            name="administrative_areas/level_2"
            list={
              permissions.includes('administrative_areas/level_2__view')
                ? CommuneList
                : null
            }
            create={
              permissions.includes('administrative_areas/level_2__create')
                ? CommuneCreate
                : null
            }
            edit={
              permissions.includes('administrative_areas/level_2__edit')
                ? CommuneEdit
                : null
            }
            icon={CommuneIcon}
            options={{ label: 'resources.administrative_areas/level_2.label' }}
          />,
          <Resource name="addresses" />,
          <Resource
            name="clients/retail"
            list={
              permissions.includes('clients/retail__view') ? RetailList : null
            }
            create={
              permissions.includes('clients/retail__create')
                ? RetailCreate
                : null
            }
            edit={
              permissions.includes('clients/retail__edit') ? RetailEdit : null
            }
            icon={RetailIcon}
            options={{ label: 'resources.clients/retail.label' }}
          />,
          <Resource
            name="drivers"
            list={permissions.includes('drivers__view') ? DriverList : null}
            create={
              permissions.includes('drivers__create') ? DriverCreate : null
            }
            edit={permissions.includes('drivers__edit') ? DriverEdit : null}
            icon={Logo}
            options={{ label: 'resources.drivers.label' }}
          />,
          <Resource
            name="roles"
            option={{label: 'Role'}}
            list={permissions.includes('roles__view') ? RoleList : null}
            edit={permissions.includes('roles__edit') ? RoleEdit : null}
            show={permissions.includes('roles__view') ? RoleShow : null}
            icon={RoleIcon}
            options={{ label: 'resources.roles.label' }}
          />,
          <Resource name="dashboard" />,
          <Resource
            name="deliveries"
            show={DeliveryShow}
            edit={DeliveryShow}
          />,
          <Resource 
            name="settings" 
            icon={settingsIcon}
            options={{ "label": 'resources.settings.label',  "isMenuParent": permissions.length > 0 ? true : false }}
         />,
          <Resource 
            name="warehouses" 
            icon={WarehouseIcon}
            options={{ "label": 'resources.warehouses.label', "menuParent": "settings" }} 
            list={permissions.length > 0 ? WarehouseList : null}
            edit={permissions.length > 0 ? WarehouseEdit : null}
            show={permissions.length > 0 ? WarehouseShow : null}
            create={permissions.length > 0 ? WarehouseCreate : null}
          />,
          <Resource name="optimize" />,
          <Resource name="optimized" />,
          <Resource name="settings/import_templates" />,
          <Resource 
            name="report" 
            icon={WaybillReportIcon}
            options={{ "label": 'resources.reports.label',  "isMenuParent": true }}
          />,
          <Resource 
            name="reports/waybill" 
            icon={WaybillReportIcon}
            options={{ "label": 'resources.reports.waybill.label', "menuParent": "report" }} 
            list={WaybillReport}
          />,
          <Resource 
            name="reports/route" 
            icon={RouteReportIcon}
            options={{ "label": 'resources.reports.route.label', "menuParent": "report" }} 
            list={RouteReport}
          />,
          <Resource 
            name="reports/order" 
            icon={OrderReportIcon}
            options={{ "label": 'resources.reports.order.label', "menuParent": "report" }} 
            list={OrderReport}
          />,
          <Resource name="subscription_plans" />,
          <Resource name="settings/import_templates" />,
          <Resource name="profile"/>
        ]
      }}
      </Admin>
    </GoogleMapsContextProvider>
  );
}

export default App;
