export default {
    name: 'Role |||| Roles',
    label: 'Roles',
    fields: {
        label: 'Name',
        permissions: 'Permissions', 
        name: 'Entity',
        actions: 'Actions',
    },
    message: {
        created: 'Created',
        update: 'Last Update',
        details: 'Details',
        role: 'Role',
    }
}