import React from 'react';
import {
    SimpleForm,
    Show,
    ReferenceField,
    TextField,
    DateField,
    NumberField,
    EmailField,
    SelectField,
    SingleFieldList,
    ReferenceManyField,
    useTranslate,
    BooleanInput, 
} from 'react-admin';
import Map from '../../components/Map';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import GeocodeButton from '../../components/GeocodeButton';


function WarehouseTitle({ record }) {
    const translate = useTranslate();
    return <span> {`${record.name}`}</span>;
}

const useButtonStyles = makeStyles(theme => ({
    button: {
      marginBottom: theme.spacing(3)
    },
    spinner: {
      marginLeft: theme.spacing(1),
      color: '#ffffff'
    }
}));

const useStyles = makeStyles({
    form: { 
        '& .MuiCardContent-root': {
            display: 'flex', 
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
        }
    },
    shortField: { 
        flexBasis: '25%',
        '& .MuiFormControl-root': { width: '100%' }
    },
    largeField: { 
        flexBasis: '50%',
        '& .MuiFormControl-root': { width: '100%' }
    },
    fullWidth: { 
        flexBasis: '100%',
        textAlign: 'left',
        '& .MuiFormControl-root': { width: '100%' }
    },
});

function WarehouseShow(props){
    const classes = useStyles();
    const translate = useTranslate();
    return(
        <Show 
            {...props} 
            title={<WarehouseTitle />}
        >
            <SimpleForm 
                toolbar={<> </>}
                className={classes.form} 
                variant="standard"
            >
                <TextField 
                    source='name' 
                    formClassName={classes.shortField}
                />
                <BooleanInput 
                    source="default" 
                    formClassName={classes.shortField}
                    disabled 
                />
                <TextField 
                    source='address'
                    formClassName={classes.largeField}
                />
                <GeocodeButton
                    latSource="latitude"
                    lngSource="longitude"
                    addressSource="address"
                    formClassName={classes.fullWidth}
                />
            </SimpleForm>
        </Show>
    );
}

export default WarehouseShow;
