import React, { useState } from 'react';
import {
  Button,
  useTranslate,
} from 'react-admin';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import OptimizeWizard from './OptimizeWizard';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subtitle: {
    margin: '1rem 0'
  }

}));

const OptimizeDialog = (props) => {
  const [showDialog, setShowDialog] = useState(false);
  const translate = useTranslate();
  const { orders } = props;
  const classes = useStyles();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Button
        onClick={handleClick}
        label={translate('resources.waybills.optimize.button')}
      >
        <FlashOnIcon />
      </Button>
      <Dialog
        fullWidth
        maxWidth="lg"
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={translate('resources.waybills.optimize.modalTitle')}
      >
        <DialogTitle>
          {translate('resources.waybills.optimize.modalTitle')}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseClick}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box >
            <OptimizeWizard orders={orders} closeDialog={handleCloseClick}/>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OptimizeDialog;
