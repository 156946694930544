import { useTranslate } from 'react-admin';

function useTranslateRouteStatus() {
  const translate = useTranslate();
  return [
    { 
        id: 'INITIAL', 
        name: translate('resources.routes.status.initial') 
    },
    { 
        id: 'CREATED', 
        name: translate('resources.routes.status.created') 
    },
    { 
        id: 'ASSIGNED_TRANSPORT', 
        name: translate('resources.routes.status.assigned') 
    },
    { 
        id: 'ACCEPTED_BY_DRIVER', 
        name: translate('resources.routes.status.accepted') 
    },
    { 
        id: 'IN_TRANSIT', 
        name: translate('resources.routes.status.inTransit') 
    },
    { 
        id: 'FINISHED', 
        name: translate('resources.routes.status.finished') 
    },
  ]
}

export default useTranslateRouteStatus;