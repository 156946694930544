import React from 'react';
import { ReactComponent as Icon } from './car.svg';
import { SvgIcon } from '@material-ui/core';

function CarIcon(props) {
  let color = props.color ? props.color : 'gray';
  return <SvgIcon component={Icon} viewBox="0 0 512 512" htmlColor={color} />;
}

export default CarIcon;
