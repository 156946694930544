import React, { useState, useEffect } from "react";
import { useDataProvider, useTranslate } from "react-admin";
import { useImportContext } from "../../modules/ra-csv-importer";
import Map from "../../components/Map";
import Box from "@material-ui/core/Box";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";
import useGeocoding from "../../hooks/useGoogleGeocoding";
import compact from "lodash/compact";
import keyBy from "lodash/keyBy";

function WaybillImportGeocoding() {
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [state, dispatch] = useImportContext();
  const [ordersInvalid, setOrdersInvalid] = useState(0);
  const [ordersGeocodeCached, setOrdersGeocodeCached] = useState(0);
  const hasBeenProcessed = state.customData.hasProcessedWaybill || false;
  const [hasValidatedOrders, setHasValidatedOrders] = useState(
    hasBeenProcessed
  );
  const [hasFetchedGeocodesInCache, setHasFetchedGeocodesInCache] = useState(
    hasBeenProcessed
  );
  const [hasProcessedGeocoding, setHasProcessedGeocoding] = useState(
    hasBeenProcessed
  );
  const [hasProcessedWaybill, setHasProcessedWaybill] = useState(
    hasBeenProcessed
  );
  const {
    isGeocoding,
    geocodingData,
    geocodingTotal,
    setAddressesToGeocode,
    geocode,
  } = useGeocoding();

  useEffect(() => {
    if (!hasValidatedOrders) {
      const shouldAddArea = !(state.formValues?.addressHasAreas || false);
      const ordersWithAddress = state.recordsToImport.map((order) => {
        const { code, street, streetNumber, commune, region } = order;
        const validStreet = street
          ? `${street} ${streetNumber ? streetNumber : ""}`
          : "";
        const validCommune = commune && shouldAddArea ? `, ${commune}` : "";
        const validRegion = region && shouldAddArea ? `, ${region}` : "";
        const address = `${validStreet}${validCommune}${validRegion}`;
        return {
          ...order,
          code: code.replaceAll(/[\s\(\)\|\&\:\*\!]/g, ""),
          address,
        };
      });
      const ordersCode = ordersWithAddress.map(({ code }) => code);
      dataProvider
        .getManyByQueryParams("orders", {
          filter: {
            code_match: `(${ordersCode.join("|")})`,
            status_not: "INVALID",
          },
          paginate: "all",
        })
        .then(({ data }) => {
          // const existingOrderCodes = data.map(({ code }) => code);
          const validOrders = ordersWithAddress.map((order) => {
            const existingOrders = data.filter(({ code }) =>
              code.includes(order.code)
            );
            const existingAttempts = existingOrders.map(
              ({ delivery_attempt }) => delivery_attempt || 1
            );
            const maxOrderAttempt = Math.max(0, ...existingAttempts);
            return {
              ...order,
              code:
                maxOrderAttempt > 0
                  ? `${order.code}__${maxOrderAttempt + 1}`
                  : order.code,
              delivery_attempt: maxOrderAttempt + 1,
              valid: true,
              // Descomentar para habilitar la invalidación de órdenes
              // valid: !existingOrderCodes.includes(order.code),
            };
          });
          const addressesToGeocode = validOrders
            .filter(({ valid }) => valid)
            .map(({ address }) => address);
          setOrdersInvalid(
            ordersWithAddress.length - addressesToGeocode.length
          );
          dispatch({ type: "set_records_to_import", records: validOrders });
          setAddressesToGeocode(addressesToGeocode);
          setHasValidatedOrders(true);
          return validOrders;
        })
        .then((validOrders) => {
          const ordersAddress = validOrders.map(({ address }) => address);
          dataProvider
            .getManyByQueryParams("addresses", {
              filter: { name_in: ordersAddress },
              paginate: { page: 1, perPage: ordersAddress.length },
            })
            .then(({ data }) => {
              const geoAddressesByName = data.reduce(
                (geoAddressesByName, geoAddress) => ({
                  ...geoAddressesByName,
                  [geoAddress.name]: {
                    lat: geoAddress.latitude,
                    lng: geoAddress.longitude,
                  },
                }),
                {}
              );
              const geocodedOrders = validOrders.map((order) =>
                addGeocodeToOrder(order, geoAddressesByName)
              );
              const addressesToGeocode = geocodedOrders
                .filter(({ valid, geocoded }) => valid && !geocoded)
                .map(({ address }) => address);
              dispatch({
                type: "set_records_to_import",
                records: geocodedOrders,
              });
              setOrdersGeocodeCached(
                geocodedOrders.length - addressesToGeocode.length
              );
              setAddressesToGeocode(addressesToGeocode);
              setHasFetchedGeocodesInCache(true);
            })
            .catch((error) => console.error(error));
        })
        .catch((error) => console.error(error));
    }
  }, [hasValidatedOrders, hasFetchedGeocodesInCache]);

  useEffect(() => {
    if (
      !hasProcessedGeocoding &&
      hasValidatedOrders &&
      hasFetchedGeocodesInCache
    ) {
      geocode();
      if (geocodingData.length === geocodingTotal) {
        const geoByAddress = keyBy(geocodingData, "address");
        const geoAddresses = geocodingData.map(({ address, lat, lng }) => ({
          name: address,
          latitude: lat,
          longitude: lng,
        }));
        createAddresses(geoAddresses);
        const geoOrders = state.recordsToImport.map((order) =>
          addGeocodeToOrder(order, geoByAddress)
        );
        dispatch({ type: "set_records_to_import", records: geoOrders });
        setHasProcessedGeocoding(true);
      }
    }
  }, [geocodingData.length, hasValidatedOrders, hasFetchedGeocodesInCache]);

  useEffect(() => {
    if (!hasProcessedWaybill && hasProcessedGeocoding) {
      const { waybill_code, from_filename } = state.formValues;
      let totalWeightOrders = 0;
      let totalVolumeOrders = 0;
      const orders = state.recordsToImport.map((order) => {
        let priority = order.priority
          ? order.priority.replaceAll(/[\,\.]/g, "")
          : 0;
        let amount = order.amount ? order.amount.replaceAll(",", ".") : 0;
        let weight = order.weight ? order.weight.replaceAll(",", ".") : 0;
        let volume = order.volume ? order.volume.replaceAll(",", ".") : 0;
        priority = isNaN(parseInt(priority)) ? 0 : parseInt(priority);
        amount = isNaN(parseFloat(amount)) ? 0 : parseFloat(amount);
        weight = parseFloat(weight);
        volume = parseFloat(volume);
        totalWeightOrders += isNaN(weight) ? 0 : weight;
        totalVolumeOrders += isNaN(volume) ? 0 : volume;
        const retail = order.client_retail_id
          ? {}
          : { client_retail: order.retail };
        return {
          ...order,
          ...retail,
          amount,
          priority,
          volume: isNaN(volume) ? 0 : volume,
          weight: isNaN(weight) ? 0 : weight,
        };
      });
      const waybills = [
        {
          code: waybill_code,
          from_filename,
          orders: orders,
          weight: totalWeightOrders,
          volume: totalVolumeOrders,
        },
      ];
      dispatch({ type: "set_records_to_review", records: waybills });
      dispatch({ type: "set_records_to_import", records: waybills });
      dispatch({
        type: "set_custom_data",
        customData: { hasProcessedWaybill: true },
      });
      setHasProcessedWaybill(true);
    }
  }, [hasProcessedWaybill, hasProcessedGeocoding]);

  const addGeocodeToOrder = (order, geoAddressesByName = {}) => {
    if (order.geocoded) {
      return order;
    }
    const { lat, lng } = geoAddressesByName[order.address]
      ? geoAddressesByName[order.address]
      : { lat: null, lng: null };
    return {
      ...order,
      latitude: lat ? lat : null,
      longitude: lng ? lng : null,
      geocoded:
        typeof lat === "number" && typeof lng === "number" ? true : false,
    };
  };

  const createAddresses = (addresses) =>
    Promise.all(
      addresses.map((address) =>
        dataProvider.create("addresses", {
          data: address,
        })
      )
    );
  const progress = hasFetchedGeocodesInCache
    ? (geocodingData.length / geocodingTotal) * 100
    : 0;
  const waitMessage =
    geocodingTotal < 600
      ? "resources.waybills.message.shortWait"
      : "resources.waybills.message.longWait";
  const totalGeocodedOrders =
    ordersInvalid + ordersGeocodeCached + geocodingData.length;
  const totalOrders = ordersInvalid + ordersGeocodeCached + geocodingTotal;
  const LoadingComponent = (
    <>
      <Typography variant="body2" color="textPrimary">
        {translate("resources.waybills.message.gecodedAddress")}{" "}
        {translate(waitMessage)}
      </Typography>
      <Box width="50%" mr={2}>
        <LinearProgress variant="determinate" value={progress} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {hasFetchedGeocodesInCache
            ? `${totalGeocodedOrders} / ${totalOrders}`
            : ""}
        </Typography>
      </Box>
    </>
  );

  const geoData = hasProcessedWaybill
    ? state.recordsToImport[0].orders
        .filter(({ valid }) => valid)
        .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }))
    : geocodingData.map(({ lat, lng }) => ({ lat, lng }));
  const markers = compact(geoData);

  const MapComponent =
    markers instanceof Array && markers.length > 0 ? (
      <Map data={markers} />
    ) : (
      <Typography variant="body2" color="textPrimary">
        {translate("resources.waybills.message.gecodedAll")}
        {translate("resources.waybills.message.gecodedNothing")}
      </Typography>
    );

  return (
    <Box
      component="div"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="20vw"
      width="100%"
    >
      {isGeocoding || !hasProcessedWaybill ? LoadingComponent : MapComponent}
    </Box>
  );
}
export default WaybillImportGeocoding;
