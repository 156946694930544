import { useTranslate } from 'react-admin';

function useTranslateVehicleType() {
  const translate = useTranslate();
  return [
    { id: 'BIKE', name: translate('resources.vehicles.message.bike')  },
    { id: 'MOTORBIKE', name: translate('resources.vehicles.message.motorBike') },
    { id: 'CAR', name: translate('resources.vehicles.message.car') },
    { id: 'VAN', name: translate('resources.vehicles.message.van')  },
    { id: 'TRUCK', name: translate('resources.vehicles.message.truck')  },
  ];
}

export default useTranslateVehicleType;