import React from 'react';
import {required} from 'react-admin';
import { ImportForm, SelectImport } from '../../modules/ra-csv-importer';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    selectImport: {
      flexBasis: '22.5%'
    }
})

function RetailUploadForm(props) {
    const classes = useStyles();
    return (
      <ImportForm>
        <SelectImport
            source="dni" 
            variant="standard" 
            classes={classes.selectImport} 
            validate={[required()]} 
        />
        <SelectImport
            source="name" 
            variant="standard" 
            classes={classes.selectImport} 
            validate={[required()]} 
        />
        <SelectImport
            source="email" 
            variant="standard" 
            classes={classes.selectImport} 
            validate={[required()]} 
        />
        <SelectImport
            source="phone"
            variant="standard" 
            classes={classes.selectImport} 
            validate={[required()]} 
        />
      </ImportForm>
    )
}

export default RetailUploadForm;