import React from 'react';
import {
    Filter,
    SelectInput,
    useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import DateWithCustomTimeInput from '../../components/DateWithCustomTimeInput';
import useTranslateRangeFilter from './useTranslateRangeFilter';

const useStyles = makeStyles({
    filter: {
        marginTop: 0,
        marginLeft: '0px',

        '& .filter-field': {
            flexGrow: 1,
        },
        '& .MuiFormControl-root': {
            width: '100%',
        }
    }
})

function Filters(props) {
    const classes = useStyles();
    const rangeStatus = useTranslateRangeFilter();
    const translate = useTranslate();

    const handlerChange = (event) => {
        const today = new Date();
        let old = new Date();
        let newFilter = {};
        const days = event.target.value;
        if (days === '15DAYS'){
            old.setDate(today.getDate() - 15);
        }else if (days === '30DAYS'){
            old.setDate(today.getDate() - 30);
        }
        newFilter = {
            date_gte: old.toLocaleDateString('en-US') ,
            date_lte: today.toLocaleDateString('en-US'),
            range: event.target.value
        };
        props.setFilters(newFilter)
    }

    return (
        <Filter 
            className={classes.filter} 
            resource={props.resource}
            
            {...props}
        >
            <SelectInput 
                source="range"
                label={translate('dashboard.filters.range')}
                choices={rangeStatus}
                allowEmpty = {false}
                alwaysOn
                onChange={handlerChange}
                
            />
            <DateWithCustomTimeInput 
                source="date_gte" 
                label={translate('dashboard.filters.date_gte')}
                time="00:00:00"
                alwaysOn 
            />
            <DateWithCustomTimeInput 
                source="date_lte" 
                label={translate('dashboard.filters.date_lte')}
                time="23:59:59"
                alwaysOn 
            />
        </Filter>
    )
}

export default Filters;