import React from 'react';

import WaybillContextProvider from './context/WaybillContextProvider';
import WaybillWrapper from './WaybillWrapper';

export function WaybillShow(props) {
  return (
    <WaybillContextProvider>
      <WaybillWrapper {...props} />
    </WaybillContextProvider>
  );
}

export default WaybillShow;
