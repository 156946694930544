import React, { useState } from 'react';
import { useForm } from 'react-final-form';
import {
  required,
  Button,
  SaveButton,
  TextInput,
  ReferenceInput,
  SelectInput,
  useCreate,
  useNotify,
  FormWithRedirect,
  useTranslate,
  maxLength,
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';

import { Dialog, DialogTitle, DialogContent, DialogActions, makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  dialogContent: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  shortInput: { 
    flexBasis: '47.5%',
    '& .MuiFormControl-root': { width: '100%' },
  }
});

const validate = {
  description: [maxLength(256), required()],
  vehicle_id: [],
  warehouse: [required()]
};


function RouteQuickCreateButton({ 
  onChange,
  className,
 }) {
  const classes = useStyles();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState(false);
  const [create, { loading }] = useCreate('routes');
  const notify = useNotify();
  const form = useForm();

  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const handleSubmit = async (values) => {
    create(
      { payload: { data: values } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false);
          form.change('route_id', data.id);
          onChange();
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <>
      <Button 
        onClick={handleClick} 
        className={className}
        label={translate('resources.waybills.message.new')}>
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label="Crear ruta"
      >
        <DialogTitle>{translate('resources.waybills.message.createRoute')}</DialogTitle>

        <FormWithRedirect
          resource="routes"
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent className={classes.dialogContent} >
                <TextInput
                  source="description"
                  variant="standard"
                  label={translate('resources.waybills.fields.description')}
                  fullWidth
                  validate={validate.description}
                />
                <ReferenceInput
                  source="warehouse_id"
                  variant="standard"
                  label={translate('resources.waybills.fields.warehouse')}
                  reference="warehouses"
                  className={classes.shortInput}
                  validate={validate.warehouse}
                  allowEmpty
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                  source="vehicle_id"
                  variant="standard"
                  label={translate('resources.waybills.fields.vehicleID')}
                  reference="vehicles"
                  allowEmpty
                  className={classes.shortInput}
                  filterToQuery={(searchText) => ({ plate: searchText, status: 'AVAILABLE' })}
                  filter={{ status: 'AVAILABLE' }}
                >
                  <SelectInput optionText="plate" />
                </ReferenceInput>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                  disabled={loading}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  );
}

export default RouteQuickCreateButton;
