import React from 'react';
import {
  List,
  ImageField, 
  SimpleList,
  Datagrid,
  TextField,
  ShowButton,
  DateField,
  ReferenceField,
} from 'react-admin';
import GroupIcon from '@material-ui/icons/Group';

function formatImage(value) {
    if (!value || typeof value === 'string') {
      return { url: value };
    } else {
      return value;
    }
  }

export const UserIcon = GroupIcon;

export function DeliveryList(props) {
  return(
    <List  {...props}>
      <Datagrid rowClick='show'>
        <DateField 
            source='createdAt'
        />
        <ImageField 
            format={formatImage}
            source='photo'
            accept='image/*' />
        <TextField 
          source='observations'  />
        <ReferenceField
          source="order_id" 
          reference="orders" 
        >
          <TextField source="code" />
        </ReferenceField>
        <TextField 
          source=' '  />
        <TextField 
          source='id' />
        <TextField 
          source="status" />
        <ShowButton label="" />
      </Datagrid>
    </List>
  );
  
}
export default DeliveryList;