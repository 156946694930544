import React, { useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { useTranslate, useQueryWithStore } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
      fontSize: '1rem',
  }
});

const colors = [
  '#3892ff',
  '#a187fc',
  '#e378e6',
  '#ff6cc2',
  '#ff6d97',
  '#ff8169',
  '#ff9e3c',
  '#ffbd05',
  '#fecd3a',
  '#fed246',
];

const styles = {
  chartSize: { width: '99%', height: 300 },
};

function CommuneChart({ data }) {
  const [state, setState] = useState({ activeIndex: 0 });
  const translate = useTranslate();
  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <Card>
          <CardContent>
            <p className="label">{`${payload[0].payload.name} : $${payload[0].value}`}</p>
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader
        classes={{
         title: classes.title
        }}
        title={translate('dashboard.communeChart.title')}
      />
      <CardContent>
        <div style={styles.chartSize}>
          {data && data.length ? (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  innerRadius={60}
                  label
                  isAnimationActive={false}
                  fill="#8884d8"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[index % colors.length]}
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          ): <Typography>{translate('dashboard.withoutData')} </Typography>}
        </div>
      </CardContent>
    </Card>
  );
}

export default CommuneChart;
