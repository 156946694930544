import React, { useState, useEffect } from 'react'
import { DateInput } from 'react-admin';
import { useFormState, useForm } from 'react-final-form';

function DateWithCustomTimeInput({ 
  source, 
  time,
  ...props
}) {
  const [currentISODatetime, setCurrentISODatetime] = useState(null);
  const { values } = useFormState();
  const form = useForm();

  useEffect(() => {
    const newDate = new Date(values[source]);
    if (isValidDate(newDate)) {
      const newISODate = newDate.toISOString();
      if (currentISODatetime !== newISODate) {
        const date = newISODate.split('T')[0];
        const localeDatetime= new Date(`${date} ${time}`);
        if (!isValidDate(localeDatetime)) {
          throw new Error('DateWithCustomTimeInput. Invalid time prop format, it must be "HH:mm:ss"');
        }
        const theISODatetime = localeDatetime.toISOString();
        setCurrentISODatetime(theISODatetime);
        form.change(source, theISODatetime);
      }
    }
  }, [values[source]]);

  const isValidDate = (date) => date instanceof Date && !isNaN(date);

  return (
    <DateInput source={source} {...props} />
  )
}

export default DateWithCustomTimeInput;