import React from "react";
import { useTranslate, Button } from "react-admin";
import LabelIcon from "@material-ui/icons/LocalOffer";
import jsPDF from "jspdf";
import "jspdf-autotable";

const LABELS_PER_PAGE = 3;

const generateRouteLabels = (records, fields) => {
  if (records.length > 0) {
    const doc = new jsPDF();

    let recordsPerPages = [];
    for (let i = 0; i < records.length; i += LABELS_PER_PAGE) {
      const nextPageFirstRecord = i + LABELS_PER_PAGE;
      recordsPerPages = [
        ...recordsPerPages,
        records.slice(i, nextPageFirstRecord),
      ];
    }

    for (let i = 0; i < recordsPerPages.length; i++) {
      if (i > 0) {
        doc.addPage();
      }
      for (let j = 0; j < recordsPerPages[i].length; j++) {
        const {
          deliveryPosition,
          code,
          route,
          qr_code,
          firstname,
          lastname,
          phone,
          address,
        } = recordsPerPages[i][j];
        const textOptions = { maxWidth: 120 };
        const labelHeight = 95;
        const logo = "/chitrack-background.png";
        const logoX = 12;
        const logoY = 10 + j * labelHeight;
        const qrX = 10;
        const qrY = 30 + j * labelHeight;
        const deliveryNumberX = 170;
        const deliveryNumberY = 23 + j * labelHeight;
        const deliveryLabelX = 140;
        const deliveryLabelY = 22.5 + j * labelHeight;
        const textX = 65;
        const routeY = 36 + j * labelHeight;
        const orderY = 44 + j * labelHeight;
        const deliveryToY = 52 + j * labelHeight;
        const receiverX = 72;
        const receiverY = 60 + j * labelHeight;
        doc.addImage(logo, "PNG", logoX, logoY, 75, 15);
        doc.addImage(qr_code, "PNG", qrX, qrY, 50, 50);
        doc.setFontSize(40);
        doc.text(`${deliveryPosition}`, deliveryNumberX, deliveryNumberY);
        doc.setFontSize(14);
        doc.text(fields.deliveryNumber, deliveryLabelX, deliveryLabelY);
        doc.text(`${fields.route}  ${route.code}`, textX, routeY);
        doc.text(`${fields.order}  ${code}`, textX, orderY, textOptions);
        doc.text(fields.deliveryTo, textX, deliveryToY);
        const toReceiverText = `${firstname} ${lastname ?? ""}
${fields.phone}: ${phone ?? ""}
${fields.address}: ${address}`;
        doc.text(toReceiverText, receiverX, receiverY, textOptions);
        doc.line(0, j * 100 - j * 5, 300, j * 100 - j * 5, "S");
      }
    }

    doc.save(`${fields.filename}${records[0].route?.code ?? "x"}.pdf`);
  }
};

function ExportRouteOrdersLabels({ record, disabled, ...props }) {
  const translate = useTranslate();

  const fields = {
    filename: translate("resources.routes.orderLabels.filename"),
    order: translate("resources.routes.orderLabels.fields.order"),
    route: translate("resources.routes.orderLabels.fields.route"),
    deliveryTo: translate("resources.routes.orderLabels.fields.deliveryTo"),
    deliveryNumber: translate(
      "resources.routes.orderLabels.fields.deliveryNumber"
    ),
    phone: translate("resources.routes.orderLabels.fields.phone"),
    address: translate("resources.routes.orderLabels.fields.address"),
  };

  const sortedOrders = record?.orders
    .sort((curr, next) => curr.priority - next.priority)
    .map((order, index) => ({ ...order, deliveryPosition: index + 1 }));

  const orders = sortedOrders || [];

  return (
    <Button
      onClick={() => {
        generateRouteLabels(orders, fields);
      }}
      label="resources.routes.orderLabels.actionButton"
      disabled={orders.length <= 0}
    >
      <LabelIcon />
    </Button>
  );
}

export default ExportRouteOrdersLabels;
