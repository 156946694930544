import React, { useContext } from 'react';
import { useLoadScript } from '@react-google-maps/api';

const googleMapsApiKey = process.env.REACT_APP_G_MAPS_API_KEY;
const libs = ['visualization'];

const GoogleMapsContext = React.createContext();

export function useGoogleMapsContext() {
  return useContext(GoogleMapsContext);
}

export function GoogleMapsContextProvider({ children, ...props }) {
  /* global window, google */
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey,
    language: 'es-419',
    libraries: libs,
  });

  const state = {
    isLoaded,
    error: loadError,
    google: window.google 
  }

  return (
    <GoogleMapsContext.Provider value={state}>
      { children }
    </GoogleMapsContext.Provider>
  )
}

export default GoogleMapsContext;
