import { default as es } from '../es/es';
import { default as dashboard } from './dashboard';
import { default as orders } from './orders';
import { default as waybills } from './waybills';
import { default as administrative_areas_level_1 } from './administrative_areas_level_1';
import { default as administrative_areas_level_2 } from './administrative_areas_level_2';

export default {
  ...es,
  dashboard,
  resources: {
    ...es.resources,
    orders,
    waybills,
    "administrative_areas/level_1": administrative_areas_level_1,
    "administrative_areas/level_2": administrative_areas_level_2,
  }
}