export default  {
  name: 'Subscription plan |||| Subscription plans',
  label: 'Subscription plans',
  fields: {
    subscription_plan_id: "Subscription plan",
    initial_date: "Initial date",
  },
  message:{ 
    update: 'Update plan',
    updateSubscription: 'Update subscription plan',
    subscriptionUpdated: 'Subscription plan successfully updated',
    subscriptionFinished: 'Your plan has finished',
    subscriptionContact: 'Contact support to manage the payment and reactivation of your service'
  }
}