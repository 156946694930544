import React, { cloneElement, useEffect } from "react";
import { ReferenceInput } from "react-admin";
import { useFormState, useForm } from "react-final-form";

function DependentReferenceInput({
  source,
  dependentSource,
  filterProp,
  resetOnDependentChange = true,
  children,
  ...props
}) {
  const { values } = useFormState();
  const form = useForm();
  const dependentValue = values[dependentSource] ? values[dependentSource] : "";

  useEffect(() => {
    if (resetOnDependentChange) {
      form.change(source);
    }
  }, [values[dependentSource]]);

  return (
    <ReferenceInput
      {...props}
      filter={{ [filterProp]: dependentValue }}
      source={source}
    >
      {children}
    </ReferenceInput>
  );
}
export default DependentReferenceInput;
