import * as React from 'react';
import { cloneElement, useMemo } from 'react';
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useTranslate,
} from 'react-admin';
import {ImportButton} from '../../modules/ra-csv-importer';
import {default as RetailUploadComponent} from './RetailUploadComponent';
import {default as RetailReviewComponent} from './RetailReviewComponent';

function RetailActions(props) {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        hasCreate,
        basePath,
        selectedIds,
        showFilter,
        total,
    } = useListContext();
    const translate = useTranslate();

    const disableImportNextStep = (stepIndex, state) => {
        const { formValues, recordsToImport } = state;
        return recordsToImport.length === 0 || !formValues.dni || !formValues.name || !formValues.email || !formValues.phone ;
    }
    
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <CreateButton 
                basePath={basePath} />
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <ImportButton 
                resource={resource}
                dialogTitle={translate('resources.clients/retail.message.import')}
                disableNextStep={disableImportNextStep} 
                UploadComponent={<RetailUploadComponent />}
                ReviewComponent={<RetailReviewComponent />}
            />
        </TopToolbar>
    );
};

export default RetailActions;