export default {
    name: 'Planner |||| Planners',
    label: 'Planners',
    fields: {
        firstName: 'Firstname',
        lastName: 'Lastname',
        email: 'Email',
        phone: 'Phone',
        dni: 'DNI',
        address: 'Address',
    },
    message: {
        register: 'Register planner',
        planner: 'Planner:',
    },
}