import React from 'react';
import { Login } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';


const styles = ({
  main: { 
    background: '#f6cc1a',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '50%',
    backgroundPosition: '50% 80%',
  }
});

function LoginPage(props) {
  return (
    <Login 
      backgroundImage="/chitrack-background.png"
      {...props} 
    />
  );
}

export default withStyles(styles)(LoginPage);