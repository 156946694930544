export default {
  name: "Ruta |||| Rutas",
  label: "Rutas",
  fields: {
    code: "ID",
    description: "Descripción",
    vehicle_id: "Vehículo",
    transport_id: "Transporte",
    status: "Estatus",
    createdAt: "Creado",
    createdAt_gte: "Fecha inicio",
    createdAt_lte: "Fecha fin",
    updatedAt: "Actualizado",
    warehouse_id: "Centro de distribución",
    warehouse: "Centro de distribución",
    weight: "Peso (kg)",
    volume: "Volumen  (mts³)",
    distance: "Distancia (km)",
    duration: "Duración (min)",
  },
  status: {
    initial: "Inicial",
    created: "Creada",
    assigned: "Asignada a transporte",
    accepted: "Aceptada por conductor",
    inTransit: "En tránsito",
    finished: "Finalizada",
  },
  orderLabels: {
    actionButton: "Etiquetas",
    filename: "etiquetas-de-ordenes-ruta-",
    fields: {
      order: "Orden",
      route: "Ruta",
      deliveryTo: "Entregar a",
      deliveryNumber: "Entrega Nro.",
      phone: "Teléfono",
      address: "Dirección",
    },
  },
  message: {
    date: "Fecha",
    id: "ID",
    name: "Nombre",
    address: "Dirección",
    amount: "Monto",
    waybill: "Guía",
    capacity: "Capacidad del Vehículo",
    mts: "(mts³)",
    kg: "(kg)",
    assigned: "Asignado",
    available: "Disponible",
    driver: "Conductor",
    order: "Órdenes",
    assignedVehicle: "Vehículo asignado",
    unAssignedVehicle: "Vehículo no asignado",
    route: "Ruta:",
    back: "Atrás",
    weight: "Peso (kg)",
    volume: "Volumen (mts³)",
  },
};
