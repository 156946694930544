import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
  email,
  useTranslate,
} from 'react-admin';

function RetailTitle({ record }) {
  const translate = useTranslate();
    return <span>{translate('resources.clients/retail.message.retail')} {`${record.name}`}</span>;
  }
  
const validate={
  dni: [maxLength(32), required()],
  name:  [maxLength(128), required()],
  email: [email(), maxLength(128), required()],
  phone: [minLength(2),maxLength(16), required()],
}

function RetailEdit(props) {
  return (
    <Edit title={<RetailTitle />} {...props}>
      <SimpleForm variant="standard">
        <TextInput source='dni' validate={validate.dni}/>
        <TextInput source='name' validate={validate.name} />
        <TextInput source='email' validate={validate.email}/>
        <TextInput source='phone' validate={validate.phone} />
      </SimpleForm>
    </Edit>
  );
}

export default RetailEdit;


