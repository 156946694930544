import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteButton,
  SelectField,
  ShowButton,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { default as RouteActions } from './RouteActions';
import useTranslateRouteStatus from './useTranslateRouteStatus';
import CustomButtonDelete from '../../components/ConfirmDeleteButton'

function RouteList(props) {
  const routeStatus = useTranslateRouteStatus();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => `${record.code}`}
      secondaryText={(record) => record.vehicle?.transport}
      tertiaryText={(record) => record.status}
    />
  );
  const datagrid = (
    <Datagrid rowClick='show'>
      <TextField source='code'  />
      <TextField source='description' />
      <ReferenceField
        source='vehicle_id'
        reference='vehicles'
        link="show"
      >
          <TextField source="plate" />
      </ReferenceField>
      <ReferenceField
          source="warehouse_id" 
          reference="warehouses" 
          alwaysOn 
      > 
          <TextField source="name"  />
      </ReferenceField>
      <SelectField
          source='status'
          choices={routeStatus}
        />
      <ShowButton label=""  />
      <EditButton label=""/>
      <CustomButtonDelete />
    </Datagrid>
  );
  return (
    <List
      {...props}
      actions={<RouteActions />}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default RouteList;
