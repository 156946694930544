import React, { useEffect, useCallback, useState } from 'react';
import {
  useDataProvider,
  useTranslate,
} from 'react-admin';


import OptimizedRoutesList from './OptimizedRoutesList';


const OptimizeRoutes = (props) => {
  
  const { orders, warehouse_id, routesState, handleRoutes, selectedFleet } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [error, setError] = useState('');

  useEffect(() => { 
    fetchOptimizeRoutes() 
  }, []);

  const fetchOptimizeRoutes = useCallback(async () => {
    handleRoutes(false);
    try{
      const { data } = await dataProvider.getOptimized('optimize', {
        data: { 
          orders: orders, 
          warehouse_id: warehouse_id,
          vehicles: selectedFleet
        },
      });
      handleRoutes(data);
    } catch(e){
      setError(e.message);
    }
    
  });

  return (
    <OptimizedRoutesList routes={routesState} error={error}/>
  );
};

export default OptimizeRoutes;
