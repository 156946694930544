import React, { useState, useEffect } from "react";
import {
  TextInput,
  SelectInput,
  BooleanInput,
  required,
  useDataProvider,
  useNotify,
  useTranslate,
} from "react-admin";
import {
  ImportForm,
  SelectImport,
  useImportContext,
  ReferenceSelectImport,
} from "../../modules/ra-csv-importer";
import { useForm, useFormState } from "react-final-form";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  selectImport: {
    flexBasis: "30%",
  },
  selectImportQuarter: {
    flexBasis: "22.5%",
  },
  label: {
    color: "gray",
    flexBasis: "100%",
  },
  inlineLabel: {
    color: "gray",
  },
  input: {
    flexBasis: "30%",
  },
  halfInput: {
    flexBasis: "45%",
  },
  fullwidthInput: {
    flexBasis: "100%",
  },
  filenameInput: {
    flexBasis: "65%",
  },
});

function SelectTemplateInput({ ...props }) {
  const [templates, setTemplates] = useState([]);
  const dataProvider = useDataProvider();
  const [state, dispatch] = useImportContext();
  const form = useForm();

  useEffect(() => {
    dataProvider
      .getList("settings/import_templates", {
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "id", order: "ASC" },
        filter: { resource: "orders" },
      })
      .then((resp) => {
        const data = resp ? resp.data : [];
        setTemplates(data);
      });
  }, []);

  const onChangeTemplate = (event) => {
    const { values: formValues } = form.getState();
    const template = templates.filter(
      (template) => template.id === event.target.value
    );
    const values = template.length
      ? {
          waybill_code: formValues.waybill_code,
          from_filename: formValues.from_filename,
          template_id: template[0].id,
          template_name: template[0].name,
          ...template[0].template,
        }
      : formValues;
    const fields = Object.keys(values);
    form.batch(() => {
      fields.forEach((field) => form.change(field, values[field]));
    });
    dispatch({ type: "set_form_values", values });
  };

  return (
    <SelectInput
      {...props}
      source="template_id"
      choices={templates}
      label="resources.waybills.message.selectTemplate"
      allowEmpty={true}
      variant="standard"
      onChange={onChangeTemplate}
    />
  );
}

function TemplateNameInput({ ...props }) {
  const { values } = useFormState();
  const label = values.template_id
    ? "resources.waybills.message.updateTemplateName"
    : "resources.waybills.message.inputTemplateName";

  return (
    <TextInput
      style={{ flexBasis: "45%" }}
      source="template_name"
      label={label}
      variant="standard"
    />
  );
}

function FileNameImportInput({
  source,
  label,
  resource,
  variant = "standard",
  validate,
  className,
  formClassName,
  disabled,
}) {
  const [state] = useImportContext();
  const form = useForm();

  useEffect(() => {
    form.change(source, state.file.name);
  }, [state.file.name]);

  return (
    <TextInput
      formClassName={formClassName}
      className={className}
      source={source}
      resource={resource}
      label={label}
      variant={variant}
      validate={validate}
      disabled={disabled}
    />
  );
}

function WaybillImportForm(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleSaveTemplate = (values) => {
    const {
      template_id,
      template_name,
      files,
      waybill_code,
      from_filename,
      ...template
    } = values;
    const data = {
      name: template_name,
      resource: "orders",
      template,
    };
    if (template_id && template_name) {
      dataProvider
        .update("settings/import_templates", { id: template_id, data })
        .then((data) =>
          notify("resources.waybills.message.updatedImportTemplate")
        )
        .catch((error) => notify(error.message, "error"));
    }
    if (!template_id && template_name) {
      dataProvider
        .create("settings/import_templates", { data })
        .then((data) =>
          notify("resources.waybills.message.createdImportTemplate")
        )
        .catch((error) => notify(error.message, "error"));
    }
  };

  return (
    <ImportForm submitButton={true} onSaveAssociation={handleSaveTemplate}>
      <Typography variant="subtitle1" className={classes.label}>
        {translate("resources.waybills.message.importTemplate")}
      </Typography>
      <SelectTemplateInput className={classes.halfInput} />
      <Typography variant="subtitle1" style={{ color: "gray" }}>
        {translate("resources.waybills.message.or")}
      </Typography>
      <TemplateNameInput className={classes.halfInput} />
      <Typography variant="subtitle1" className={classes.label}>
        {translate("resources.waybills.message.landing")}
      </Typography>
      <TextInput
        className={classes.input}
        source="waybill_code"
        variant="standard"
      />
      <FileNameImportInput
        className={classes.filenameInput}
        source="from_filename"
        variant="standard"
        disabled
      />
      <Typography variant="subtitle1" className={classes.label}>
        {translate("resources.waybills.message.orders")}
      </Typography>
      <SelectImport
        source="code"
        variant="standard"
        classes={classes.selectImport}
        validate={[required()]}
      />
      <SelectImport
        source="firstname"
        variant="standard"
        classes={classes.selectImport}
        validate={[required()]}
      />
      <SelectImport
        source="lastname"
        variant="standard"
        classes={classes.selectImport}
      />
      <SelectImport
        source="phone"
        variant="standard"
        classes={classes.selectImport}
        validate={[required()]}
      />
      <SelectImport
        source="email"
        variant="standard"
        classes={classes.selectImport}
      />
      <ReferenceSelectImport
        source="retail"
        reference="clients/retail"
        referenceSource="client_retail_id"
        referenceTarget="name"
        referenceValue="id"
        variant="standard"
        classes={classes.selectImport}
      />
      <SelectImport
        source="street"
        variant="standard"
        classes={classes.selectImport}
        validate={[required()]}
      />
      <SelectImport
        source="streetNumber"
        variant="standard"
        classes={classes.selectImport}
      />
      <BooleanInput
        source="addressHasAreas"
        variant="standard"
        initialValue={false}
        classes={classes.fullwidthInput}
      />
      <ReferenceSelectImport
        source="commune"
        reference="administrative_areas/level_2"
        referenceSource="administrative_area_level_2_id"
        referenceTarget="name"
        referenceValue="id"
        variant="standard"
        classes={classes.selectImport}
        perPage={360}
      />
      <ReferenceSelectImport
        source="region"
        reference="administrative_areas/level_1"
        referenceSource="administrative_area_level_1_id"
        referenceTarget="name"
        referenceValue="id"
        variant="standard"
        classes={classes.selectImport}
      />
      <SelectImport
        source="address_references"
        variant="standard"
        classes={classes.selectImport}
      />
      <SelectImport
        source="amount"
        variant="standard"
        classes={classes.selectImportQuarter}
      />
      <SelectImport
        source="weight"
        variant="standard"
        classes={classes.selectImportQuarter}
      />
      <SelectImport
        source="volume"
        variant="standard"
        classes={classes.selectImportQuarter}
      />
      <SelectImport
        source="priority"
        variant="standard"
        classes={classes.selectImportQuarter}
      />
    </ImportForm>
  );
}

export default WaybillImportForm;
