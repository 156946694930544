import React from 'react';
import { useTranslate } from 'react-admin';
import TimerOff from '@material-ui/icons/TimerOff';
import makeStyles from '@material-ui/styles/makeStyles';

const useStyles = makeStyles(
  theme => ({
      container: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          [theme.breakpoints.up('md')]: {
              height: '100%',
          },
          [theme.breakpoints.down('sm')]: {
              height: '100vh',
              marginTop: '-3em',
          },
      },
      icon: {
          width: '9em',
          height: '9em',
      },
      message: {
          textAlign: 'center',
          fontFamily: 'Roboto, sans-serif',
          opacity: 0.5,
          margin: '0 1em',
      },
      toolbar: {
          textAlign: 'center',
          marginTop: '2em',
      },
  }),
  { name: 'SubscriptionPlanFinished' }
);

function SubscriptionPlanFinished(props) {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.container}>
      <div className={classes.message}>
        <TimerOff className={classes.icon} />
        <h1>{translate('resources.subscription_plans.message.subscriptionFinished')}</h1>
        <div>{translate('resources.subscription_plans.message.subscriptionContact')}</div>
      </div>
    </div>
  )
}

export default SubscriptionPlanFinished;