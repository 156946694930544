import React from "react";
import {
  TopToolbar,
  ListButton,
  EditButton,
  ShowButton,
  Button,
  useTranslate,
} from "react-admin";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

function OrderShowActions({ basePath, data, ...props }) {
  const translate = useTranslate();
  let waybill = {};
  if (data) {
    waybill = {
      id: data.waybill_id,
    };
  }
  console.log({ OrderEditActions: { basePath, data, ...props } });

  return (
    <TopToolbar>
      <Button
        basePath="/waybills"
        label={translate("resources.orders.message.back")}
        icon={<ChevronLeft />}
        record={waybill}
        onClick={() => props.history.goBack()}
      />
      <EditButton basePath={basePath} record={data} />
    </TopToolbar>
  );
}

export default OrderShowActions;
