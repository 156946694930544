import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  ReferenceInput,
  SelectInput,
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  hide: {
    display: 'none',
  }
})

const validate={
  code: [maxLength(64), required()],
  name:  [maxLength(128), required()],
}

export function CommuneCreate(props) {
  const classes = useStyles();
  return (
    <Create  {...props}>
      <SimpleForm>
        <TextInput source="code"  validate={validate.code} />
        <TextInput source="name"  validate={validate.name} />
        <ReferenceInput 
          source="parent_area_id" 
          reference="administrative_areas/level_1"
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
}

export default CommuneCreate;