import React from "react";
import {
  SimpleForm,
  Show,
  ReferenceField,
  TextField,
  DateField,
  ImageField,
  NumberField,
  EmailField,
  SelectField,
  SingleFieldList,
  ReferenceManyField,
  useTranslate,
} from "react-admin";
import Map from "../../components/Map";
import { Box } from "@material-ui/core";
import useTranslateOrderStatus from "./useTranslateOrderStatus";
import { makeStyles } from "@material-ui/core";
import PreviewImageField from "../../components/PreviewImageField";
import OrderShowActions from "./OrderShowActions";
import useTranslateDeliveryStatus from "../deliveries/useTranslateDeliveryStatus";

function OrderTitle({ record }) {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.orders.message.order")} {`${record.code}`}
    </span>
  );
}

const useButtonStyles = makeStyles((theme) => ({
  button: {
    marginBottom: theme.spacing(3),
  },
  spinner: {
    marginLeft: theme.spacing(1),
    color: "#ffffff",
  },
}));

function MapField(props) {
  const { record } = props;
  const classes = useButtonStyles();

  const markers = [
    {
      lat: record.latitude,
      lng: record.longitude,
    },
  ];

  return (
    <Box height="30vh" width="100%">
      <Map data={markers} />
    </Box>
  );
}

const useStyles = makeStyles({
  form: {
    "& .MuiCardContent-root": {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
  },
  shortField: { flexBasis: "12.5%" },
  field: { flexBasis: "18%" },
  imageField: {
    "& div[class^='RaFormInput-input']": {
      width: "100%",
    },
  },
  shortInput: {
    flexBasis: "22.5%",
    "& .MuiFormControl-root": { width: "100%" },
  },
  largeInput: {
    flexBasis: "48.5%",
    "& .MuiFormControl-root": { width: "100%" },
  },
  xlargeInput: {
    flexBasis: "75%",
    textAlign: "left",
    "& .MuiFormControl-root": { width: "100%" },
  },
  fullWidth: {
    flexBasis: "100%",
    textAlign: "left",
    "& .MuiFormControl-root": { width: "100%" },
  },
  chipSelect: {
    backgroundColor: "lightgray",
    color: "currentColor",
    padding: "0.75rem",
    borderRadius: "20px",
    margin: "0.75rem 0.75rem 0 0",
  },
});

function OrderShow(props) {
  const classes = useStyles();
  const orderStatus = useTranslateOrderStatus();
  const deliveryTranslate = useTranslateDeliveryStatus();
  const translate = useTranslate();
  return (
    <Show
      {...props}
      title={<OrderTitle />}
      actions={<OrderShowActions history={props.history} />}
    >
      <SimpleForm toolbar={<> </>} className={classes.form} variant="standard">
        <DateField source="createdAt" formClassName={classes.field} />
        <DateField source="updatedAt" formClassName={classes.field} />
        <TextField source="code" formClassName={classes.shortField} />
        <SelectField
          source="status"
          choices={orderStatus}
          formClassName={classes.shortField}
        />
        <ReferenceField
          source="waybill_id"
          reference="waybills"
          formClassName={classes.shortField}
        >
          <TextField source="code" />
        </ReferenceField>
        <ImageField
          source="qr_code"
          title="QR code"
          formClassName={`${classes.shortField} ${classes.imageField}`}
        />
        <ReferenceField
          source="client_retail_id"
          reference="clients/retail"
          formClassName={classes.shortInput}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceManyField
          label={translate("resources.orders.message.label")}
          reference="deliveries"
          target="order_id"
          formClassName={classes.xlargeInput}
        >
          <SingleFieldList
            classes={{
              link: classes.chipSelect,
            }}
          >
            <SelectField source="status" choices={deliveryTranslate} />
          </SingleFieldList>
        </ReferenceManyField>
        <TextField source="firstname" formClassName={classes.shortInput} />
        <TextField source="lastname" formClassName={classes.shortInput} />
        <TextField source="phone" formClassName={classes.shortInput} />
        <EmailField source="email" formClassName={classes.shortInput} />
        <NumberField source="amount" formClassName={classes.shortInput} />
        <NumberField source="priority" formClassName={classes.shortInput} />
        <NumberField source="weight" formClassName={classes.shortInput} />
        <NumberField source="volume" formClassName={classes.shortInput} />
        <ReferenceField
          source="administrative_area_level_1_id"
          reference="administrative_areas/level_1"
          formClassName={classes.largeInput}
          alwaysOn
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="administrative_area_level_2_id"
          reference="administrative_areas/level_2"
          formClassName={classes.largeInput}
          alwaysOn
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="address" formClassName={classes.largeInput} />
        <TextField
          source="address_references"
          formClassName={classes.largeInput}
        />
        <MapField formClassName={classes.fullWidth} />
      </SimpleForm>
    </Show>
  );
}

export default OrderShow;
