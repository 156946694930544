import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  minLength,
  email,
} from 'react-admin';

const validate={
  dni: [maxLength(32), required()],
  name:  [maxLength(128), required()],
  email: [email(), maxLength(128), required()],
  phone: [minLength(2),maxLength(16), required()],
}

export function RetailCreate(props) {
    return (
      <Create {...props}>
        <SimpleForm>
          <TextInput source='dni' validate={validate.dni}  />
          <TextInput source='name' validate={validate.name} />
          <TextInput source='email' validate={validate.email} />
          <TextInput source='phone' validate={validate.phone} />
        </SimpleForm>
      </Create>
    );
  }

export default RetailCreate;