import React, { useEffect, Children, ReactElement, cloneElement } from "react";
import { Form } from "react-final-form";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import { FileInput, useTranslate } from "react-admin";
import FileReaderField from "./FileReaderField";
import { useImportContext } from "./ImportContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  selectsBox: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionsBox: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

function ImportForm({
  record,
  source,
  children,
  className,
  style,
  variant = "filled",
  submitButton = false,
  submitLabel,
  onSaveAssociation = () => {},
}) {
  const classes = useStyles();
  const translate = useTranslate();
  const [state, dispatch] = useImportContext();
  const { file, resource, fields, records, formValues, selectedFields } = state;

  useEffect(() => {
    if (file.content && typeof file.content === "string") {
      // dispatch({ type: 'reset' });
      const { fields, records } = parseCSVToJSON(file.content);
      dispatch({ type: "set_imported_data", fields, records });
    }
  }, [file.content]);

  const parseCSVToJSON = (csvText) => {
    const csvRows = csvText.split(/\r\n|\r|\n/);
    const [heading, ...rows] = csvRows;
    const fields = heading.split(/\;/);
    const records = rows
      .filter((row) => {
        return row.replaceAll(/[\s\,\;]/g, "") !== "";
      })
      .map((row) => {
        const rowData = row.split(/\;/);
        return fields.reduce((obj, field, index) => {
          const data = rowData[index] ? rowData[index].replaceAll('"', "") : "";
          obj[field] = data.trim();
          return obj;
        }, {});
      });
    return { fields, records };
  };

  const removeFile = () => dispatch({ type: "reset" });

  const submit = (values) => {
    onSaveAssociation(values);
    dispatch({ type: "set_form_values", values });
  };

  const onChangeField = (event, form) => {
    const { values } = form.getState();
    if (event.target && event.target.name) {
      selectedFields[event.target.name] = event.target.value;
    }
    dispatch({ type: "set_form_values", values });
    dispatch({ type: "set_imported_data", fields, records, selectedFields });
  };

  return (
    <Form
      onSubmit={submit}
      initialValues={formValues}
      render={(formProps) => (
        <>
          <FileInput
            source="files"
            label="ra-csv-importer.file.title"
            accept=".csv"
            multiple={false}
            labelSingle="ra-csv-importer.message.message1"
            options={{ onRemove: removeFile }}
          >
            <FileReaderField />
          </FileInput>
          <form
            onSubmit={formProps.handleSubmit}
            style={{ display: file.content ? "block" : "none" }}
          >
            <Box className={classes.selectsBox}>
              {Children.map(
                children,
                (input) =>
                  input &&
                  cloneElement(input, {
                    resource,
                    onChange: (event) => onChangeField(event, formProps.form),
                  })
              )}
            </Box>
            {submitButton && (
              <Box className={classes.actionsBox}>
                <Button type="submit" variant="contained" color="primary">
                  {submitLabel ||
                    translate("ra-csv-importer.button.association")}
                </Button>
              </Box>
            )}
          </form>
        </>
      )}
    />
  );
}

export default ImportForm;
