import React, { useEffect, useState } from 'react';
import {
  Edit,
  useUpdate,
  useNotify,
  useQueryWithStore,
  useTranslate,
} from 'react-admin';
import { useContext } from 'react';
import WaybillAside from './WaybillAside';
import WaybillTitle from './WaybillTitle';
import WaybillShowActions from './WaybillShowActions';
import WaybillForm from './WaybillForm';
import { makeStyles } from '@material-ui/core/styles';
import { DragDropContext } from 'react-beautiful-dnd';
import WaybillContext from './context';

const useStyles = makeStyles({
  root: { alignItems: 'flex-start' },
});

export function WaybillWrapper(props) {
  const [vehicles, setVehicles] = useState(null);
  const { routes, setRoutes } = useContext(WaybillContext);
  const classes = useStyles();
  const [update, { loading }] = useUpdate('routes');
  const notify = useNotify();
  const translate = useTranslate();

  const unavailableRoutes = ['ACCEPTED_BY_DRIVER', 'IN_TRANSIT', 'FINISHED'];

  let { loaded, data } = useQueryWithStore({
    type: 'getList',
    resource: 'vehicles',
    payload: {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'plate', order: 'asc' },
      filter: { status: 'AVAILABLE' },
    },
  });
  let vehiclesMap = {};
  useEffect(() => {
    if (data && loaded) {
      data.map((vehicle) => {
        vehiclesMap[vehicle.id] = vehicle;
      });
      setVehicles(vehiclesMap);
    }
  }, [data]);

  const handleUpdateRoute = async (
    values,
    id,
    assignedVehicle = null,
    unassignedVehicle = null
  ) => {
    if (
      assignedVehicle &&
      assignedVehicle.volumeCapacity - assignedVehicle.volumeAssigned <
        routes[id].volume
    ) {
      notify(
        translate('resources.waybills.message.errorExceededCapacity'),
        'error'
      );
    } else if (unavailableRoutes.includes(routes[id].status)) {
      notify(
        translate('resources.waybills.message.errorFinalizedRoute'),
        'error'
      );
    } else {
      update(
        { payload: { data: values, id: id } },
        {
          onSuccess: ({ data }) => {
            let newVehicles = vehicles;
            if (assignedVehicle) {
              assignedVehicle.volumeAssigned += data.volume;
              assignedVehicle.weightAssigned += data.weight;
              if (assignedVehicle.volumeCapacity - assignedVehicle.volumeAssigned <= 0){
                delete newVehicles[assignedVehicle.id];
              }else{
                newVehicles[assignedVehicle.id] = assignedVehicle;
              }
            }
            if (unassignedVehicle) {
              unassignedVehicle.volumeAssigned -= data.volume;
              unassignedVehicle.weightAssigned -= data.weight;
              newVehicles[unassignedVehicle.id] = unassignedVehicle;
            }

            let newRoutes = routes;
            newRoutes[id] = data;
            setRoutes(newRoutes);
            setVehicles(newVehicles);
          },
          onFailure: ({ error }) => {
            notify(error.message, 'error');
          },
        }
      );
    }
  };

  const onDrag = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (destination.droppableId !== source.droppableId) {
      if (destination.droppableId !== 'vehicles' && source.droppableId !== 'vehicles'){
        return;
      }
      let assignedVehicle = null;
      let unassignedVehicle = null;
      let routeData = {};
      let routeId = '';
      if (destination.droppableId === 'vehicles') {
        const oldRoute = routes[source.droppableId];
        routeData = { vehicle_id: null };
        routeId = oldRoute.id;
        unassignedVehicle = oldRoute.vehicle;
      } else {
        const oldRoute = routes[destination.droppableId];
        assignedVehicle = Object.values(vehicles)[source.index];
        unassignedVehicle = oldRoute.vehicle;
        routeId = oldRoute.id;
        routeData = { vehicle_id: assignedVehicle.id };
        if (assignedVehicle.id === oldRoute.vehicle_id) {
          return;
        }
      }
      handleUpdateRoute(routeData, routeId, assignedVehicle, unassignedVehicle);
    }
  };
  return (
    <DragDropContext onDragEnd={onDrag}>
      <Edit
        title={<WaybillTitle />}
        actions={<WaybillShowActions />}
        aside={<WaybillAside vehicles={vehicles} />}
        classes={classes}
        component="div"
        {...props}
      >
        <WaybillForm {...props} />
      </Edit>
    </DragDropContext>
  );
}

export default WaybillWrapper;
