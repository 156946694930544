import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import { default as BikeIcon } from '../icons/BikeIcon';
import { default as MotorcycleIcon } from '../icons/MotorcycleIcon';
import { default as CarIcon } from '../icons/CarIcon';
import { default as VanIcon } from '../icons/VanIcon';
import { default as TruckIcon } from '../icons/TruckIcon';

const useStyles = makeStyles({
  icon: {
    width: '0.5em',
    paddingLeft: 2,
  },
});

function VehicleTypeField({ record = {}, source }) {
  const classes = useStyles();
  const color = record.transport?.color || '#000';
  return (
    record &&
    (record[source] === 'BIKE' ? (
      <BikeIcon color={color} />
    ) : record[source] === 'MOTORBIKE' ? (
      <MotorcycleIcon color={color} />
    ) : record[source] === 'CAR' ? (
      <CarIcon color={color} />
    ) : record[source] === 'VAN' ? (
      <VanIcon color={color} />
    ) : (
      <TruckIcon color={color} />
    ))
  );
}

export default VehicleTypeField;
