import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  maxLength,
  number,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';


const validate = {
  transport_id: [required()],
  driver_id: [],
  type: [required()],
  plate: [maxLength(16), required()],
  weightCapacity: [number(), required()],
  volumeCapacity: [number(), required()],
  brand: [maxLength(32), required()],
  model: [maxLength(32), required()],
  year: [number(), required()],
  color: [maxLength(32), required()],
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function VehicleCreate(props) {
  const classes = useStyles();
  const translate = useTranslate();

  const vehicleTypes = [
    { id: 'BIKE', name: translate('resources.vehicles.message.bike')  },
    { id: 'MOTORBIKE', name: translate('resources.vehicles.message.motorBike') },
    { id: 'CAR', name: translate('resources.vehicles.message.car') },
    { id: 'VAN', name: translate('resources.vehicles.message.van')  },
    { id: 'TRUCK', name: translate('resources.vehicles.message.truck')  },
  ];
  return (
    <Create  {...props}>
      <SimpleForm>
        <ReferenceInput
          source='transport_id'
          reference='transports'
          filterToQuery={(searchText) => ({ name: searchText })}
          validate={validate.transport_id}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="driver_id"
          reference="drivers"
          allowEmpty
          filterToQuery={(searchText) => ({ firstName: searchText })}
          validate={validate.driver_id}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectInput
          source="type"
          choices={vehicleTypes}
          validate={validate.type}
        />
        <TextInput
          source='plate'
          validate={validate.plate}
          formClassName={classes.inlineBlock}
        />
        <NumberInput
          source='weightCapacity'
          validate={validate.weightCapacity}
          formClassName={classes.inlineBlock}
        />
        <NumberInput
          source='volumeCapacity'
          validate={validate.volumeCapacity}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='brand'
          validate={validate.brand}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='model'
          validate={validate.model}
          formClassName={classes.inlineBlock}
        />
        <NumberInput
          source='year'
          validate={validate.year}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='color'
          validate={validate.color}
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Create>
  );
}

export default VehicleCreate;
