import * as React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { useSetLocale, useLocale } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: { 
        "& .MuiInput-underline:after": {
            borderBottomWidth: 0,
        },
        "& .MuiInput-underline:before": {
            borderBottomWidth: 0,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
            borderBottomWidth: 0,
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "transparent",
        },
        color: "black",
    },
    icon: {
        color: "black",
    }
});

function LocaleSwitcher(){
    const locale = useLocale(); 
    const setLocale = useSetLocale();
    const classes = useStyles(); 
    const handleChange = (event) => {
        setLocale(event.target.value);
    };
    return (
        <div className={classes.root}>
            <Select
                onChange={handleChange}
                value={locale}
                classes={classes}
            >
                <MenuItem value='es'>Español (CL)</MenuItem>
                <MenuItem value='es-ve'>Español (VE)</MenuItem>
                <MenuItem value='en'>English</MenuItem>
            </Select>
        </div>
    );
};

export default LocaleSwitcher;