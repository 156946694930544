import React from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
      fontSize: '1rem',
  }
});

const colors = {
  CREATED: '#557CF2',
  ASSIGNED_TRANSPORT: '#F50C35',
  ACCEPTED_BY_DRIVER: '#8F7C45',
  IN_TRANSIT: '#f4bd18',
  FINISHED: '#51B86A'};
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const styles = {
  chartSize: { width: '99%', height: 300 },
  legend: { fontFamily: 'Roboto, Helvetica' },
};

function RoutesStatusChart({ data }) {
  const translate = useTranslate();
  const classes = useStyles();

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <Card>
          <CardContent>
            <p className="label">{`${payload[0].payload.name} : ${payload[0].value}`}</p>
          </CardContent>
        </Card>
      );
    }

    return null;
  };

  return (
    <Card>
      <CardHeader
       classes={{
        title: classes.title
      }}
        title={translate('dashboard.routesStatusChart.title')}
      />
      <CardContent>
        <div style={styles.chartSize}>
          {data && data.length ? (
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  outerRadius={99}
                  label={renderCustomizedLabel}
                  labelLine={false}
                  isAnimationActive={false}
                  fill="#8884d8"
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={colors[entry.id]}
                    />
                  ))}
                </Pie>
                <Legend wrapperStyle={styles.legend} />
                <Tooltip content={<CustomTooltip />} />
              </PieChart>
            </ResponsiveContainer>
          ): <Typography>{translate('dashboard.withoutData')} </Typography>}
        </div>
      </CardContent>
    </Card>
  );
}

export default RoutesStatusChart;
