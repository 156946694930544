export default {
    name: 'Transporte |||| Transportes',
    label: 'Transportes',
    fields: {
        name: 'Nombre',
        dni: 'RUT',
        address: 'Dirección',
        phone: 'Teléfono',
        website: 'URL sitio web',
        callCenterPhone: 'Teléfono callcenter',
        contactFirstName: 'Nombre persona de contacto',
        contactLastName: 'Apellido persona de contacto',
        contactEmail: 'Email persona de contacto',
        contactPhone: 'Teléfono persona de contacto',
        contac: 'Persona de contacto',
    },
    message: {
        vehicles: 'Vehículos',
        drivers: 'Conductores',
        register: "Registrar empresa de transporte",
        plate: 'Placa',
        brand: 'Marca',
        model: 'Modelo',
        maxMt: 'Máx. (mts³)',
        maxKg: 'Máx. (kg)',
        driver: 'Conductor',
        name: 'Nombre',
        dni: 'RUT',
        email: 'Correo electrónico',
        capacity: 'Capacidad de flota',
        volume: 'Volumen',
        weight: 'Peso',
        assigned: 'Asignado',
        available: 'Disponible',
        details: 'Detalles',
        created: 'Creado',
        update: 'Actualizado',
        transportCompany: 'Empresa de transporte:'
    }
}