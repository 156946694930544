export default {
    filters: {
        date_gte: 'Fecha inicio',
        date_lte: 'Fecha fin',
        range: 'Filtrar',
        rangeOptions: {
            today: "Hoy",
            last15Days: "Últimos 15 días",
            last30Days: "Últimos 30 días",
        },
    },
    withoutData: 'Sin datos',
    monthlyRevenue: "Monto facturado del mes",
    weeklyRevenue: "Monto facturado de la semana",
    dailyRevenue: "Monto facturado del día",
    pendingWaybills: {
        title: "Guías pendientes por asignar ruta",
        fields: {
            code: "Guía",
            createdAt: "Fecha",
            orders: "Órdenes",
            assigned: "Asignadas",
            pending: "Pendiente",
        }
    },
    regionChart:{
        title: "Facturado por región",
        subtitle: "Últimos 30 días",
    },
    communeChart:{
        title: "Top 10 Facturado por comuna",
        subtitle: "Últimos 30 días",
    },
    heatMap:{
        title: "Distribución zonas de entrega",
        subtitle: "Últimos 30 días",
    },
    orderStatusChart:{
        title: "Órdenes procesadas",
        subtitle: "Últimos 30 días",
    },
    retailsChart:{
        title: "Órdenes por cliente retail",
        subtitle: "Últimos 30 días",
        amount: "Monto",
        orders: "#Órdenes",
    },
    waybillsStatusChart:{
        title: "Guías",
        subtitle: "Últimos 30 días",
    },
    routesStatusChart:{
        title: "Rutas",
        subtitle: "Últimos 30 días",
    },
}