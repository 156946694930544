import React, { useState } from 'react';
import { ReferenceInput, SelectInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import RouteQuickCreateButton from './RouteQuickCreateButton';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    margen: {
        marginRight: '20px'
    },
});

const RouteReferenceInput = props => {
    const translate = useTranslate();
    const classes = useStyles();
    const [version, setVersion] = useState(0);
    const handleChange = () => setVersion(version + 1);

    return (
        <div className={classes.root}>
            <RouteQuickCreateButton 
                onChange={handleChange} 
                className={classes.margen}
            />
            <ReferenceInput 
                variant="standard"
                label={translate('resources.waybills.message.route')} 
                key={version}
                filter={{status_in: ['CREATED', 'ASSIGNED_TRANSPORT'] }} 
                {...props}
            >
                <SelectInput  optionText="description" />
            </ReferenceInput>
        </div>
    );
};

export default RouteReferenceInput;
