import React, { useState } from "react";
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  DateInput,
  Button,
  useTranslate,
  useRefresh,
  useNotify,
  required,
} from "react-admin";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const basePath = process.env.REACT_APP_API_URL;

function SubscriptionPlanButton({ record, ...props }) {
  const notify = useNotify();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [subscriptionModal, openSubscriptionModal] = useState(false);

  const handleClick = () => openSubscriptionModal(true);

  const handleClose = () => openSubscriptionModal(false);

  const handleSubmit = (values) => {
    const token = sessionStorage.getItem("ACCESS_TOKEN");

    setLoading(true);
    fetch(`${basePath}/users/${record.id}/subscription/activate`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        Origin: window.location.origin
      },
      body: JSON.stringify(values),
      method: "PUT",
    })
      .then((data) => {
        notify("resources.subscription_plans.message.subscriptionUpdated");
        refresh();
      })
      .catch((error) => {
        notify("Ha ocurrido un error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        type="button"
        label="resources.subscription_plans.message.update"
        variant="outlined"
        onClick={handleClick}
      ></Button>
      <Dialog
        open={subscriptionModal}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="xs"
        aria-labelledby="subscription-plan-dialog-title"
      >
        <DialogTitle
          id="subscription-plan-dialog-title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          disableTypography
        >
          <Typography variant="h5">
            {translate(
              "resources.subscription_plans.message.updateSubscription"
            )}
          </Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            resource=""
            variant="outlined"
            saving={loading}
            save={handleSubmit}
          >
            <ReferenceInput
              source="subscription_plan_id"
              reference="subscription_plans"
              validate={required()}
              fullWidth
            >
              <SelectInput />
            </ReferenceInput>
            <DateInput source="initial_date" validate={required()} fullWidth />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SubscriptionPlanButton;
