import React from 'react';
import {
    Filter,
    SelectInput
} from 'react-admin';
import DateWithCustomTimeInput from '../../../components/DateWithCustomTimeInput';
import useTranslateWaybillStatus from '../../waybills/useTranslateWaybillStatus';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    filter: {
        '& .filter-field': {
            /* flexGrow: 1, */
        },
        '& .MuiFormControl-root': {
            width: '100%',
        }
    }
})

function WaybillFilters(props) {
    const classes = useStyles();
    const waybillStatus = useTranslateWaybillStatus();
    return (
        <Filter 
            className={classes.filter} 
            variant="standard"
            {...props}>
            <DateWithCustomTimeInput 
                source="createdAt_gte" 
                time="00:00:00" 
                alwaysOn 
                label="resources.waybills.fields.createdAt_gte"
            />
            <DateWithCustomTimeInput 
                source="createdAt_lte" 
                time="23:59:59"
                alwaysOn 
                label="resources.waybills.fields.createdAt_lte"
            />
            <SelectInput 
                source="status" 
                choices={waybillStatus} 
                alwaysOn
                label="resources.waybills.fields.status"
            />
        </Filter>
    )
}

export default WaybillFilters;