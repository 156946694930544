import React, { useContext, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  ListContextProvider,
  Datagrid,
  TextField,
  NumberField,
  SelectField,
  ShowButton,
  EditButton,
  useTranslate,
  ReferenceField,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import VehicleTypeField from '../../components/VehicleTypeField';
import WaybillContext from './context';
import useTranslateOrderStatus from '../orders/useTranslateOrderStatus';
import useTranslateRouteStatus from '../route/useTranslateRouteStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '25%',
    flexShrink: 1,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  vehicleCard: {
    padding: '10px !important'
  },
  datagridCell: {
    padding: '6px',
    maxWidth: '80px',
    textAlign: 'center',
    '&.column-address': {
      maxWidth: '100px',
    }
  },
  actionButton: {
    minWidth: '20px',
    padding: 0
  }
}));

const accordionStyles = makeStyles((theme) => ({
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  details: { padding: 0 }
}));

const linkStyles = makeStyles((theme) => ({
  root: { display: 'flex', flexWrap: 'nowrap', alignItems: 'center' },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

const grid = 3;

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgreen' : 'white',
  //padding: grid,
  //width: 250,
});

export default function RouteList(props) {
  const { routes, setRoutes } = useContext(WaybillContext);
  const orderStatus = useTranslateOrderStatus();
  const routeStatus = useTranslateRouteStatus();
  const translate = useTranslate();

  useEffect(() => {
    if (props.routes instanceof Array) {
      const newRoutes = {};
      props.routes.map((route) => {
        newRoutes[route.id] = route;
      });
      setRoutes(newRoutes);
    }
  }, [JSON.stringify(props.routes)]);

  const classes = useStyles();
  const accordionClass = accordionStyles();
  const linkClasses = linkStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className={classes.root}>
      {routes &&
        Object.values(routes).map((route) => {
          return (
            <Droppable droppableId={route.id} key={route.id}>
              {(provided, snapshot, onDragEnd) => (
                <Accordion
                  className={classes.ul}
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  style={getListStyle(snapshot.isDraggingOver)}
                  expanded={expanded === route.code}
                  onChange={handleChange(route.code)}
                  key={route.code}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${route.code}bh-content`}
                    id={route.code}
                  >
                    <div className={classes.heading}>
                      <Typography className={classes.heading}>
                        {route.code}&nbsp;({route.orders.length})
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                        {route.description}
                      </Typography>
                    </div>
                    <div className={classes.heading}>
                    <Typography className={classes.heading}>
                      {translate('resources.waybills.message.status')}
                    </Typography>
                      <SelectField 
                        source='status'
                        record={route}
                        choices={routeStatus}
                        className={classes.secondaryHeading}
                      />
                    </div>
                    <div className={classes.heading}>
                      <Typography className={classes.heading}>
                       {translate('resources.waybills.message.weightAndVolume')} 
                      </Typography>
                      <Typography className={classes.secondaryHeading}>
                      {route.weight} {translate('resources.waybills.message.kg')} {'  '}
                      {route.volume} {translate('resources.waybills.message.mts')} 
                      </Typography>
                    </div>
                    {route.vehicle && (
                      <Draggable
                        key={route.id}
                        draggableId={route.id}
                        index={0}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card style={{ backgroundColor: 'white' }}>
                              <CardContent className={classes.vehicleCard}>
                                <Typography className={linkClasses.root}>
                                  <VehicleTypeField
                                    record={route.vehicle}
                                    source="type"
                                  />
                                  &nbsp; {route.vehicle.plate}
                                </Typography>
                                <Typography color="textSecondary">
                                  {route.vehicle.weightAssigned} {translate('resources.waybills.message.kg')}  {' '}
                                  {route.vehicle.volumeAssigned} {translate('resources.waybills.message.mts')} 
                                </Typography>
                              </CardContent>
                            </Card>
                          </div>
                        )}
                      </Draggable>
                    )}
                    {provided.placeholder}
                  </AccordionSummary>
                  <AccordionDetails className={accordionClass.details}>
                    <ListContextProvider
                      value={{
                        resource: 'orders',
                        basePath: '/orders',
                        data: keyBy(route.orders, 'id'),
                        ids: route.orders.map(({ id }) => id),
                        currentSort: { field: 'id', order: 'ASC' },
                        selectedIds: [],
                      }}
                    >
                      <Datagrid 
                        rowClick="show"
                        classes={{ 
                          headerCell: classes.datagridCell, 
                          rowCell: classes.datagridCell 
                        }}
                      >
                        <TextField source="code" label="ID" />
                        <SelectField source="status" choices={orderStatus} />
                        <TextField source="address"  />
                        <TextField source="priority"  />
                        <NumberField source="amount"  />
                        <TextField source="weight" />
                        <TextField source="volume" />
                        <ReferenceField
                          source="client_retail_id"
                          reference="clients/retail"
                          label="Cliente retail"
                        >
                          <TextField source="name" />
                        </ReferenceField> 
                        <ShowButton label="" className={classes.actionButton} />
                        <EditButton label="" className={classes.actionButton} />
                      </Datagrid>
                    </ListContextProvider>
                  </AccordionDetails>
                </Accordion>
              )}
            </Droppable>
          );
        })}
    </div>
  );
}
