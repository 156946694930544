import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  content: {
    padding: theme.spacing(4),
  }
}));

function ImportDialog({ children, title = "ra-csv-importer.modal.title", open, onClose }) {
  const classes = useStyles();
  const handleClose = () => {
    onClose();
  };

  const closeButton = onClose 
    ? (
      <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    )
    : null

  return (
    <Dialog 
      open={open}
      onClose={handleClose} 
      fullWidth={true}
      maxWidth={'md'}
      aria-labelledby="simple-dialog-title" 
    >
      <DialogTitle id="simple-dialog-title">
        {title}
        {closeButton}
      </DialogTitle>
      <DialogContent className={classes.content}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ImportDialog;