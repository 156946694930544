import * as React from "react";
import Typography from '@material-ui/core/Typography';
import {AppBar} from 'react-admin';
import {default as LocaleSwitcher} from './LocaleSwitcher';
import CustomUserMenu from './CustomUserMenu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    title: {
        flexGrow: 1,
    },
    margin: {
        margin: "20px",
    }
});

function CustomAppBar(props){
    const classes = useStyles(); 
    return (
    <AppBar {...props} userMenu={<CustomUserMenu />}>
            <Typography  className={classes.title}
                variant="h6"
                id="react-admin-title" 
            />
            <Typography  className={classes.margin}
                variant="subtitle1"
            > 
            v1.0
            </Typography>
            <LocaleSwitcher/>
    </AppBar>
);
};

export default CustomAppBar;