import React, { useState } from 'react';
import {
  TextInput,
  SimpleForm,
  required,
  maxLength,
  minLength,
  email,
  useUpdate,
  useNotify,
} from 'react-admin';
import { Card } from '@material-ui/core';

const ProfileEdit = ({ staticContext, ...props }) => {
  const userInfo = JSON.parse(sessionStorage.getItem('USER_INFO'));
  delete userInfo.id;
  
  const [state, setState] = useState(userInfo);
  const [update, { loading }] = useUpdate('users');
  const notify = useNotify();

  const validate = {
    firstName: [maxLength(128), required()],
    lastName: [maxLength(128), required()],
    email: [maxLength(128), required(), email()],
    phone: [maxLength(16), required()],
    dni: [maxLength(32), required()],
    address: [maxLength(128), required()],
    password: [maxLength(16), minLength(8), required()],
    role_id: [required()],
  };

  const handleSubmit = (values) => {
    const userInfo = JSON.parse(sessionStorage.getItem('USER_INFO'));
    update(
      { payload: { data: values, id: userInfo.id } },
      {
        onSuccess: ({ data }) => {
          sessionStorage.setItem('USER_INFO', JSON.stringify(data));
          notify('resources.users.message.success', 'info');
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error');
        },
      }
    );
  };

  return (
    <Card>
      <SimpleForm
        record={state}
        resource="users"
        save={handleSubmit}
        saving={loading}
        redirect={false}
      >
        <TextInput source="firstName" validate={validate.firstName} />
        <TextInput source="lastName" validate={validate.lastName} />
        <TextInput source="email" validate={validate.email} />
        <TextInput source="phone" validate={validate.phone} />
        <TextInput source="dni" validate={validate.dni} />
        <TextInput source="address" validate={validate.address} />
      </SimpleForm>
    </Card>
  );
};

export default ProfileEdit;
