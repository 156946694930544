import React from "react";
import { TextField, BooleanField } from "react-admin";
import { ImportList } from "../../modules/ra-csv-importer";

function RegionImportList(props) {
  return (
    <ImportList {...props}>
      <TextField source="code" />
      <TextField source="name" />
    </ImportList>
  );
}

export default RegionImportList;
