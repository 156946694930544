import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
  maxLength,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  TopToolbar,
  Toolbar,
  ListButton,
  SaveButton,
  DeleteButton,
  ShowButton,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

function RoleTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.roles.message.role')} {`${record.label}`}</span>;
}

const validate = {
  label: [maxLength(32), required()],
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  hide: {
    RaSimpleFormIterator: 'none',
  },
});

const RoleEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath}  icon={<ChevronLeft />} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const RoleEditToolbar = (props) => {
  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.pristine}
        redirect="show"
        submitOnEnter={true}
      />
      {!props.record.standard && <DeleteButton />}
    </Toolbar>
  );
};

export function RoleEdit(props) {
  const classes = useStyles();
  return (
    <Edit title={<RoleTitle />} actions={<RoleEditActions />} {...props}>
      <SimpleForm 
        toolbar={<RoleEditToolbar />}
        variant="standard"
      >
        <TextInput source="label"  validate={validate.label} />
        <ArrayInput source="permissions" >
          <SimpleFormIterator disableRemove disableAdd>
            <FormDataConsumer>
              {({ getSource, scopedFormData }) => {
                return (
                  <TextInput
                    key={getSource('name')}
                    disabled
                    source={getSource('name')}
                  />
                );
              }}
            </FormDataConsumer>
            <ArrayInput
              source="actions"
              formClassName={classes.inlineBlock}
            >
              <SimpleFormIterator disableRemove disableAdd>
                <FormDataConsumer>
                  {({ getSource, scopedFormData }) => {
                    return (
                      <BooleanInput
                        key={getSource('id')}
                        source={getSource('allowed')}
                        label={scopedFormData.actionLabel}
                        formClassName={classes.inlineBlock}
                      />
                    );
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
}

export default RoleEdit;
