import React, { useState, useEffect } from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDataProvider, useRefresh, useTranslate } from "react-admin";
import { useImportContext } from "./ImportContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  navContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(3),
  },
  button: {
    marginRight: theme.spacing(2),
  },
  spinner: {
    marginLeft: theme.spacing(1),
    color: "#ffffff",
  },
}));

function ImportWizard({
  resource = "",
  UploadComponent = <></>,
  ReviewComponent = <></>,
  steps: customSteps,
  disableNextStep: customDisableNextStep,
  disableImport: customDisableImport,
  renderStepComponent: customRenderStepComponent,
  onImport = () => {},
}) {
  const translate = useTranslate();
  const [state, dispatch] = useImportContext();
  const dataProvider = useDataProvider();
  const [activeStep, setActiveStep] = useState(0);
  const [isImporting, setIsImporting] = useState(false);
  const refresh = useRefresh();
  const classes = useStyles();
  const { recordsToImport } = state;
  const steps = customSteps || [
    "ra-csv-importer.step.one",
    "ra-csv-importer.step.two",
  ];

  useEffect(() => {
    dispatch({ type: "set_resource", resource });
  }, []);

  useEffect(() => {
    if (isImporting) {
      createRecords()
        .catch((error) => {
          alert("Úps ocurrio un error");
        })
        .finally(() => {
          setIsImporting(false);
          refresh();
          onImport();
        });
    }
  }, [isImporting]);

  const createRecords = () =>
    Promise.all(
      recordsToImport.map((record) =>
        dataProvider.create(resource, {
          id: record.id,
          data: record,
        })
      )
    );

  const handleNextStep = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setActiveStep((prevStep) => (prevStep > 0 ? prevStep - 1 : 0));
  };

  const defaultDisableNextStep = (stepIndex) => recordsToImport.length === 0;

  const defaultDisableImport = () =>
    isImporting || recordsToImport.length === 0;

  const disableNextStep = customDisableNextStep || defaultDisableNextStep;

  const disableImport = customDisableImport || defaultDisableImport;

  const handleImportData = () => setIsImporting(true);

  const renderNavigation = () => (
    <div className={classes.navContainer}>
      <Button
        className={classes.button}
        variant="contained"
        disabled={activeStep === 0}
        onClick={handlePrevStep}
      >
        {translate("ra-csv-importer.button.back")}
      </Button>
      {activeStep < steps.length - 1 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={disableNextStep(activeStep, state)}
          onClick={handleNextStep}
        >
          {translate("ra-csv-importer.button.next")}
        </Button>
      )}
      {activeStep === steps.length - 1 && (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          disabled={disableImport(state)}
          onClick={handleImportData}
        >
          {translate("ra-csv-importer.button.import")}
          {isImporting && (
            <CircularProgress className={classes.spinner} size={20} />
          )}
        </Button>
      )}
    </div>
  );

  const renderStepsTitle = () =>
    steps.map((title) => (
      <Step key={title}>
        <StepLabel>{translate(title)}</StepLabel>
      </Step>
    ));

  const defaultRenderStepComponent = (stepIndex = 0) => {
    switch (stepIndex) {
      case 0:
        return UploadComponent;
      case 1:
        return ReviewComponent;
    }
  };

  const renderStepComponent =
    customRenderStepComponent || defaultRenderStepComponent;

  return (
    <>
      <div>
        <Stepper activeStep={activeStep} alternativeLabel>
          {renderStepsTitle()}
        </Stepper>
      </div>
      <div>
        <div>{renderStepComponent(activeStep)}</div>
        {renderNavigation()}
      </div>
    </>
  );
}

export default ImportWizard;
