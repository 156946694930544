import React, { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  downloadCSV,
  useTranslate,
} from 'react-admin';
import RouteReportExportPDF from './RouteReportExportPDF';
import useTranslateRouteStatus from '../../route/useTranslateRouteStatus';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';

function RouteReportActions(props) {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  const routeStatus = useTranslateRouteStatus();
  const translate = useTranslate();

  const mapStatus = {};
  routeStatus.forEach((status) => {
    mapStatus[status.id] = status.name;
  });

  const fieldsMap = {
    code: translate('resources.routes.fields.code'),
    created: translate('resources.routes.status.created'),
    assigned: translate('resources.routes.status.assigned'),
    accepted: translate('resources.routes.status.accepted'),
    inTransit: translate('resources.routes.status.inTransit'),
    finished: translate('resources.routes.status.finished'),
    vehicle: translate('resources.routes.fields.vehicle_id'),
  };

  const exporter = (records) => {
    const recordsForExport = records.map((record) => {
      const csvData = {
        [fieldsMap.code]: record.code,
        [fieldsMap.created]: record.events.CREATE
          ? format(new Date(record.events.CREATE), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.assigned]: record.events.TRANSPORT_ASSIGNMENT
          ? format(new Date(record.events.TRANSPORT_ASSIGNMENT), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.accepted]: record.events.DRIVER_CONFIRMATION
          ? format(new Date(record.events.DRIVER_CONFIRMATION), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.inTransit]: record.events.LOAD_TRANSPORT
          ? format(new Date(record.events.LOAD_TRANSPORT), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.finished]: record.events.DELIVER_LAST_ORDER
          ? format(new Date(record.events.DELIVER_LAST_ORDER), 'DD/MM/YYYY')
          : '-',
        [fieldsMap.vehicle]: record.vehicle.plate,
      };
      return csvData;
    });
    jsonExport(
      recordsForExport,
      {
        headers: [],
      },
      (err, csv) => {
        downloadCSV(csv, 'waybills_report'); // download as 'waybills_report.csv`
      }
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        exporter={exporter}
      />
      <RouteReportExportPDF
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
}

export default RouteReportActions;
