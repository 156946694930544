import * as React from 'react';
import { DateField, useTranslate } from 'react-admin';
import { Typography, Card, CardContent, Box } from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { makeStyles } from '@material-ui/core/styles';

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const dateOptions = {
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

export function RoleAside({ record, basePath }) {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
    </div>
  );
}

const EventList = ({ record }) => {
const translate = useTranslate();
  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>
            {translate('resources.roles.message.details')}
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>{translate('resources.roles.message.created')} </Typography>
                    <DateField
                      record={record}
                      source="createdAt"
                      options={dateOptions}
                      showTime
                    />
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AccessTimeIcon fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography> {translate('resources.roles.message.update')}</Typography>
                    <DateField
                      record={record}
                      source="updatedAt"
                      options={dateOptions}
                      showTime
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default RoleAside;
