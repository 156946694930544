import { useReducer } from "react";
import { useGoogleMapsContext } from "../contexts/GoogleMapsContextProvider";
import uniq from "lodash/uniq";
import concat from "lodash/concat";
import without from "lodash/without";

const geocodingReducer = (state, action) => {
  switch (action.type) {
    case "start_loading": {
      return {
        ...state,
        loading: true,
      };
    }
    case "end_loading": {
      return {
        ...state,
        loading: false,
      };
    }
    case "set_addresses": {
      return {
        ...state,
        addresses: action.addresses,
        total: action.addresses.length,
      };
    }
    case "geocode": {
      const { addresses, geoAddresses } = state;
      const { geoAddress } = action;
      return {
        ...state,
        geoAddresses: concat(geoAddresses, geoAddress),
        addresses: without(addresses, geoAddress.address),
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

const defaultState = {
  loading: false,
  addresses: [],
  total: 0,
  geoAddresses: [],
};

const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY || "CL";

function useGoogleGeocoding(country) {
  const { isLoaded, error, google } = useGoogleMapsContext();
  const [state, dispatch] = useReducer(geocodingReducer, defaultState);

  const setAddressesToGeocode = (addresses = []) => {
    dispatch({ type: "set_addresses", addresses: uniq(addresses) });
  };

  const geocode = () => {
    if (isLoaded && google && arrayHasElements(state.addresses)) {
      const address = state.addresses[0];
      const urlParams = {
        address,
        componentRestrictions: {
          country: country || DEFAULT_COUNTRY,
        },
        //language
      };

      dispatch({ type: "start_loading" });
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(urlParams, (results, status) => {
        setTimeout(() => {
          if (status == "OK") {
            const location = results[0].geometry.location;
            const geoAddress = {
              address,
              lat: location.lat(),
              lng: location.lng(),
            };
            dispatch({ type: "geocode", geoAddress });
          } else {
            const geoAddress = { address, geo: null };
            dispatch({ type: "geocode", geoAddress });
          }
        }, 300);
      });
    } else {
      dispatch({ type: "end_loading" });
    }
  };

  const arrayHasElements = (array) =>
    array instanceof Array && array.length > 0;

  return {
    isGeocoding: state.loading,
    geocodingData: state.geoAddresses,
    geocodingTotal: state.total,
    setAddressesToGeocode,
    geocode,
  };
}

export default useGoogleGeocoding;
