export default {
    name: 'Rol |||| Roles',
    label: 'Roles',
    fields: {
        label: 'Nombre',
        permissions: 'Permisos', 
        name: 'Entidad',
        actions: 'Acciones',
    },
    message: {
        created: 'Creado',
        update: 'Última Actualización', 
        details: 'Detalles',    
        role: 'Rol:'               
    }
}