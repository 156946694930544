import React, { cloneElement } from 'react';
import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  downloadCSV,
  useTranslate
} from 'react-admin';
import WaybillReportExportPDF from './WaybillReportExportPDF';
import useTranslateWaybillStatus from '../../waybills/useTranslateWaybillStatus';
import jsonExport from 'jsonexport/dist';
import { format } from 'date-fns';

function WaybillReportActions(props) {
  const { className, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    showFilter,
    total,
  } = useListContext();
  const waybillStatus = useTranslateWaybillStatus();
  const translate = useTranslate();

  const mapStatus = {};
  waybillStatus.forEach((status) => {
    mapStatus[status.id] = status.name;
  });

  const fieldsMap = {
    code: translate('resources.waybills.fields.code'),
    createdAt: translate('resources.waybills.fields.createdAt'),
    orders: translate('resources.waybills.fields.orders'),
    volume: translate('resources.waybills.fields.volume'),
    weight: translate('resources.waybills.fields.weight'),
    status: translate('resources.waybills.fields.status'),
  };
  

  const exporter = (records) => {
    
    const recordsForExport = records.map((record) => {
      const csvData = {
        [fieldsMap.code]: record.code,
        [fieldsMap.createdAt]: format(new Date(record.createdAt), 'DD/MM/YYYY'),
        [fieldsMap.orders]: record.orders,
        [fieldsMap.volume]: record.volume,
        [fieldsMap.weight]: record.weight,
        [fieldsMap.status]: mapStatus[record.status],
      };
      return csvData;
    });
    jsonExport(
      recordsForExport,
      {
        headers: [],
      },
      (err, csv) => {
        downloadCSV(csv, 'waybills_report'); // download as 'waybills_report.csv`
      }
    );
  };
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
        exporter={exporter}
      />
      <WaybillReportExportPDF
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
    </TopToolbar>
  );
}

export default WaybillReportActions;
