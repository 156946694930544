export default {
    name: 'Región |||| Regiones',
    label: 'Regiones',
    fields: {
        code: 'Código',
        name: 'Nombre',
        sub_areas: 'Comunas',
    },
    message: {
        import: 'Importar regiones',
        region: 'Región:'
    },
}