import React from 'react';
import {required} from 'react-admin';
import { ImportForm, SelectImport, ReferenceSelectImport } from '../../modules/ra-csv-importer';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    selectImport: {
      flexBasis: '30%'
    },
    selectImportQuarter: {
      flexBasis: '22.5%'
    },
  })

function UploadOrderForm(props) {
    const classes = useStyles();
    return (
      <ImportForm>
         <SelectImport 
          source="code" 
          variant="standard" 
          classes={classes.selectImport}
          validate={[required()]} 
        />
        <SelectImport 
          source="firstname" 
          variant="standard" 
          classes={classes.selectImport} 
          validate={[required()]} 
        />
        <SelectImport 
          source="lastname" 
          variant="standard" 
          classes={classes.selectImport} 
          validate={[required()]} 
        />
        {/*
        <SelectImport 
          source="address" 
          label="Dirección completa" 
          variant="standard" 
          classes={classes.selectImport} 
        /> 
        */}
        <SelectImport 
          source="phone" 
          variant="standard" 
          classes={classes.selectImport} 
          validate={[required()]} 
        />
        <SelectImport 
          source="email" 
          variant="standard" 
          classes={classes.selectImport} 
          validate={[required()]} 
        />
        <ReferenceSelectImport 
          source="retail" 
          reference="clients/retail" 
          referenceSource="client_retail_id" 
          referenceTarget="name" 
          referenceValue="id" 
          variant="standard" 
          classes={classes.selectImport}
          validate={[required()]} 
        />
         <SelectImport 
          source="street" 
          variant="standard" 
          classes={classes.selectImportQuarter} 
          validate={[required()]} 
        />
        <SelectImport 
          source="streetNumber" 
          variant="standard" 
          classes={classes.selectImportQuarter} 
          validate={[required()]} 
        />
        <ReferenceSelectImport 
          source="commune" 
          reference="administrative_areas/level_2" 
          referenceSource="administrative_area_level_2_id" 
          referenceTarget="name" 
          referenceValue="id" 
          variant="standard" 
          classes={classes.selectImportQuarter}
          validate={[required()]} 
          perPage={360}
        />
        <ReferenceSelectImport 
          source="region" 
          reference="administrative_areas/level_1" 
          referenceSource="administrative_area_level_1_id" 
          referenceTarget="name" 
          referenceValue="id" 
          variant="standard" 
          classes={classes.selectImportQuarter}
          validate={[required()]} 
        />
        <SelectImport 
          source="amount" 
          variant="standard" 
          classes={classes.selectImport} 
        />
        <SelectImport 
          source="priority"  
          variant="standard" 
          classes={classes.selectImport} 
        />
        <SelectImport 
          source="weight"
          variant="standard"
          classes={classes.selectImport}
        />
        <selectImport
          source="volume"
          variant="standard"
          classes={classes.selectImport}
        />
      </ImportForm>
    )
  }

  export default UploadOrderForm;