import React from 'react';
import { Card, CardContent, Box, Grid, Typography } from '@material-ui/core';
import {
  useTranslate,
  FormWithRedirect,
  Labeled,
  DateField,
  TextField,
  NumberField,
  SelectField,
} from 'react-admin';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import RouteList from './RouteList';
import Orders from './Orders';
import { Accordion, AccordionSummary, AccordionDetails  } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useTranslateWaybillStatus from './useTranslateWaybillStatus';

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const useStyles = makeStyles({
  root: { alignItems: 'flex-start' },
  accordeonDetails: { padding: 0 }
});

const WaybillForm = (props) => {
  const classes = useStyles();
  const translate = useTranslate();
  const waybillStatus = useTranslateWaybillStatus();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => {
        const duplicatedOrdersString = formProps.record.duplicated_orders
          .toString()
          .replaceAll(',', ', ');
        const observations = formProps.record.duplicated_orders.length > 0 
          ? `${translate('resources.waybills.message.duplicateOrders')} ${duplicatedOrdersString}.`
          : '';
        formProps.record = {
          ...formProps.record,
          observations
        }
        return (
        <Box className={classes.mainBox}>
          <Card>
            <CardContent>
              <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.waybills.message.landing')}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled
                    label={translate('resources.waybills.fields.createdAt')}
                  >
                    <DateField
                      source="createdAt"
                      record={formProps.record}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled
                    label={translate('resources.waybills.fields.code')}
                  >
                    <TextField source="code" record={formProps.record} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled label={translate('resources.waybills.fields.status')}>
                    <SelectField
                      source="status"
                      record={formProps.record}
                      choices={waybillStatus}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled
                    label={translate(
                      'resources.waybills.fields.from_filename'
                    )}
                  >
                    <TextField
                      source="from_filename"
                      record={formProps.record}
                    />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled
                    label={translate('resources.waybills.fields.weight')}
                  >
                    <NumberField source="weight" record={formProps.record} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <Labeled
                    label={translate('resources.waybills.fields.volume')}
                  >
                    <NumberField source="volume" record={formProps.record} />
                  </Labeled>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Labeled
                      label={translate('resources.waybills.fields.observations')}
                    >
                      <TextField
                        source="observations"
                        record={formProps.record}
                      />
                    </Labeled>
                  </Grid>
              </Grid>
              <Spacer />
              {formProps.record.routes.length > 0 && (
                <Box>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.waybills.message.routes')}
                  </Typography>
                  <RouteList routes={formProps.record.routes} />
                </Box>
              )}

              <Spacer />
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" gutterBottom>
                    {translate('resources.waybills.message.order')} {'  '}
                    ({formProps.record.orders.length})
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordeonDetails}>
                  {formProps.record.orders.length > 0 && (
                    <Orders record={formProps.record} />
                  )}
                </AccordionDetails> 
              </Accordion> 
            </CardContent>
          </Card>
        </Box>
      )
      }}
    />
  );
};

export default WaybillForm;
