export default {
    name: 'Cliente retail |||| Clientes retail',
    label: 'Clientes retail',
    fields: {
        dni: 'RUT',
        name: 'Nombre',
        email: 'Correo electrónico',
        phone: 'Teléfono',
    },
    message: {
        import: 'Importar clientes retail',
        retail: 'Cliente retail:',
    },
}