import React from 'react';
import WaybillActions from './WaybillActions';
import WaybillFilters from './WaybillFilters';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  SelectField,
  Pagination,
  ShowButton,
  DeleteButton,
} from 'react-admin';
import useTranslateWaybillStatus from './useTranslateWaybillStatus';
import { makeStyles } from "@material-ui/core/styles";
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  content: {
    overflowX: 'hidden',
  }
})  

function WaybillsPagination(props){
  return(
    <Pagination 
      rowsPerPageOptions={[100, 200, 500]} 
      {...props} 
    />
  )
} 

function WaybillList(props) {
  const classes = useStyles();
  const waybillStatus = useTranslateWaybillStatus();

  const toDateString = (date) => {
    const year = date.getFullYear().toString().padStart(4, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const today = new Date();
  const todayDate = toDateString(today)
  const todayInitTime = new Date(`${todayDate} 00:00:00`);
  const todayFinalTime = new Date(`${todayDate} 23:59:59`);

  return (
    <List 
      {...props} 
      classes={classes}
      actions={<WaybillActions />}
      filters={<WaybillFilters />}
      filterDefaultValues={{ 
        createdAt_gte: todayInitTime, 
        createdAt_lte: todayFinalTime 
      }}
    >
      <Datagrid rowClick='show'>
        <DateField source="createdAt"  />
        <SelectField source="status" choices={waybillStatus} />
        <TextField source="code"  />
        <TextField source="from_filename" />
        <ShowButton label="" />
        <CustomButtonDelete  />
      </Datagrid>
    </List>
  );
}
export default WaybillList;