export default {
    name: 'Delivery |||| Deliveries ',
    label: 'Deliveries',
    fields: {
        photo: 'Photo',
        observations: 'Observations',
        order_id: 'Order ID',
        id: "ID",
        status: 'Status',
        createdAt: 'Date'
    },
    status: {
        accepted: 'Accepted',
        rejected: 'Rejected',
    },
    message: {
        delivery: 'Delivery',
    }
}
