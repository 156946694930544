export default  {
    name: 'Driver |||| Drivers',
    label: 'Drivers',
    fields: {
        transport_id: 'Transport company',
        firstName: 'Firtname',
        lastName: 'lastname',
        dni: 'RUT',
        email: 'Email',
        phone: 'Phone',
        photo: 'Photo',
        address: 'Address',
        licenseCode: "Driver's license code", 
        licenseType: "Driver's license type", 
        licenseExpire: "Driver's license expiration", 
    },
    message:{ 
        driver: 'Driver:'
    }
}