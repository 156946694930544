import { useTranslate } from 'react-admin';

function useTranslateDeliveryStatus() {
  const translate = useTranslate();
  return [
    { id: 'ACCEPTED', name: translate('resources.deliveries.status.accepted') },
    { id: 'REJECTED', name: translate('resources.deliveries.status.rejected') },
  ]
}

export default useTranslateDeliveryStatus;