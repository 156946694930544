import { useTranslate } from 'react-admin';

function useTranslateWaybillStatus() {
  const translate = useTranslate();
  return [
    { id: 'INITIAL', name: translate('resources.waybills.status.initial') },
    { id: 'RECEIVED', name: translate('resources.waybills.status.received') },
    { id: 'INVALID', name: translate('resources.waybills.status.invalid') },
    { id: 'IN_PROCESS', name: translate('resources.waybills.status.in_process') },
    { id: 'FINISHED', name: translate('resources.waybills.status.finished') },
  ]
}

export default useTranslateWaybillStatus;