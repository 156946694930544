import React, { useEffect } from "react";
import { useDataProvider, SelectInput } from "react-admin";
import { useImportContext } from "./ImportContext";

function ReferenceSelectImport({
  source,
  reference,
  referenceSource,
  referenceTarget,
  referenceValue,
  perPage = 100,
  label,
  validate = [],
  allowEmpty = true,
  classes = {},
  variant = "filled",
  ...props
}) {
  const dataProvider = useDataProvider();
  const [state, dispatch] = useImportContext();
  const { resource, fields, records, selectedFields } = state;

  useEffect(() => {
    const params = {
      pagination: { page: 1, perPage },
      sort: { field: "id", order: "ASC" },
      filter: {},
    };
    dataProvider
      .getList(reference, params)
      .then(({ data }) => {
        const referenceMapping = data.reduce(
          (mapping, record) => ({
            ...mapping,
            [record[referenceTarget].toUpperCase()]: record[referenceValue],
          }),
          {}
        );
        dispatch({
          type: "set_reference_mapping",
          source,
          referenceSource,
          mappings: referenceMapping,
        });
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <SelectInput
      {...props}
      className={classes}
      source={source}
      label={label}
      resource={resource}
      validate={validate}
      variant={variant}
      allowEmpty={allowEmpty}
      choices={fields.map((field) => ({
        id: field,
        name: field,
        disabled: Object.values(selectedFields).includes(field) ? true : false,
      }))}
    />
  );
}

export default ReferenceSelectImport;
