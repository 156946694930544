import React, { useReducer, useContext } from 'react';

const ImportStateContext = React.createContext();
const ImportDispatchContext = React.createContext();

const defaultState = { 
  resource: '',
  file: { 
    content: '',
    name: '',
  }, 
  formValues: {},
  fields: [], 
  records: [], 
  recordsToReview: [], 
  recordsToImport: [], 
  mappings: {},
  customData: {}
};

function importReducer(state, action) {
  const mapRecordWithSelectedFields = (record = {}, associations = {}, index) => {
    const properties = Object.keys(associations);
    return properties.reduce((newRecord, prop) => {
      const field = associations[prop];
      const propValue = record[field];
      newRecord[prop] = propValue;
      if (state.mappings.hasOwnProperty(prop)) {
        const { referenceSource } = state.mappings[prop];
        const propValueUpper = propValue ? propValue.toUpperCase() : '';
        newRecord[referenceSource] = state.mappings[prop][propValueUpper];
      }
      return newRecord
    }, { 
      id: Math.random().toString(36).substring(2, 15)
    });
  }

  switch(action.type) {
    case 'set_resource': {
      return {
        ...state,
        resource: action.resource
      }
    }
    case 'save_file_result': {
      return {
        ...state,
        file: {
          content: action.content,
          name: action.name
        }
      }
    }
    case 'set_form_values': {
      const recordsToImport = state.records.map((record, index) => {
        return mapRecordWithSelectedFields(record, action.values, index);
      });
      return {
        ...state, 
        formValues: action.values, 
        recordsToReview: recordsToImport,
        recordsToImport: recordsToImport
      }
    }
    case 'set_imported_data': {
      return {
        ...state, 
        fields: action.fields || [], 
        selectedFields: action.selectedFields || {},
        records: action.records || [] 
      }
    }
    case 'set_records_to_import': {
      return {
        ...state,
        recordsToImport: action.records
      }
    }
    case 'set_records_to_review': {
      return {
        ...state,
        recordsToReview: action.records
      }
    }
    case 'set_reference_mapping': {
      return {
        ...state,
        mappings: {
          ...state.mappings,
          [action.source]: {
            referenceSource: action.referenceSource,
            ...action.mappings,
          }
        }
      }
    }
    case 'set_custom_data': {
      return {
        ...state,
        customData: {
          ...state.customData,
          ...action.customData
        }
      }
    }
    case 'reset': {
      return defaultState;
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ImportProvider({ children }) {
  const [state, dispatch] = useReducer(importReducer, defaultState);

  return (
    <ImportStateContext.Provider value={state}>
      <ImportDispatchContext.Provider value={dispatch}>
        {children}
      </ImportDispatchContext.Provider>
    </ImportStateContext.Provider>
  );
}

function useImportContext() {
  const stateContext = useContext(ImportStateContext);
  const dispatchContext = useContext(ImportDispatchContext);

  if (stateContext === undefined || dispatchContext === undefined) {
    throw new Error('useImportContext must be used within a ImportProvider');
  }

  return [stateContext, dispatchContext];
}

export {ImportProvider, useImportContext};