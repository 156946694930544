import React, { cloneElement } from "react";
import {
  useListContext,
  TopToolbar,
  ExportButton,
  sanitizeListRestProps,
  useTranslate,
} from "react-admin";
import { ImportButton } from "../../modules/ra-csv-importer";
import WaybillImportForm from "./WaybillImportForm";
import WaybillImportGeocoding from "./WaybillImportGeocoding";
import WaybillImportPreviewList from "./WaybillImportPreviewList";

function WaybillActions(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const translate = useTranslate();

  const renderImportOrdersStep = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <WaybillImportForm />;
      case 1:
        return <WaybillImportGeocoding />;
      case 2:
        return <WaybillImportPreviewList />;
    }
  };

  const disableImportNextStep = (stepIndex, state) => {
    const { formValues, recordsToImport, customData } = state;
    switch (stepIndex) {
      case 0:
        return (
          recordsToImport.length === 0 ||
          !formValues.waybill_code ||
          !formValues.from_filename ||
          !formValues.code ||
          !formValues.firstname ||
          !formValues.phone ||
          !formValues.street
        );
      case 1:
        return !customData.hasProcessedWaybill;
      default:
        return true;
    }
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <ImportButton
        resource={resource}
        buttonLabel={translate("ra_csv_importer.action.import")}
        dialogTitle={translate("resources.waybills.message.importLoadGuide")}
        renderStepComponent={renderImportOrdersStep}
        steps={[
          "resources.waybills.import_step.one",
          "resources.waybills.import_step.two",
          "resources.waybills.import_step.three",
        ]}
        disableNextStep={disableImportNextStep}
      />
    </TopToolbar>
  );
}

export default WaybillActions;
