import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  SelectField,
  NumberField,
  DateField,
  Pagination,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import useTranslateWaybillStatus from '../../waybills/useTranslateWaybillStatus';
import WaybillReportFilter from './WaybillReportFilter';
import WaybillReportActions from './WaybillReportActions'

function WaybillPagination(props) {
  return <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />;
}

function WaybillReport(props) {
  const waybillStatus = useTranslateWaybillStatus();

  const dateOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  };

  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => record.code}
      secondaryText={(record) => record.createdAt}
    />
  );
  const datagrid = (
    <Datagrid rowClick="show">
      <TextField source="code" label="resources.waybills.fields.code" />
      <DateField
        source="createdAt"
        label="resources.waybills.fields.createdAt"
        options={dateOptions}
        locales="es-VE"
      />
      <NumberField source="orders" label="resources.waybills.fields.orders" />
      <NumberField source="volume" label="resources.waybills.fields.volume" />
      <NumberField source="weight" label="resources.waybills.fields.weight" />
      <SelectField
        source="status"
        choices={waybillStatus}
        label="resources.waybills.fields.status"
      />
    </Datagrid>
  );
  return (
    <List
      {...props}
      bulkActionButtons={false}
      filters={<WaybillReportFilter />}
      pagination={<WaybillPagination />}
      perPage={25}
      sort={{ field: 'code', order: 'ASC' }}
      actions={<WaybillReportActions/>}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default WaybillReport;
