import React, { useEffect, useCallback, useState } from 'react';
import { useTranslate, useDataProvider, Button } from 'react-admin';
import GetAppIcon from '@material-ui/icons/GetApp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';

const generatePDF = (records, columns, title) => {
  const doc = new jsPDF();

  const tableRows = [];
  records.forEach((record) => {
    const recordData = [
      record.code,
      record.events.GEOCODE_SUCCESSED
        ? format(new Date(record.events.GEOCODE_SUCCESSED), 'DD/MM/YYYY')
        : '-',
      record.events.ROUTE_PLANNING
        ? format(new Date(record.events.ROUTE_PLANNING), 'DD/MM/YYYY')
        : '-',
      record.events.TRANSPORT_ASSIGNMENT
        ? format(new Date(record.events.TRANSPORT_ASSIGNMENT), 'DD/MM/YYYY')
        : '-',
      record.events.START_ROUTE
        ? format(new Date(record.events.START_ROUTE), 'DD/MM/YYYY')
        : '-',
      record.events.DELIVER
        ? format(new Date(record.events.DELIVER), 'DD/MM/YYYY')
        : '-',
      record.vehiclePlate,
    ];
    tableRows.push(recordData);
  });
  const imageData = '/chitrack-background.png';
  doc.addImage(imageData, 'PNG', 15, 20, 90, 20);
  doc.text(title, 15, 50);
  doc.autoTable(columns, tableRows, { startY: 60 });
  doc.save(`orders_report.pdf`);
};

function OrderReportExportPDF(props) {
  const [records, setRecords] = useState([]);
  const { disabled, resource, sort, filterValues } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  const fetchData = useCallback(async () => {
    const { data } = await dataProvider.getList(resource, {
      filter: { ...filterValues },
      sort: { ...sort },
      pagination: { page: 1, perPage: 1000 },
    });

    setRecords(data);
  });

  const columns = [
    translate('resources.orders.fields.code'),
    translate('resources.orders.status.received'),
    translate('resources.orders.status.planned_in_route'),
    translate('resources.orders.status.assigned_in_transport'),
    translate('resources.orders.status.in_transit'),
    translate('resources.orders.status.accepted_by_client'),
    translate('resources.routes.fields.vehicle_id'),
  ];

  return (
    <Button
      onClick={() => {
        generatePDF(
          records,
          columns,
          translate('resources.reports.order.label')
        );
      }}
      label="resources.reports.exportPDF"
      disabled={disabled}
    >
      <GetAppIcon />
    </Button>
  );
}

export default OrderReportExportPDF;
