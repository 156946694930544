import React from 'react';
import { Card, CardHeader, CardContent, Box, Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';
import Map from '../Map';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  title: {
      fontSize: '1rem',
  }
});

function HeatMap({ data }) {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Card>
      <CardHeader
        classes={{
          title: classes.title
      }}
        title={translate('dashboard.heatMap.title')}
      />
      <CardContent>
        {data && data.length > 0 ? (
          <Box component="div" height="40vh" width="100%">
            <Map data={data} heatMap={true} />
          </Box>
        ): <Typography>{translate('dashboard.withoutData')} </Typography>}
      </CardContent>
    </Card>
  );
}

export default HeatMap;
