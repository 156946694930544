export default {
  name: "Guía de carga |||| Guías de carga",
  label: "Guías de carga",
  fields: {
    code: "ID",
    firstname: "Nombres",
    lastname: "Apellidos",
    address: "Dirección",
    address_references: "Referencias de la dirección",
    phone: "Teléfono",
    email: "Correo Electrónico",
    amount: "Monto",
    client_retail_id: "Cliente retail",
    retail: "Cliente retail",
    priority: "Prioridad",
    createdAt: "Fecha",
    from_filename: "Archivo de importación",
    geocoded: "Geocodificado",
    isImportable: "Recepcionado",
    waybill_code: "ID",
    street: "Dirección línea 1",
    streetNumber: "Dirección línea 2",
    addressHasAreas: "Dirección incluye comuna y región",
    commune: "Comuna",
    region: "Región",
    administrative_area_level_1_id: "Región",
    administrative_area_level_2_id: "Comuna",
    observations: "Observaciones",
    createdAt_gte: "Fecha inicio",
    createdAt_lte: "Fecha fin",
    weight: "Peso (kg)",
    volume: "Volumen (mts³)",
    status: "Estatus",
    distance: "Longitud del recorrido",
    duration: "Total duration",
    description: "Descripción",
    vehicleID: "Vehículo",
    warehouse: "Centro de distribución",
    warehouse_id: "Centro de distribución",
    orders: "Órdenes",
    valid: "Válida",
  },
  status: {
    initial: "Inicial",
    received: "Recepcionada",
    invalid: "Inválida",
    in_process: "En proceso",
    finished: "Finalizada",
    error: "Error",
    pending: "Pendiente",
  },
  message: {
    importTemplate: "Plantilla de importación",
    or: "ó",
    selectTemplate: "Selecciona una plantilla guardada",
    inputTemplateName: "Ingresa nombre de plantilla nueva",
    updateTemplateName: "Actualiza nombre de la plantilla",
    createdImportTemplate: "Plantilla de importación creada exitosamente",
    updatedImportTemplate: "Plantilla de importación actualizada exitosamente",
    number: "Guía de carga:",
    landing: "Guía de carga",
    order: "Órdenes sin asignar",
    orders: "Órdenes",
    routes: "Rutas",
    noReceived: "Órden no recepcionada",
    received: "Órden recepcionada",
    geocoded: "Dirección geocodificada",
    noGeocoded: "Dirección no geocodificada",
    shortWait: "Espere...",
    longWait: "Vaya por una taza de café ☕",
    gecodedAddress: "Geocodificando direcciones, por favor",
    gecodedAll: "Todas las órdenes ya han sido recepcionadas.",
    gecodedNothing: "Nada para geocodificar.",
    importLoadGuide: "Importar guía de carga",
    weightAndVolume: "Peso y volumen total",
    mts: "(mts³)",
    kg: "(kg)",
    distanceUnit: "(km)",
    durationUnit: "(min)",
    duplicateOrders: "Guía de carga inválida debido a ordenes duplicadas:",
    fleetAvailable: "Flota disponible",
    status: "Estatus",
    selected: "Seleccionado",
    createRoute: "Crear ruta",
    new: "Nueva",
    route: "Rutas",
    errorFinalizedRoute: "La ruta ha finalizado",
    errorExceededCapacity: "Capacidad excedida",
    back: "Atrás",
  },
  import_step: {
    one: "Cargar archivo",
    two: "Geocodificación",
    three: "Previsualizar datos",
  },
  optimize: {
    button: "Optimizar",
    buttonDescription: "Distribuir automáticamente las órdenes",
    modalTitle: "Optimizar rutas",
    modalSubtitle: "Rutas óptimas sugeridas",
    loading: "Optimizando...",
    noSolution: "No hay flota disponible para cubrir la carga seleccionada",
    noWarehouse: "No hay centros de distribución registrados",
    errorAddress: "Alguna ubicación no es accesible. Por favor, verifica las direcciones de entrega",
    wizard: {
      buttons: {
        back: "Anterior",
        next: "Siguiente",
        optimize: "Optimizar",
      },
      steps: {
        1: "Selección de almacén",
        2: "Selección de flota",
        3: "Confirmar",
      },
    },
  },
  assign: {
    button: "Asignar",
    buttonDescription: "Asignar a una ruta manualmente",
    modalTitle: "Asignar ruta",
  },
  button: {
    assign: "Asignar",
  },
};
