import React from "react";
import {
  Filter,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
} from "react-admin";
import { makeStyles } from "@material-ui/core";
import DependentReferenceInput from "../../components/DependentReferenceInput";
import DateWithCustomTimeInput from "../../components/DateWithCustomTimeInput";
import useTranslateOrderStatus from "./useTranslateOrderStatus";

const useStyles = makeStyles({
  filter: {
    marginTop: 0,
    marginLeft: "16px",

    "& .filter-field": {
      flexGrow: 1,
    },
    "& .MuiFormControl-root": {
      width: "100%",
    },
  },
});

function OrderFilter(props) {
  const classes = useStyles();
  const orderStatus = useTranslateOrderStatus();

  return (
    <Filter
      className={classes.filter}
      resource={props.resource}
      variant="standard"
      {...props}
    >
      <TextInput source="code" alwaysOn />
      <SelectInput source="status" choices={orderStatus} alwaysOn />
      <ReferenceInput
        source="administrative_area_level_1_id"
        reference="administrative_areas/level_1"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <DependentReferenceInput
        source="administrative_area_level_2_id"
        reference="administrative_areas/level_2"
        filterProp="parent_area_id"
        dependentSource="administrative_area_level_1_id"
        sort={{ field: "name", order: "ASC" }}
        filterToQuery={(searchText) => ({ name: searchText })}
        perPage={400}
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </DependentReferenceInput>
      <NumberInput source="priority" min={1} step={1} alwaysOn />
      <ReferenceInput
        source="client_retail_id"
        reference="clients/retail"
        alwaysOn
      >
        <AutocompleteInput optionText="name" optionValue="id" />
      </ReferenceInput>
    </Filter>
  );
}

export default OrderFilter;
