import React from 'react';
import {
  Edit,
  UrlField,
  TopToolbar,
  ListButton,
  TextField,
  Labeled,
  FormWithRedirect,
  EditButton,
  useTranslate,
} from 'react-admin';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { default as ColorField } from '../../components/ColorField';
import { default as TransportAside } from './TransportAside';

import {
  Card,
  CardContent,
  Box,
  Grid,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import Vehicles from './Vehicles';
import Drivers from './Drivers';

function TransportTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.transports.message.transportCompany')} {`${record.name}`}</span>;
}

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const TransportShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const useStyles = makeStyles({
  root: { alignItems: 'flex-start' },
});
const accordionStyles = makeStyles((theme) => ({
  root: { width: '100%' },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const TransportForm = (props) => {
  const translate = useTranslate();
  const classes = useStyles();
  const accordionClass = accordionStyles();
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Box className={classes.mainBox}>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                  <Typography variant="h6" gutterBottom>
                    <ColorField source="color" record={formProps.record} />
                    &nbsp;{formProps.record.name}&nbsp;
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.transports.fields.dni')}>
                        <TextField
                          source="dni"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.transports.fields.address')}>
                        <TextField source="address" record={formProps.record} />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.transports.fields.website')}>
                        <UrlField source="website" record={formProps.record} />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.transports.fields.phone')}>
                        <TextField source="phone" record={formProps.record} />
                      </Labeled>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label={translate('resources.transports.fields.callCenterPhone')}>
                        <TextField
                          source="callCenterPhone"
                          record={formProps.record}
                        />
                      </Labeled>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                  {translate('resources.transports.fields.contac')} 
                  </Typography>
                  <Box display="flex" flexDirection="column">
                    <Typography>
                      {formProps.record?.contactFirstName}{' '}
                      {formProps.record?.contactLastName}
                    </Typography>
                    <Typography
                      component={Link}
                      color="primary"
                      href={`mailto:${formProps.record?.contactEmail}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {formProps.record?.contactEmail}
                    </Typography>
                    <Typography>{formProps.record?.contactPhone}</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Spacer />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={accordionClass.heading}>
                  {translate('resources.transports.message.vehicles')} 
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={accordionClass.root}>
                    <Vehicles record={formProps.record} />
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={accordionClass.heading}>
                  {translate('resources.transports.message.drivers')} 
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box className={accordionClass.root}>
                    <Drivers record={formProps.record} />
                  </Box>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Box>
      )}
    />
  );
};

export function TransportShow(props) {
  const classes = useStyles();
  return (
    <Edit
      title={<TransportTitle />}
      actions={<TransportShowActions />}
      aside={<TransportAside />}
      classes={classes}
      component="div"
      {...props}
    >
      <TransportForm />
    </Edit>
  );
}

export default TransportShow;
