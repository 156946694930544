import React from 'react';
import {ReactComponent as Icon} from './chauffer.svg';
import { SvgIcon } from '@material-ui/core'

const DIcon = <SvgIcon component={Icon} viewBox="0 0 480 480"/>

function DriverIcon() {
  return (
    <SvgIcon component={Icon} viewBox="0 0 490 490" htmlColor="gray"/>
  );
}

export default DriverIcon;
