export default {
    name: 'Vehículo |||| Vehículos',
    label: 'Vehículos',
    fields: {
        transport_id: 'Empresa de transporte',
        plate: 'Placa',
        brand: 'Marca',
        driver_id: 'Conductor',
        model: 'Modelo',
        weightCapacity: 'Capacidad de peso (kg)',
        volumeCapacity: 'Capacidad de volumen (mts³)',
        weightAssigned: 'Peso asignado (Kg)',
        volumeAssigned: 'Volumen asignado (mts³)',
        year: 'Año',
        color: 'Color',
        type: 'Tipo',
        status: 'Estatus',
    },
    message: {
        bike: 'Bicicleta',
        motorBike: 'Moto',
        car: 'Automóvil',
        van: 'Furgón',
        truck: 'Camión',
        vehicle: 'Vehículo:',
        weightCapacity: 'El peso del vehículo no debe ser menor al peso asignado',
        volumeCapacity: 'El volumen del vehículo no debe ser menor al volumen asignado',
    },
    status: {
        assigned: 'Asignado',
        available: 'Disponible',
    },
    errors: {
        invalidWeightCapacity: 'Vehículo con capacidad de peso inválida, no puede ser menor al peso asignado',
        invalidVolumeCapacity: 'Vehículo con capacidad de volumen inválida, no puede ser menor al volumen asignado',
        driverAlreadyAssigned: 'Conductor ya está asignado a otro vehículo'
    }
}