import React from 'react';
import { useTranslate } from 'react-admin';

function WaybillTitle({ record }) {
  const translate = useTranslate();
  return (
    <span>
      {' '}
      {translate('resources.waybills.message.number')} {`${record.code}`}
    </span>
  );
}

export default WaybillTitle;