import * as React from "react";
import { useTranslate, useRedirect } from "react-admin";
import { Typography, Card, CardContent, Box } from "@material-ui/core";
import { AllInbox, MoveToInbox } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import Map from "../../components/Map";

const useAsideStyles = makeStyles((theme) => ({
  root: {
    width: 400,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

export function TransportAside({ record, basePath }) {
  const classes = useAsideStyles();
  return (
    <div className={classes.root}>
      {record && <EventList record={record} basePath={basePath} />}
    </div>
  );
}

const EventList = ({ record }) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const { orders, vehicle, warehouse } = record;
  let volumeAvailable = 0;
  let weightAvailable = 0;
  let markers = [];

  const convertOrderStatusInColor = (status = "", relocated = false) => {
    let color = relocated ? "#9e34eb" : "#F6CC1A";

    switch (status) {
      case 'REJECTED_BY_CLIENT':
        return '#F50C35';
      case 'ACCEPTED_BY_CLIENT':
        return '#51B86A';
      default:
        return relocated ? '#9e34eb' : '#F6CC1A';
    }
  };

  if (orders instanceof Array) {
    markers = orders
      .filter(
        ({ latitude, longitude }) =>
          typeof latitude === "number" && typeof longitude === "number"
      )
      .map(
        ({
          id,
          code,
          latitude,
          longitude,
          status,
          relocated,
          delivery_number,
        }) => ({
          lat: latitude,
          lng: longitude,
          color: convertOrderStatusInColor(status, relocated),
          label: delivery_number,
          title: `ID: ${code}`,
          onClick: () => redirect(`/orders/${id}/show`),
        })
      );

    markers = [
      {
        lat: warehouse.latitude,
        lng: warehouse.longitude,
        color: convertOrderStatusInColor(),
      },
      ...markers,
    ];
  }

  if (vehicle) {
    volumeAvailable = vehicle.volumeCapacity - vehicle.volumeAssigned;
    weightAvailable = vehicle.weightCapacity - vehicle.weightAssigned;
  }

  return (
    <>
      <Box m="0 0 1em 1em">
        <Card>
          <Box component="div" height="40vh" width="100%">
            <Map data={markers} route={true} />
          </Box>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {translate("resources.routes.message.capacity")}
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AllInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {vehicle && vehicle.volumeCapacity
                        ? vehicle.volumeCapacity
                        : 0}{" "}
                      {"  "}
                      {translate("resources.routes.message.mts")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <MoveToInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {vehicle && vehicle.weightCapacity
                        ? vehicle.weightCapacity
                        : 0}{" "}
                      {"  "}
                      {translate("resources.routes.message.kg")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              {translate("resources.routes.message.assigned")}
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AllInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {vehicle && vehicle.volumeAssigned
                        ? vehicle.volumeAssigned
                        : 0}{" "}
                      {"  "}
                      {translate("resources.routes.message.mts")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <MoveToInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {vehicle && vehicle.weightAssigned
                        ? vehicle.weightAssigned
                        : 0}{" "}
                      {"  "}
                      {translate("resources.routes.message.kg")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Typography variant="h6" gutterBottom>
              {translate("resources.routes.message.available")}
            </Typography>
            <Box display="flex">
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <AllInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {volumeAvailable} {"  "}
                      {translate("resources.routes.message.mts")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box flexGrow={1}>
                <Box display="flex" mb="1em">
                  <Box mr="1em">
                    <MoveToInbox fontSize="small" color="disabled" />
                  </Box>
                  <Box flexGrow={1}>
                    <Typography>
                      {weightAvailable} {"  "}
                      {translate("resources.routes.message.kg")}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default TransportAside;
