export default { 
    name: 'Ciudad |||| Ciudades',
    label: 'Ciudades',
    fields: {
        code: 'Código',
        name: 'Nombre',
        region: 'Estado',
        parent_area_id: 'Estados',
    },
    message:{
        import: "Importar ciudades",
        commune: 'Ciudad:',
    },
}