import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  ReferenceField,
  DateField,
  EditButton,
} from 'react-admin';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { default as PlannerActions } from './PlannerActions';
import SubscriptionPlanButton from '../../components/SubscriptionPlanButton';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

const useStyles = makeStyles((theme) => ({
  datagridCell: {
    padding: '6px',
    textAlign: 'center'
  },
  actionButton: {
    minWidth: '35px'
  }
}));

function PlannerList({ permissions = [], ...props }) {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => `${record.firstName} ${record.lastName}`}
      secondaryText={(record) => record.email}
    />
  );
  const datagridClasses = { 
    headerCell: classes.datagridCell, 
    rowCell: classes.datagridCell 
  }
  const datagrid = (
    <Datagrid classes={datagridClasses}>
      <TextField source='firstName' />
      <TextField source='lastName' />
      <TextField source='email' />
      <TextField source='phone' />
      <ReferenceField source="subscription_plan_id" reference="subscription_plans">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="subscription_last_day" />
      {permissions.includes("users__subscription_plan") && <SubscriptionPlanButton />}
      <EditButton label="" className={classes.actionButton} />
      <CustomButtonDelete className={classes.actionButton} />
    </Datagrid>
  );
  return (
    <List  {...props} actions={<PlannerActions />}>
      {isSmall ? list : datagrid}
    </List>
  );
}

export default PlannerList;
