import * as React from 'react';
import { FC } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Avatar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, FieldProps, useTranslate, useQueryWithStore } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  rightAlignedCell: { textAlign: 'right' },
  root: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(1),
    marginTop: -theme.spacing(0.5),
    marginBottom: -theme.spacing(0.5),
    width: parseInt(25, 10),
    height: parseInt(25, 10),
  },
}));

const Drivers = ({ record }) => {
  const translate = useTranslate();
  const classes = useStyles();

  if (!record) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{translate('resources.transports.message.name')} </TableCell>
          <TableCell>{translate('resources.transports.message.dni')} </TableCell>
          <TableCell>{translate('resources.transports.message.email')}</TableCell>
          <TableCell>{translate('resources.transports.message.vehicles')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {record.drivers.map(
          (item) =>
            item && (
              <TableRow key={item.id}>
                <TableCell>
                  <div className={classes.root}>
                    <Avatar
                      src={`${item.photo}?size=25x25`}
                      className={classes.avatar}
                    />
                    <Link to={`/drivers/${item.id}`}>{item.name}</Link>
                  </div>
                </TableCell>
                <TableCell>{item.dni}</TableCell>
                <TableCell>{item.email}</TableCell>
                <TableCell>
                  {item.vehicle && (
                    <Link to={`/vehicles/${item.vehicle.id}`}>
                      {item.vehicle.plate}
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            )
        )}
      </TableBody>
    </Table>
  );
};

export default Drivers;
