import React, { useState } from 'react';
import { Button } from 'react-admin';
import PublishIcon from '@material-ui/icons/Publish';
import ImportDialog from './ImportDialog';
import ImportWizard from './ImportWizard';
import { ImportProvider } from './ImportContext';

function ImportButton({
  resource = '',
  buttonLabel = 'ra-csv-importer.button.import',
  dialogTitle = 'ra-csv-importer.modal.title',
  UploadComponent,
  ReviewComponent,
  steps,
  renderStepComponent,
  disableNextStep,
  disableImport
}) {
  const [open, setOpen] = useState(false);

  const handleClickButton = () => setOpen(true);

  const handleCloseDialog = () => setOpen(false);

  return (
    <>
      <Button 
        onClick={handleClickButton} 
        label={buttonLabel}
      >
        <PublishIcon />
      </Button>
      <ImportDialog 
        title={dialogTitle} 
        open={open} 
        onClose={handleCloseDialog} 
      >
        <ImportProvider>
          <ImportWizard 
            resource={resource}
            UploadComponent={UploadComponent} 
            ReviewComponent={ReviewComponent} 
            steps={steps}
            renderStepComponent={renderStepComponent}
            disableNextStep={disableNextStep}
            disableImport={disableImport}
            onImport={handleCloseDialog} 
          />
        </ImportProvider>
      </ImportDialog>
    </>
  );
};

export default ImportButton;