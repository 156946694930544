import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  email,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { ColorInput } from 'react-admin-color-input';

const validate = {
  name: [maxLength(128), required()],
  color: [required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
  website: [maxLength(128)],
  phone: [maxLength(16)],
  contactFirstName: [maxLength(128), required()],
  contactLastName: [maxLength(128), required()],
  contactEmail: [maxLength(128), required(), email()],
  contactPhone: [maxLength(16), required()],
  callCenterPhone: [maxLength(16)],
};

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function TransportCreate(props) {
  const translate = useTranslate();
  const classes = useStyles();
  return (
    <Create title={translate('resources.transports.message.register')} {...props}>
      <SimpleForm>
        <TextInput
          source='name'
          validate={validate.name}
          formClassName={classes.inlineBlock}
        />
        <ColorInput source="color" validate={validate.color} />
        <TextInput
          source='dni'
          validate={validate.dni}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address'
          validate={validate.address}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='website'
          validate={validate.website}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='phone'
          validate={validate.phone}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='callCenterPhone'
          validate={validate.callCenterPhone}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='contactFirstName'
          validate={validate.contactFirstName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='contactLastName'
          validate={validate.contactLastName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='contactEmail'
          validate={validate.contactEmail}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='contactPhone'
          validate={validate.contactPhone}
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Create>
  );
}

export default TransportCreate;
