import {default as es} from './es/es';
import {default as esVE} from './es-ve';
import {default as en} from './en/en';

export default {
    es,
    esVE,
    en,
}

