import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  ReferenceInput,
  SelectInput,
  useTranslate,
} from 'react-admin';

function CommuneTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.administrative_areas/level_2.message.commune')} {`${record.name}`}</span>;
  }

const validate={
  cod: [maxLength(64), required()],
  name:  [maxLength(128), required()],
}
  
  
export function CommuneEdit(props) {
  return (
    <Edit title={<CommuneTitle />} {...props}>
      <SimpleForm variant="standard">
        <TextInput source="code"  validate={validate.cod} />
        <TextInput source="name"  validate={validate.name} />
        <ReferenceInput 
          source="parent_area_id" 
          reference="administrative_areas/level_1" 
          alwaysOn 
        >
          <SelectInput optionText="name" optionValue="id" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
}

export default CommuneEdit;