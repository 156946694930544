import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  EditButton
} from 'react-admin';
import { useMediaQuery, makeStyles } from '@material-ui/core';
import { default as DriverActions } from './DriverActions';
import SubscriptionPlanButton from '../../components/SubscriptionPlanButton';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

const useStyles = makeStyles((theme) => ({
  datagridCell: {
    padding: '6px',
    textAlign: 'center'
  },
  actionButton: {
    minWidth: '35px'
  }
}));

function DriverList({ permissions = [], ...props }) {
  const classes = useStyles();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const list = (
    <SimpleList
      primaryText={(record) => `${record.transport.name}`}
      secondaryText={(record) => `${record.firstName} ${record.lastName}`}
      tertiaryText={(record) => `${record.email} ${record.phone}`}
    />
  );

  const datagridClasses = { 
    headerCell: classes.datagridCell, 
    rowCell: classes.datagridCell 
  }

  const datagrid = (
    <Datagrid classes={datagridClasses}>
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="dni" />
      <TextField source="email" />
      <TextField source="phone" />
      <ReferenceField
        source="transport_id"
        reference="transports"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="subscription_plan_id" reference="subscription_plans">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="subscription_last_day" />
      {permissions.includes("users__subscription_plan") && <SubscriptionPlanButton />}
      <EditButton label="" className={classes.actionButton} />
      <CustomButtonDelete className={classes.actionButton} />
    </Datagrid>
  );
  return (
    <List  {...props} actions={<DriverActions />}>
      {isSmall ? list : datagrid}
    </List>
  );
}

export default DriverList;
