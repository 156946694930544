import React from 'react';

import {
    Filter,
    ReferenceInput,
    SelectInput,
    AutocompleteInput,
    TextInput,
    }from 'react-admin';


function CommuneFilter(props){
   return(
    <Filter {...props}
    variant="standard"
    >
        <TextInput 
            source="name" 
            alwaysOn 
        />
        <ReferenceInput  
            source="parent_area_id" 
            reference="administrative_areas/level_1"
            filterToQuery={searchText => ({ name: searchText })} 
            alwaysOn 
        >
            <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
    </Filter>
    
    )
}

export default CommuneFilter;