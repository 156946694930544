import React from 'react';
import { ReactComponent as Icon } from './motorcycle.svg';
import { SvgIcon } from '@material-ui/core';

function MotorcycleIcon(props) {
  let color = props.color ? props.color : 'gray';
  return <SvgIcon component={Icon} viewBox="0 0 512 512" htmlColor={color} />;
}

export default MotorcycleIcon;
