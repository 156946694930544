import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  SelectField,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { default as VehicleActions } from './VehicleActions';
import { default as VehicleTypeField } from '../../components/VehicleTypeField';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';
import useTranslateVehicleStatus from './useTranslateVehicleStatus';

function VehicleList(props) {
  const vehicleStatus = useTranslateVehicleStatus();
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => `${record.transport.name}`}
      secondaryText={(record) => record.plate}
      tertiaryText={(record) => `${record.brand} ${record.model}`}
    />
  );
  const datagrid = (
    <Datagrid rowClick="show">
      <VehicleTypeField source="type" label=""/>
      <ReferenceField
        source='transport_id'
        reference='transports'
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source='plate' />
      <TextField source='brand' />
      <TextField source='model' />
      <TextField source='weightCapacity' />
      <TextField source='volumeCapacity' />
      <SelectField 
        source="status"
        choices={vehicleStatus}
      />
      <EditButton label="" />
      <CustomButtonDelete />
    </Datagrid>
  );

  return (
    <List  {...props} actions={<VehicleActions />}>
      {isSmall ? list : datagrid}
    </List>
  );
}

export default VehicleList;
