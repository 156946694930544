import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Paper,
  Toolbar,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import RouteAssign from './RouteAssign';
import OptimizeDialog from './OptimizeDialog';
import useTranslateOrderStatus from '../orders/useTranslateOrderStatus';
import {
  useTranslate,
  EditButton,
  ShowButton,
  Datagrid,
  TextField,
  NumberField,
  SelectField,
  ListContextProvider,
  ReferenceField,
} from 'react-admin';
import keyBy from 'lodash/keyBy';
import OrderFilters from '../orders/OrderFilters';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    minHeight: 'auto'
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const translate = useTranslate();
  const classes = useToolbarStyles();
  const { numSelected, selected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 && (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} {translate('resources.orders.message.selected')}
        </Typography>
      )}

      {numSelected > 0 && (
        <Tooltip title=''>
          <IconButton aria-label={translate('resources.waybills.assign.buttonDescription')}>
            <RouteAssign selected={selected} />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 && (
        <Tooltip title=''>
          <IconButton aria-label={translate('resources.waybills.optimize.buttonDescription')}>
            <OptimizeDialog orders={selected}/>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  selected: PropTypes.array.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  datagridCell: {
    padding: '6px',
    maxWidth: '70px',
    textAlign: 'center',
    '&.column-address': {
      maxWidth: '100px',
    },
  },
  actionButton: {
    minWidth: '20px',
    padding: 0,
  },
}));

export default function Orders({ record }) {
  const { orders } = record;
  const classes = useStyles();
  const [selected, setSelected] = React.useState([]);
  const [usedFilters, setUsedFilters] = React.useState({});
  const orderStatus = useTranslateOrderStatus();

  const handleClick = (id, basePath, record) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const filteredOrders = orders.filter((order) => {
    const orderID = order.code; 
    if ( 
      usedFilters.hasOwnProperty("code")
      &&  !orderID.includes(usedFilters.code)
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('status') &&
      usedFilters.status !== order.status
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('administrative_area_level_1_id') &&
      usedFilters.administrative_area_level_1_id !== null &&
      usedFilters.administrative_area_level_1_id !==
        order.administrative_area_level_1_id
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('administrative_area_level_2_id') &&
      usedFilters.administrative_area_level_2_id !== null &&
      usedFilters.administrative_area_level_2_id !==
        order.administrative_area_level_2_id
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('priority') &&
      usedFilters.priority !== null &&
      usedFilters.priority !== order.priority
    ) {
      return false;
    }
    if (
      usedFilters.hasOwnProperty('client_retail_id')&&
      usedFilters.client_retail_id !== null &&
      usedFilters.client_retail_id !== order.client_retail_id
    ) {
      return false; 
    }
    return true;
  });

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          selected={selected}
        />
        <OrderFilters
          resource="orders"
          setFilters={setUsedFilters}
          hideFilter={() => false}
        />
        <ListContextProvider
          value={{
            resource: 'orders',
            basePath: '/orders',
            data: keyBy(filteredOrders, 'id'),
            ids: filteredOrders.map(({ id }) => id),
            currentSort: { field: 'priority', order: 'ASC' },
            selectedIds: selected,
            onSelect: (selectableIDs) => setSelected(selectableIDs),
          }}
        >
          <Datagrid
            hasBulkActions={true}
            rowClick={handleClick}
            isRowSelectable={(record) =>
              record.status !== 'INVALID' &&
              record.status !== 'WITHOUT_GEOCODING'
            }
            classes={{
              headerCell: classes.datagridCell,
              rowCell: classes.datagridCell,
            }}
          >
            <TextField source="code" />
            <SelectField source="status" choices={orderStatus} />
            <TextField source="address" />
            <TextField source="priority" />
            <NumberField source="amount" />
            <NumberField source="weight" />
            <NumberField source="volume" />
            <ReferenceField
              source="client_retail_id"
              reference="clients/retail"
              label="Cliente retail"
            >
              <TextField source="name" />
            </ReferenceField> 
            <ShowButton label="" className={classes.actionButton} />
            <EditButton label="" className={classes.actionButton} />
          </Datagrid>
        </ListContextProvider>
      </Paper>
    </div>
  );
}
