import React from 'react';
import {
    List,
    SimpleList,
    Datagrid,
    TextField,
    SelectField,
    ReferenceField,
    ArrayField,
    SingleFieldList,
    ChipField,
    EditButton,
    DeleteButton,
    Pagination,
  } from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import {default as RegionActions} from './RegionActions'; 
import SelectRecordField from '../../components/SelectRecordField';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function OrdersPagination(props){
  return(
    <Pagination 
      rowsPerPageOptions={[25, 50, 100]} 
      {...props} 
    />
  )
} 

function RegionList(props) {
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const list = (
      <SimpleList
        primaryText={(record) => record.regionName}
        secondaryText={(record) => record.abreviatura}
      />
    );
    const datagrid = (
      <Datagrid rowClick="show">
        <TextField source="code"  />
        <TextField source="name"  />
        <ArrayField source="sub_areas" fieldKey="id" >
          <SingleFieldList>
            <ChipField source="name" />
          </SingleFieldList>
        </ArrayField>
        <EditButton label=""  />
        <CustomButtonDelete />
      </Datagrid>
    );
    return (
      <List {...props} 
        actions={<RegionActions/>}
        pagination={<OrdersPagination />}
        perPage={25} 
        sort={{ field: 'code', order: 'ASC' }} 
      >
        {isSmall ? list : datagrid}
        
      </List>
    );
  }

export default RegionList;



