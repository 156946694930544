import { fetchUtils } from 'react-admin';

const apiUrl = process.env.REACT_APP_API_URL;
const httpClient = fetchUtils.fetchJson;

const authProvider = {
  // called when the user attempts to log in
  login: ({ username, password }) => {
    const email = username;

    return httpClient(`${apiUrl}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ email, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json;
      })
      .then(({ access_token }) => {
        const payload = JSON.parse(atob(access_token.split('.')[1]));
        sessionStorage.setItem('USER_INFO', JSON.stringify(payload.identity));
        sessionStorage.setItem('ACCESS_TOKEN', access_token);
      });
  },
  // called when the user clicks on the logout button
  logout: () => {
    sessionStorage.removeItem('ACCESS_TOKEN');
    sessionStorage.removeItem('USER_INFO');
    return Promise.resolve();
  },
  // called when the API returns an error
  checkError: ({ status }) => {
    if (status === 401 || status === 403) {
      sessionStorage.removeItem('ACCESS_TOKEN');
      return Promise.reject();
    }
    return Promise.resolve();
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return sessionStorage.getItem('ACCESS_TOKEN')
      ? Promise.resolve()
      : Promise.reject();
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: async () => {
    const token = sessionStorage.getItem('ACCESS_TOKEN');
    let headers = new Headers({ Accept: 'application/json' });
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return httpClient(`${apiUrl}/auth/login`, {
      method: 'GET',
      headers: headers,
    }).then(({ json }) => {
      return json.permissions;
    });
  },
};

export default authProvider;
