import React from 'react';
import { Filter, SelectInput, ReferenceInput } from 'react-admin';
import DateWithCustomTimeInput from '../../../components/DateWithCustomTimeInput';
import DependentReferenceInput from '../../../components/DependentReferenceInput';
import useTranslateRouteStatus from '../../route/useTranslateRouteStatus';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  filter: {
    '& .filter-field': {
      /* flexGrow: 1, */
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
  },
});

function RouteFilters(props) {
  const classes = useStyles();
  const routeStatus = useTranslateRouteStatus();
  return (
    <Filter className={classes.filter} variant="standard" {...props}>
      <DateWithCustomTimeInput
        source="createdAt_gte"
        time="00:00:00"
        alwaysOn
        label="resources.routes.fields.createdAt_gte"
      />
      <DateWithCustomTimeInput
        source="createdAt_lte"
        time="23:59:59"
        alwaysOn
        label="resources.routes.fields.createdAt_lte"
      />
      <ReferenceInput
        source="transport_id"
        reference="transports"
        alwaysOn
        label="resources.routes.fields.transport_id"
      >
        <SelectInput optionText="name" optionValue="id" />
      </ReferenceInput>
      <DependentReferenceInput
        source="vehicle_id"
        reference="vehicles"
        filterProp="transport_id"
        dependentSource="transport_id"
        sort={{ field: 'plate', order: 'ASC' }}
        perPage={60}
        variant="standard"
        alwaysOn
        resetOnDependentChange={true}
        label="resources.routes.fields.vehicle_id"
      >
        <SelectInput optionText="plate" optionValue="id" />
      </DependentReferenceInput>
      <SelectInput
        source="status"
        choices={routeStatus}
        alwaysOn
        label="resources.routes.fields.status"
      />
    </Filter>
  );
}

export default RouteFilters;
