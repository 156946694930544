import React from 'react';
import {
  Edit,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  required,
  useTranslate,
  maxLength,
  minLength,
  email,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';


const validate = {
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email:  [maxLength(128), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  password: [maxLength(16), minLength(8), required()],
  address: [maxLength(128), required()],
  role_id: [required()],

};

function UserTitle({ record }) {
    const translate = useTranslate();
    return <span>{translate('resources.users.message.user')} {`${record.email} `}</span>;
  }
  
  export function UserEdit(props) {
    return (
      <Edit title={<UserTitle />} {...props}>
        <SimpleForm
          variant="standard"
        >
          <TextInput source='firstName'  validate={validate.firstName} />
          <TextInput source='lastName'  validate={validate.lastName} />
          <TextInput source='email' validate={validate.email} />
          <TextInput source='phone' validate={validate.phone}  />
          <TextInput source='dni' validate={validate.dni} />
          <TextInput source='address' validate={validate.address}  />
          <ReferenceInput
            source="role_id"
            reference="roles"
            filterToQuery={(searchText) => ({ label: searchText })}
            validate={validate.role_id}

          >
            <AutocompleteInput optionText="label" />
          </ReferenceInput>
        </SimpleForm>
      </Edit>
    );
  }

  export default UserEdit;