import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minLength,
  email,
  useTranslate,
  BooleanInput,
} from 'react-admin';
import { makeStyles } from "@material-ui/core/styles";
import GeocodeButton from '../../components/GeocodeButton';

const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
  fullWidth: {
    width: '100%'
  },
  form: { 
    '& .MuiCardContent-root': {
        display: 'flex', 
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
  },
  shortInput: { 
    flexBasis: '30%',
    '& .MuiFormControl-root': { width: '100%' },
  },
  shortBoolean: { 
    flexBasis: '15%',
    '& .MuiFormControl-root': { width: '100%' },
    '& .MuiFormGroup-root': { width: '100%' },
  },
  largeInput: { 
    flexBasis: '50%',
    '& .MuiFormControl-root': { width: '100%' }
  },
})

const validate={
  address: [maxLength(128), required()],
  name:  [maxLength(128), required()],
  latitude: [required()],
  longitude: [required()],
}

export function WarehouseCreate(props) {
  const translate = useTranslate();
  const classes = useStyles();

  const validateGeocode = (values) => {
    const errors = {};
    if (
      (!values.latitude && typeof values.latitude !== 'number')  
      || (!values.longitude && typeof values.latitude !== 'number')
    ) {
      errors.latitude = translate('resources.warehouses.message.geocode') ;
    }
    return errors
  };

  return (
    <Create  {...props}>
      <SimpleForm 
        validate={validateGeocode}
        className={classes.form} 
        variant='standard'
      >
        <TextInput 
          source="name" 
          validate={validate.name}
          formClassName={classes.shortInput} />
        <BooleanInput 
          source="default"
          defaultValue={false}  
          formClassName={classes.shortBoolean}
        />
        <TextInput 
          source="address" 
          validate={validate.address}
          formClassName={classes.largeInput} 
        />
        <GeocodeButton
          latSource="latitude"
          lngSource="longitude"
          addressSource="address"
          formClassName={classes.fullWidth} />
      </SimpleForm>
    </Create>
  );
}

export default WarehouseCreate;