import React from "react";
import {
  TextInput,
  NumberInput,
  SimpleForm,
  Edit,
  ReferenceField,
  TextField,
  DateField,
  ImageField,
  SelectField,
  ReferenceInput,
  AutocompleteInput,
  maxLength,
  required,
  useTranslate,
} from "react-admin";
import DependentReferenceInput from "../../components/DependentReferenceInput";
import useTranslateOrderStatus from "./useTranslateOrderStatus";
import { makeStyles } from "@material-ui/core";
import OrderEditActions from "./OrderEditActions";
import GeocodeButton from "../../components/GeocodeButton";

function OrderTitle({ record }) {
  const translate = useTranslate();
  return (
    <span>
      {translate("resources.orders.message.order")} {`${record.code}`}
    </span>
  );
}

const validate = {
  code: [required(), maxLength(128)],
  firstname: [required(), maxLength(256)],
  phone: [required(), maxLength(16)],
  address: [required(), maxLength(256)],
  latitude: [required()],
};

const useStyles = makeStyles({
  form: {
    "& .MuiCardContent-root": {
      display: "flex",
      flexFlow: "row wrap",
      justifyContent: "space-between",
    },
  },
  shortField: { flexBasis: "12.5%" },
  field: { flexBasis: "18%" },
  imageField: {
    "& div[class^='RaFormInput-input']": {
      width: "100%",
    },
  },
  shortInput: {
    flexBasis: "22.5%",
    "& .MuiFormControl-root": { width: "100%" },
  },
  largeInput: {
    flexBasis: "48.5%",
    "& .MuiFormControl-root": { width: "100%" },
  },
  fullWidth: {
    flexBasis: "100%",
    textAlign: "right",
    "& .MuiFormControl-root": { width: "100%" },
  },
});

function OrderEdit(props) {
  const classes = useStyles();
  const orderStatus = useTranslateOrderStatus();
  const removeStatusBeforeSubmit = (record) => {
    delete record["status"];
    return record;
  };

  const redirect = (basePath, id, data) => `/waybills/${data.waybill_id}/show`;

  return (
    <Edit
      {...props}
      title={<OrderTitle />}
      actions={<OrderEditActions history={props.history} />}
      transform={removeStatusBeforeSubmit}
    >
      <SimpleForm
        className={classes.form}
        redirect={() => props.history.goBack()}
      >
        <DateField source="createdAt" formClassName={classes.field} />
        <DateField source="updatedAt" formClassName={classes.field} />
        <TextField source="code" formClassName={classes.shortField} />
        <SelectField
          source="status"
          choices={orderStatus}
          formClassName={classes.shortField}
        />
        <ReferenceField
          source="waybill_id"
          reference="waybills"
          formClassName={classes.shortField}
        >
          <TextField source="code" />
        </ReferenceField>
        <ImageField
          source="qr_code"
          title="QR code"
          formClassName={`${classes.shortField} ${classes.imageField}`}
        />
        {/* FORM INPUTS */}
        <TextInput
          source="firstname"
          formClassName={classes.shortInput}
          validate={validate.firstname}
          variant="standard"
        />
        <TextInput
          source="lastname"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <TextInput
          source="phone"
          formClassName={classes.shortInput}
          validate={validate.phone}
          variant="standard"
        />
        <TextInput
          source="email"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <NumberInput
          source="amount"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <NumberInput
          source="priority"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <NumberInput
          source="weight"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <NumberInput
          source="volume"
          formClassName={classes.shortInput}
          variant="standard"
        />
        <ReferenceInput
          source="client_retail_id"
          reference="clients/retail"
          variant="standard"
          filterToQuery={(searchText) => ({ name: searchText })}
          formClassName={classes.shortInput}
          alwaysOn
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </ReferenceInput>
        <ReferenceInput
          source="administrative_area_level_1_id"
          reference="administrative_areas/level_1"
          sort={{ field: "name", order: "ASC" }}
          filterToQuery={(searchText) => ({ name: searchText })}
          formClassName={classes.shortInput}
          variant="standard"
          alwaysOn
        >
          <AutocompleteInput source="name" optionValue="id" />
        </ReferenceInput>
        <DependentReferenceInput
          source="administrative_area_level_2_id"
          reference="administrative_areas/level_2"
          filterProp="parent_area_id"
          dependentSource="administrative_area_level_1_id"
          sort={{ field: "name", order: "ASC" }}
          perPage={60}
          filterToQuery={(searchText) => ({ name: searchText })}
          resetOnDependentChange={false}
          formClassName={classes.largeInput}
          variant="standard"
          alwaysOn
        >
          <AutocompleteInput optionText="name" optionValue="id" />
        </DependentReferenceInput>
        <TextInput
          source="address"
          formClassName={classes.largeInput}
          validate={validate.address}
          variant="standard"
        />
        <TextInput
          source="address_references"
          formClassName={classes.largeInput}
          variant="standard"
        />
        <GeocodeButton
          latSource="latitude"
          lngSource="longitude"
          addressSource="address"
          formClassName={classes.fullWidth}
        />
      </SimpleForm>
    </Edit>
  );
}

export default OrderEdit;
