// in src/CustomUserMenu.js
import React from 'react';
import { UserMenu, MenuItemLink } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';

function CustomUserMenu(props) {
  const profile = JSON.parse(sessionStorage.getItem('USER_INFO'));
  return (
    <UserMenu label={profile ? profile.firstName : ''} {...props}>
      <MenuItemLink
        to="/my-profile"
        primaryText="Mi perfil"
        leftIcon={<SettingsIcon />}
      />
    </UserMenu>
  );
}

export default CustomUserMenu;
