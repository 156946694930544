import React, { useState, useEffect } from "react";
import { Button, useTranslate, NumberInput } from "react-admin";
import Map from "./Map";
import {
  Box,
  CircularProgress,
  makeStyles,
  FormHelperText,
} from "@material-ui/core";
import LocationIcon from "@material-ui/icons/Room";
import { useForm, useFormState } from "react-final-form";
import useGeocoding from "../hooks/useGoogleGeocoding";

const useButtonStyles = makeStyles((theme) => ({
  map: {
    marginTop: theme.spacing(3),
  },
  button: {
    width: "auto",
  },
  spinner: {
    marginLeft: theme.spacing(1),
    color: "#ffffff",
  },
}));

function GeocodeButton({ latSource, lngSource, addressSource, ...props }) {
  const translate = useTranslate();
  const form = useForm();
  const { values, errors, submitFailed, submitErrors } = useFormState();
  const [shouldGeocode, setShouldGeocode] = useState(false);
  const classes = useButtonStyles();
  const {
    isGeocoding,
    geocodingData,
    geocodingTotal,
    setAddressesToGeocode,
    geocode,
  } = useGeocoding();

  useEffect(() => {
    if (shouldGeocode) {
      geocode();
    }
    if (geocodingData.length > 0 && geocodingData.length === geocodingTotal) {
      setShouldGeocode(false);
      const { lat, lng } = geocodingData[0];
      form.change(latSource, lat);
      form.change(lngSource, lng);
    }
  }, [shouldGeocode, geocodingData.length]);

  const handleGeocode = () => {
    setAddressesToGeocode([values[addressSource]]);
    setShouldGeocode(true);
  };
  const markers = [
    {
      lat: values[latSource],
      lng: values[lngSource],
    },
  ];
  return (
    <>
      <Button
        {...props}
        color="primary"
        label={translate("texts.geocode")}
        variant="contained"
        onClick={handleGeocode}
        className={classes.button}
      >
        {isGeocoding ? (
          <CircularProgress
            color="White"
            className={classes.spinner}
            size={20}
          />
        ) : (
          <LocationIcon />
        )}
      </Button>
      {submitFailed && errors.hasOwnProperty(latSource) && (
        <FormHelperText error={true}>{errors[latSource]}</FormHelperText>
      )}
      {submitFailed && errors.hasOwnProperty(lngSource) && (
        <FormHelperText error={true}>{errors[lngSource]}</FormHelperText>
      )}
      <Box height="30vh" width="100%" className={classes.map}>
        <Map data={markers} />
      </Box>
    </>
  );
}

export default GeocodeButton;
