export default{
    name: 'Client retail |||| Clients retail',
    label: 'Clients retail',
    fields: {
        dni: 'DNI',
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
    },
    message: {
        import: 'Import retail clients',
        retail: 'Client retail:',
    },
}