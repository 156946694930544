import React, { useEffect, useCallback, useState } from 'react';
import { useTranslate, useDataProvider, Button } from 'react-admin';
import useTranslateRouteStatus from '../../route/useTranslateRouteStatus';
import GetAppIcon from '@material-ui/icons/GetApp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { format } from 'date-fns';

const generatePDF = (records, columns, mapStatus, title) => {
  const doc = new jsPDF();

  const tableRows = [];
  records.forEach((record) => {
    const recordData = [
      record.code,
      record.events.CREATE
        ? format(new Date(record.events.CREATE), 'DD/MM/YYYY')
        : '-',
      record.events.TRANSPORT_ASSIGNMENT
        ? format(new Date(record.events.TRANSPORT_ASSIGNMENT), 'DD/MM/YYYY')
        : '-',
      record.events.DRIVER_CONFIRMATION
        ? format(new Date(record.events.DRIVER_CONFIRMATION), 'DD/MM/YYYY')
        : '-',
      record.events.LOAD_TRANSPORT
        ? format(new Date(record.events.LOAD_TRANSPORT), 'DD/MM/YYYY')
        : '-',
      record.events.DELIVER_LAST_ORDER
        ? format(new Date(record.events.DELIVER_LAST_ORDER), 'DD/MM/YYYY')
        : '-',
      record.vehicle.plate,
    ];
    tableRows.push(recordData);
  });

  doc.autoTable(columns, tableRows, { startY: 60 });
  const imageData = '/chitrack-background.png';
  doc.text(title, 15, 50);
  doc.addImage(imageData, 'PNG', 15, 20, 90, 20);
  doc.save(`routes_report.pdf`);
};

function RouteReportExportPDF(props) {
  const [records, setRecords] = useState([]);
  const { disabled, resource, sort, filterValues } = props;
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const routeStatus = useTranslateRouteStatus();

  useEffect(() => {
    fetchData();
  }, [filterValues]);

  const fetchData = useCallback(async () => {
    const { data } = await dataProvider.getList(resource, {
      filter: { ...filterValues },
      sort: { ...sort },
      pagination: { page: 1, perPage: 1000 },
    });

    setRecords(data);
  });

  const columns = [
    translate('resources.routes.fields.code'),
    translate('resources.routes.status.created'),
    translate('resources.routes.status.assigned'),
    translate('resources.routes.status.accepted'),
    translate('resources.routes.status.inTransit'),
    translate('resources.routes.status.finished'),
    translate('resources.routes.fields.vehicle_id'),
  ];

  const mapStatus = {};
  routeStatus.forEach((status) => {
    mapStatus[status.id] = status.name;
  });

  return (
    <Button
      onClick={() => {
        generatePDF(
          records,
          columns,
          mapStatus,
          translate('resources.reports.route.label')
        );
      }}
      label="resources.reports.exportPDF"
      disabled={disabled}
    >
      <GetAppIcon />
    </Button>
  );
}

export default RouteReportExportPDF;
