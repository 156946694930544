
import React from 'react';
import {
    useListContext,
} from 'react-admin';
import Map from '../../components/Map';
import Box from "@material-ui/core/Box";

function Aside() {
    const { ids: identities, data: orders } = useListContext();
    let markers = [];
  
    const parseLatLng = ({ lat, lng }) => {
      const parseLat = Number.parseFloat(lat);
      const parseLng = Number.parseFloat(lng);
      return { lat: parseLat, lng: parseLng };
    }
    if (identities instanceof Array) {
      markers = identities
        .filter(id => orders[id] 
          && typeof orders[id].latitude === 'number' 
          && typeof orders[id].longitude === 'number')
        .map(id => ({ lat: orders[id].latitude, lng: orders[id].longitude }));
    }
  
    return (
      <Box 
        component="div" 
        position="fixed"
        top="0"
        bottom="0"
        right="0"
        height="100vh"
        width="29vw"
      >
        <Map data={markers} />
      </Box>
    );
  }
  export default Aside;