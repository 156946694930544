import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minLength,
  email,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const validate = {
  name: [maxLength(128), required()],
  dniLegalPersonName: [maxLength(64)],
  dniLegalPersonFormat: [maxLength(64)],
  dniNaturalPersonName: [maxLength(64)],
  dniNaturalPersonFormat: [maxLength(64)],
};

export function CountryCreate(props) {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput 
          source='name'  
          validate={validate.name} />
        <TextInput
          source='dniLegalPersonName'
          validate={validate.dniLegalPersonName}
        />
        <TextInput
          source='dniLegalPersonFormat'
          validate={validate.dniLegalPersonFormat}
        />
        <TextInput
          source='dniNaturalPersonName'
          validate={validate.dniNaturalPersonName}
        />
        <TextInput
          source='dniNaturalPersonFormat'
          validate={validate.dniNaturalPersonFormat}
        />
      </SimpleForm>
    </Create>
  );
}

export default CountryCreate;
