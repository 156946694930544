import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput,
  required,
  maxLength,
  number,
  useTranslate,
  SelectField,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import useTranslateVehicleStatus from './useTranslateVehicleStatus';
import useTranslateVehicleType from './useTranslateVehicleType';

function VehicleTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.vehicles.message.vehicle')} {`${record.plate}`}</span>;
}

const validate = {
  transport_id: [required()],
  driver_id: [],
  type: [required()],
  plate: [maxLength(16), required()],
  weightCapacity: [number(), required()],
  volumeCapacity: [number(), required()],
  brand: [maxLength(32), required()],
  model: [maxLength(32), required()],
  year: [number(), required()],
  color: [maxLength(32), required()],
};


const useStyles = makeStyles({
  form: { 
    '& .MuiCardContent-root': {
        display: 'flex', 
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
  },
  largeInput: {
    flexBasis: '30%',
      '& .MuiFormControl-root': { width: '100%' },
  },
  largeField: { 
    flexBasis: '20%',
    '& .MuiFormControl-root': { width: '100%' }
  },
  shortInput: {
    flexBasis: '20%',
      '& .MuiFormControl-root': { width: '100%' },
  },
  shortField: { 
    flexBasis: '15%',
    '& .MuiFormControl-root': { width: '100%' }
  },
});
export function VehicleEdit(props) {
  const classes = useStyles();
  const translate = useTranslate();
  const vehicleStatus = useTranslateVehicleStatus();
  const vehicleTypes = useTranslateVehicleType();

  const validateCapacity = (values) => {
    const errors = {};
    if (values.weightCapacity < values.weightAssigned )  {
      errors.weightCapacity = translate('resources.vehicles.message.weightCapacity');
    }
    if (values.volumeCapacity < values.volumeAssigned )  {
      errors.volumeCapacity = translate('resources.vehicles.message.volumeCapacity');
    }
    return errors
  };
  
  return (
    <Edit title={<VehicleTitle />} {...props}>
      <SimpleForm
        variant="standard"
        className={classes.form}
        validate={validateCapacity}
      >
        <ReferenceInput
          source='transport_id'
          reference='transports'
          filterToQuery={(searchText) => ({ name: searchText })}
          validate={validate.transport_id}
          formClassName={classes.largeInput}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="driver_id"
          reference="drivers"
          allowEmpty
          filterToQuery={(searchText) => ({ firstName: searchText })}
          validate={validate.driver_id}
          formClassName={classes.largeInput}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <SelectField 
          source='status'
          formClassName={classes.largeInput}
          choices={vehicleStatus}
        />
        <TextInput
          source='plate'
          validate={validate.plate}
          formClassName={classes.largeInput}
        />
        <SelectInput
          source="type"
          choices={vehicleTypes}
          validate={validate.type}
          formClassName={classes.largeInput}
        />
         <TextInput
          source='model'
          validate={validate.model}
          formClassName={classes.largeInput}
        />
        <NumberInput
          source='year'
          validate={validate.year}
          formClassName={classes.largeInput}
        />
        <TextInput
          source='color'
          validate={validate.color}
          formClassName={classes.largeInput}
        />
        <TextInput
          source='brand'
          validate={validate.brand}
          formClassName={classes.largeInput}
        />
        <NumberInput
          source='weightCapacity'
          validate={validate.weightCapacity}
          formClassName={classes.shortInput}
          
        />
        <NumberInput
          source='volumeCapacity'
          validate={validate.volumeCapacity}
          formClassName={classes.shortInput}
        />
        <TextField 
          source='weightAssigned'
          formClassName={classes.shortField}
         />
         <TextField 
          source='volumeAssigned'
          formClassName={classes.shortField}
         />
      </SimpleForm>
    </Edit>
  );
}

export default VehicleEdit;
