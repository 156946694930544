import * as React from "react";
import { cloneElement, useMemo } from "react";
import PropTypes from "prop-types";
import {
  useListContext,
  TopToolbar,
  CreateButton,
  ExportButton,
  Button,
  sanitizeListRestProps,
  useTranslate,
} from "react-admin";
import IconEvent from "@material-ui/icons/Event";
import { ImportButton } from "../../modules/ra-csv-importer";
import { default as RegionUploadComponent } from "./RegionUploadComponent";
import { default as RegionReviewComponent } from "./RegionReviewComponent";

function RegionActions(props) {
  const { className, exporter, filters, maxResults, ...rest } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    selectedIds,
    showFilter,
    total,
  } = useListContext();
  const translate = useTranslate();

  const disableImportNextStep = (stepIndex, state) => {
    const { formValues, recordsToImport } = state;
    return recordsToImport.length === 0 || !formValues.code || !formValues.name;
  };

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filterValues={filterValues}
        maxResults={maxResults}
      />
      <ImportButton
        resource={resource}
        dialogTitle={translate(
          "resources.administrative_areas/level_1.message.import"
        )}
        disableNextStep={disableImportNextStep}
        UploadComponent={<RegionUploadComponent />}
        ReviewComponent={<RegionReviewComponent />}
      />
    </TopToolbar>
  );
}

export default RegionActions;
