import englishMessages from 'ra-language-english';
import { default as waybills } from './waybills';
import { default as orders} from './orders';
import { default as users } from './users';
import { default as planners} from './planners';
import { default as transports} from './transports';
import { default as vehicles } from './vehicles';
import { default as countries } from './countries';
import { default as administrative_areas_level_1 } from './administrative_areas_level_1';
import { default as administrative_areas_level_2 } from './administrative_areas_level_2';
import { default as clients_retail } from './clients_retail';
import { default as drivers } from './drivers';
import { default as roles } from './roles';
import { default as routes } from './routes';
import { default as deliveries } from './deliveries';
import { default as dashboard } from './dashboard';
import { default as warehouses } from './warehouses';
import { default as subscription_plans } from './subscription_plans';

export default {
    ...englishMessages,
    resources: {
        waybills,
        orders,
        users,
        planners,
        transports,
        vehicles,
        countries,
        "administrative_areas/level_1": administrative_areas_level_1,
        "administrative_areas/level_2": administrative_areas_level_2,
        "clients/retail": clients_retail,
        drivers,
        roles,
        routes,
        deliveries,
        warehouses,
        subscription_plans,
        settings: {
            name: 'Setting |||| Settings',
            label: 'Settings',
        },
        reports: {
            label: 'Reports',
            exportPDF: 'Export PDF',
            exportCSV: 'Export CSV',
            waybill: {
                label: 'Waybills report',
            },
            route: {
                label: 'Routes report',
            },
            order: {
                label: 'Orders report',
            },
        },

    },
    dashboard,
    auth: {
        unauthorized: "Invalid email or password"
    },
    ra_csv_importer: {
        action: {
            import: "Import"
        }
    },
    texts: {
        welcome: "Welcome to Chitrack",
        noMap: "The map could not be loaded correctly, sorry.",
        loadingMap: "Loading map ...",
        geocode: 'Geocode',
    },
    confirmDelete: {
        delete: 'Delete',
        cancel: 'Cancel',
        message: 'Are you sure you want to delete?',
        warning: 'Warning',
    },
    'Failed to fetch': `Sorry! A server error connection ocurred, try again or contact with support`,
    'BAD REQUEST': 'Invalid request, try again or contact with support'
}

