import React from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
  maxLength,
  minLength,
  email,
  useTranslate
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const validate = {
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email: [maxLength(64), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
};

export function PlannerCreate(props) {
  const translate = useTranslate();
  return (
    <Create title={translate("resources.planners.message.register")}  {...props}>
      <SimpleForm>
        <TextInput
          source='firstName'
          validate={validate.firstName}
        />
        <TextInput
          source='lastName'
          validate={validate.lastName}
        />
        <TextInput source='email' validate={validate.email} />
        <TextInput source='phone' validate={validate.phone} />
        <TextInput source='dni' validate={validate.dni} />
        <TextInput
          source='address'
          validate={validate.address}
        />
      </SimpleForm>
    </Create>
  );
}

export default PlannerCreate;
