import React, { useEffect } from 'react'
import { useImportContext } from './ImportContext';

function FileReaderField(props) {
  const { record, source } = props;
  const [state, dispatch] = useImportContext();
  const { file } = state;

  useEffect(() => { 
    readFile();
  }, [record]);

  const readFile = async () => {
    const reader = new FileReader();
    const file = record.rawFile;
    reader.onload = async (e) => {
      const content = e.target.result;
      const name = file.name;
      dispatch({ type: 'save_file_result', content, name })
    }
    reader.readAsText(file);
  }

  return (file.name && file.content ? <span>{file.name}</span>: <></>)
}

export default FileReaderField;