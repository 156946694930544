import React from 'react';
import {
  List,
  SimpleList,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  ShowButton,
} from 'react-admin';
import { useMediaQuery } from '@material-ui/core';
import { default as TransportActions } from './TransportActions';
import CustomButtonDelete from '../../components/ConfirmDeleteButton';

function TransportList(props) {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const list = (
    <SimpleList
      primaryText={(record) => `${record.name}`}
      secondaryText={(record) => record.dni}
      tertiaryText={(record) => record.address}
    />
  );
  const datagrid = (
    <Datagrid rowClick='show'>
      <TextField source='name'  />
      <TextField source='dni'  />
      <TextField source='address'  />
      <TextField source='phone'  />
      <ShowButton label="" />
      <EditButton label="" />
      <CustomButtonDelete />
    </Datagrid>
  );
  return (
    <List
      {...props}
      actions={<TransportActions />}
    >
      {isSmall ? list : datagrid}
    </List>
  );
}

export default TransportList;
