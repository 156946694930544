import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
  required,
  maxLength,
  email,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import PreviewImageField from '../../components/PreviewImageField';

function DriverTitle({ record }) {
  const translate = useTranslate();
  return <span>{translate('resources.drivers.message.driver')} {`${record.email} `}</span>;
}

const validate = {
  transport_id: [required()],
  firstName: [maxLength(128), required()],
  lastName: [maxLength(128), required()],
  email: [maxLength(64), required(), email()],
  phone: [maxLength(16), required()],
  dni: [maxLength(32), required()],
  address: [maxLength(128), required()],
  licenseCode: [maxLength(32), required()],
  licenseType: [maxLength(32), required()],
  licenseExpire: [required()],
};

function formatImage(value) {
  if (!value || typeof value === 'string') {
    return { url: value };
  } else {
    return value;
  }
}

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

export function DriverEdit(props) {
  const classes = useStyles();
  return (
    <Edit title={<DriverTitle />} {...props}>
      <SimpleForm
        variant="standard"
      >
        <ReferenceInput
          source='transport_id'
          variant="standard"
          reference='transports'
          filterToQuery={(searchText) => ({ name: searchText })}
        >
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ImageInput
          format={formatImage}
          source='photo'
          accept='image/*'
        >
          <PreviewImageField source="url" />
        </ImageInput>

        <TextInput
          source='firstName'
          validate={validate.firstName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='lastName'
          validate={validate.lastName}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='email'
          validate={validate.email}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='phone'
          validate={validate.phone}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='dni'
          validate={validate.dni}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='address'
          validate={validate.address}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='licenseCode'
          validate={validate.licenseCode}
          formClassName={classes.inlineBlock}
        />
        <TextInput
          source='licenseType'
          validate={validate.licenseType}
          formClassName={classes.inlineBlock}
        />
        <DateInput
          source='licenseExpire'
          validate={validate.licenseExpire}
          formClassName={classes.inlineBlock}
        />
      </SimpleForm>
    </Edit>
  );
}

export default DriverEdit;
