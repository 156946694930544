export default {
    name: 'Usuario |||| Usuarios',
    label: 'Usuarios',
    fields: {
        firstName: 'Nombre',
        lastName: 'Apellido',
        email: 'Correo electrónico',
        phone: 'Teléfono',
        dni: 'RUT',
        password: 'Clave',
        address: 'Dirección',
        createdAt: 'Fecha de creación',
        updatedAt: 'Ultima actualizacion',
        role_id: 'Rol',
    },
    message: {
        register: 'Registrar usuarios',
        user: 'Usuario:',
        success: 'Usuario actualizado correctamente',
    },
}