export default {
    name: 'vehicle |||| Vehicles',
    label: 'Vehicles',
    fields: {
        transport_id: 'Transport business',
        plate: 'Plate',
        brand: 'Brand',
        driver_id: 'Driver',
        model: 'Model',
        weightCapacity: 'Weight Capacity (Kg)',
        volumeCapacity: 'Volume Capacity (mts³)',
        weightAssigned: 'Weight Assigned (Kg)',
        volumeAssigned: 'Volume Assigned (mts³)',
        year: 'Year',
        color: 'Color',
        type: 'Type',
        status: 'Status',
    },
    message: {
        bike: 'Bike',
        motorBike: 'Motorcycle',
        car: 'Car',
        van: 'Van',
        truck: 'truck',
        vehicle: 'Vehicle:',
        weightCapacity: 'Weight of the vehicle must not be less than the assigned weight',
        volumeCapacity: 'Volume of the vehicle must not be less than the assigned volume',
    },
    status: {
        assigned: 'Assigned',
        available: 'Available',
    },
    errors: {
        invalidWeightCapacity: 'Vehicle with invalid weight capacity, cannot be less than the assigned weight',
        invalidVolumeCapacity: 'Vehicle with invalid volume capacity, cannot be less than the assigned volume',
        driverAlreadyAssigned: 'Driver is already assigned to another vehicle'
    }
}